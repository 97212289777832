import {
  GET_ASSOCIATE_INFORMATION,
  GET_ASSOCIATE_INFORMATION_ERROR,
  UPDATE_ASSOCIATE_ID,
  UPDATE_MOA_REDIRECT,
  GET_ASSOCIATE_PHONE_INFORMATION,
  GET_ASSOCIATE_PHONE_INFORMATION_ERROR,
} from "../actions/types";
import {
  translateAgentOfficeDataList,
  getOfficeMembers,
  translateAgentPhoneDataList,
} from "../../utils/translators";

const initialState = {
  agentsOfficeList: [],
  loggedInAssociateId: "",
  officeAssociateId: "",
  loggedInAssociateName: "",
  moaPageShown: false,
  isFetched: false,
  isMoa: false,
  currentOfficeMembers: "",
  isAgent: false,
  agentPhoneList: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ASSOCIATE_INFORMATION: {
      let response = action.payload;
      let responseAgentsOfficeList = translateAgentOfficeDataList(response);
      const loggedInAssociateId = response.associateID;
      return {
        ...state,
        isFetched: true,
        loggedInAssociateId,
        loggedInAssociateName: response.userName,
        officeAssociateId:
          responseAgentsOfficeList.length > 1
            ? ""
            : responseAgentsOfficeList[0].id,
        currentOfficeMembers:
          responseAgentsOfficeList.length > 1
            ? ""
            : getOfficeMembers(
                responseAgentsOfficeList[0].id,
                responseAgentsOfficeList,
                loggedInAssociateId
              ),
        agentsOfficeList: responseAgentsOfficeList,
        isMoa: responseAgentsOfficeList.length > 1,
        isAgent: response.isAgent,
        error: false,
      };
    }
    case GET_ASSOCIATE_INFORMATION_ERROR: {
      return {
        ...state,
        error: action.error.message,
      };
    }
    case UPDATE_ASSOCIATE_ID: {
      return {
        ...state,
        moaPageShown: true,
        officeAssociateId: action.officeAssociateId,
        currentOfficeMembers: getOfficeMembers(
          action.officeAssociateId,
          state.agentsOfficeList,
          state.loggedInAssociateId
        ),
      };
    }

    case UPDATE_MOA_REDIRECT: {
      return {
        ...state,
        moaRedirect: action.payload,
      };
    }
    case GET_ASSOCIATE_PHONE_INFORMATION: {
      //let response = action.payload;
      let responseAgentsPhoneList = translateAgentPhoneDataList(action.payload);
      return {
        ...state,
        agentPhoneList: responseAgentsPhoneList,
      };
    }
    case GET_ASSOCIATE_PHONE_INFORMATION_ERROR: {
      return {
        ...state,
        error: action.error.message,
      };
    }
    default:
      return state;
  }
}
