import React, { useEffect } from "react";
import { connect } from "react-redux";
import CustomerSearchRow from "./CustomerSearchRow";
import Loader from "../Loader";
import NoMatchFound from "./NoMatchFound";

const CustomerSearchTable = props => {
  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    document.title = `Search | SF Connect`;
  });
  const RowsOfCustomers = props.customers.map((customer, i) => {
    return <CustomerSearchRow key={customer.clientID} {...customer} history={props.history} index={i} />
  });

  return (
    <>
      {props.showLoader ? <Loader /> : null}
      {props.isEmptyList ? <NoMatchFound /> :
        <>
            <div className="-oneX-table--standard" aria-label="Standard table" style={{display: props.customers.length > 0 ? "block" : "none"}} role="table">
            <div className="-oneX-thead" role="row"> 
              <div className="-oneX-th--col" role="columnheader">Customer Details <span>({props.customers.length} results)</span></div>
              <div className="-oneX-th--col" role="columnheader">Actions</div>
            </div>
            {RowsOfCustomers}
          </div>
    
        </>
      }
    </>
  )
}

const mapStateToProps = state => {
  return {
    customers: state.customerSearchReducer.customers,
    showLoader: state.customerSearchReducer.showLoader,
    isEmptyList: state.customerSearchReducer.isEmptyList
  };
};

export default connect(mapStateToProps, null)(CustomerSearchTable);