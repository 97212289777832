import React from "react";
import { Provider } from "react-redux";
import { render } from "react-dom";
import * as serviceWorker from "./serviceWorker";
import store from "./redux";
import App from "./App";
import Footer from "./global/components/Footer/footer";
import * as axiosInterceptor from "./axiosInterceptor";

import SideDrawer from "./components/OfficeSettings/SideDrawer";

import { AuthProvider } from "./context/AuthProvider";
import { UserProvider } from "./context/UserProvider";
import { msalConfig, apiRequestScope } from "./context/AuthConfig";

axiosInterceptor.register();

render(
  <Provider store={store}>
    <AuthProvider config={msalConfig} scopes={apiRequestScope}>
      <UserProvider>
        <div className="-oneX">
          {process.env.NODE_ENV === "development" && (
            <div>
              <small>
                You are running SF Connect in <b>{process.env.NODE_ENV}</b>{" "}
                mode.
              </small>
            </div>
          )}
          {window.location.hostname.includes("preview") && (
            <div className="preview-div">
              <p>
                This site is intended for use with pilots and feedback
                gathering. This is not, and may not function like, the actual SF
                Connect site. If you encounter issues, please discontinue use of
                this site and use the{" "}
                <a href="https://sfconnect.c1.statefarm/">
                  original SF Connect site.
                </a>{" "}
                For questions/comments, please email{" "}
                <a href="mailto:home.agcy-sfconnect-va.378o07@statefarm.com">
                  HOME AGCY SFCONNECT-VA
                </a>
                .
              </p>
            </div>
          )}
          <main>
            <App />
            <SideDrawer />
          </main>
          <Footer />
        </div>
      </UserProvider>
    </AuthProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
