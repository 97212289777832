export function validateArray(arrayToValidate) {
  if (
    arrayToValidate &&
    Array.isArray(arrayToValidate) &&
    arrayToValidate.length >= 1
  ) {
    return true;
  } else {
    return false;
  }
}

export function validateArrayEmpty(arrayToValidate) {
  if (arrayToValidate && Array.isArray(arrayToValidate)) {
    return true;
  } else {
    return false;
  }
}
