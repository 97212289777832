import React from "react";
import { patchOfficesPreferences } from "../../redux/actions/officeSettingsActions";
import { validateArrayEmpty } from "../../utils/arrayValidator";
import { useSelector, useDispatch } from "react-redux";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

export default function EmailNotificationCheckbox() {
  const dispatch = useDispatch();

  const loggedInAssociateId = useSelector(
    (state) => state.userInfoReducer.loggedInAssociateId
  );

  const officeId = useSelector(
    (state) => state.userInfoReducer.officeAssociateId
  );

  const emailRecipients = useSelector(
    (state) => state.officeSettingsReducer.emailRecipients
  );

  let emailEnabledFlag;

  if (validateArrayEmpty(emailRecipients)) {
    emailEnabledFlag = emailRecipients.includes(loggedInAssociateId);
  } else {
    emailEnabledFlag = false;
  }

  const setEmailNotification = () => {
    var tempEmailRecipients = new Set();

    if (emailRecipients && emailRecipients.length !== undefined) {
      tempEmailRecipients = new Set(emailRecipients);
    }

    if (!emailEnabledFlag) {
      tempEmailRecipients.add(loggedInAssociateId);
    } else {
      tempEmailRecipients.delete(loggedInAssociateId);
    }

    dispatch(
      patchOfficesPreferences(officeId, {
        emailRecipients: Array.from(tempEmailRecipients),
      })
    );
  };

  return (
    <>
      <p className="panel-details">
        An email will be sent to you when a new text is received and you are not
        logged into SF Connect.
      </p>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={emailEnabledFlag}
              onChange={setEmailNotification}
            />
          }
          label="Email Notifications"
        />
      </FormGroup>
    </>
  );
}
