import React from "react";
import "./footer.scss";

const Footer = (props) => {
  return (
    <>
      <footer className="sfconnect-footer">
        <div className="-oneX-container">
          <div className="-oneX-row">
            <div className="-oneX-col">
              <div className="copyright-text">
                FOR INTERNAL STATE FARM USE ONLY - Contains information that may not be disclosed outside State Farm without authorization. <br />
                    &copy; Copyright 2020 |&nbsp;
                   <a href='https://intranet.opr.statefarm.org/entpolicies/gem_153_privacy_pers_info.pdf' target='_blank' rel='noopener noreferrer'>Privacy Policy</a> |&nbsp;
                   <a href='https://sfnet.opr.statefarm.org/agency/training/rollout/sfConnect/index.shtml' target='_blank' rel='noopener noreferrer'>Terms of Use</a>
              </div>
            </div>
          </div>
        </div>
      </footer>

    </>
  );
}
export default (Footer);