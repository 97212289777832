import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { UPDATE_CLIENT_TYPE_FOR_MULTIMATCH } from "redux/actions/types";
import {
  patchInteractionResolveMultiMatch,
  mainAction,
} from "../../redux/actions/interactionsActions";

class MultipleMatchDropdown extends Component {
  constructor(props) {
    super(props);
    this.onClickCustomerName = this.onClickCustomerName.bind(this);
    this.state = { dropDownSwitch: true };
  }

  MultiMatchDropdownOptions = (multiMatchList) =>
    multiMatchList.map((client, i) => {
      return (
        <option key={i} value={client.clientID}>
          {client.clientName}
        </option>
      );
    });

  onClickCustomerName(e) {
    let newClientId = e.target.value;
    if (newClientId !== "") {
      this.setState({ dropDownSwitch: false });
      this.props.actions.patchInteractionResolveMultiMatch(
        this.props.officeAssociateId,
        newClientId,
        this.props.currentInteraction.clientId,
        this.props.currentInteraction.interactionId,
        "multi-match",
        this.props.actions.mainAction(
          UPDATE_CLIENT_TYPE_FOR_MULTIMATCH,
          newClientId
        )
      );
    }

    const dropdown = document.getElementById("multiMatchSelection");
    const contentDivs = document.querySelectorAll(".message-number");
    const selectedOptionText = dropdown.options[dropdown.selectedIndex].text;
    for(const div of contentDivs) {
      div.textContent = selectedOptionText;
    }
  }

  render() {
    return (
      <>
        <div>Multiple Matches - Select Customer</div>
        {this.state.dropDownSwitch && (
          <select
            id="multiMatchSelection"
            type="text"
            aria-required="true"
            style={{ fontSize: "16px" }}
            onChange={(e) => {
              this.onClickCustomerName(e);
            }}
          >
            <option value="" selected></option>
            {this.MultiMatchDropdownOptions(this.props.multiMatchClientInfo)}
          </select>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.authReducer.token,
    officeAssociateId: state.userInfoReducer.officeAssociateId,
    currentInteraction: state.interactionsReducer.currentInteraction,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        patchInteractionResolveMultiMatch,
        mainAction,
      },
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MultipleMatchDropdown);
