import React, { Component } from "react";
class Notification extends Component {
  render() {
    const { type, inPage } = this.props;
    /*[informational | warning | error | confirmation] */
    return (
      <div ref={"notificaton"}>
        <div className={"-oneX -oneX-notification" + (inPage ? " -oneX-notification--in-page" :"")} type={type}>
          <section
            className={"-oneX-notification__icon -oneX-notification--" + type}
          >
            <span
              className={"-oneX-icon--" + type + (inPage ? "" :"--inverse")}
              aria-label={type}
              role="img"
            ></span>
          </section>
          <section className="-oneX-notification__text">
            {this.props.children}
          </section>
        </div>
      </div>
    );
  }
}

export default Notification;
