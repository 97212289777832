export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_SPA_CLIENT_ID, //Client ID of SFConnect app
        authority: process.env.REACT_APP_AUTHORITY,
        redirectUri: '/',
        postLogoutRedirectUri: '/'
    },
    cache: {
        cacheLocation: "localStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
    scopes: ["user.read"],
    forceRefresh: false // Set this to "true" to skip a cached token and go to the server to get a new token
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const apiRequest = {
    scopes: ["User.Read"],
    forceRefresh: false // Set this to "true" to skip a cached token and go to the server to get a new token
};

/*
* Added API app permission url to used in SPA application
* This will retrieve roles for an User accessing SPA application
*/
export const apiRequestScope = {
    scopes: [process.env.REACT_APP_REQUEST_SCOPE]
};

export const uiRequestScope = {
    scopes: [process.env.REACT_APP_UI_LOGGING_REQUEST_SCOPE]
};