import React, { Component } from "react";

export default class MatchHighlight extends Component {
  render() {
    if (!this.props.fuzzySearchResults) {
      return <>{this.props.text}</>;
    }

    const match = this.props.fuzzySearchResults.matches.find(
      (element) => element.key === this.props.fuzzySearchKey
    );
    if (!match) {
      return <>{this.props.text}</>;
    }

    let stringPosition = 0;
    const textSegments = [];

    const { text } = this.props;

    match.indices.forEach((index) => {
      const beforeTextSegment = {
        text: "",
        isHighlighted: false,
      };

      while (stringPosition < index[0]) {
        beforeTextSegment.text += text[stringPosition];
        stringPosition++;
      }
      textSegments.push(beforeTextSegment);

      const matchTextSegment = {
        text: "",
        isHighlighted: true,
      };
      while (stringPosition <= index[1]) {
        matchTextSegment.text += text[stringPosition];
        stringPosition++;
      }
      textSegments.push(matchTextSegment);
    });

    const afterTextSegment = {
      text: "",
      isHighlighted: false,
    };
    while (stringPosition < text.length) {
      afterTextSegment.text += text[stringPosition];
      stringPosition++;
    }
    textSegments.push(afterTextSegment);

    textSegments.forEach((segment) => {
      if (segment.isHighlighted) {
        segment.content = <mark>{segment.text}</mark>;
      } else {
        segment.content = <>{segment.text}</>;
      }
    });
    return (
      <>
        {textSegments.map((segment) => (
          <>{segment.content}</>
        ))}
      </>
    );
  }
}
