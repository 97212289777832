import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Notification from "../components/Notification";
import Breadcrumb from "../components/Breadcrumb";

//import ".././scss/unauthorized.scss";

const TITLE = "Error | SF Connect";
export default class genericError extends Component {
  componentDidMount() {
    this.setState({ panel: { visible: " -oneX-hidden" } });
    if (window.oneX) {
      window.oneX.ExpansionPanel.init();
    }
  }
  render() {
    const actionInError = this.props.actionInError;
    const errorDetails = this.props.errorDetails;
    return (
      <>
        <Helmet>
          <title>{TITLE}</title>
        </Helmet>
        <div className="-oneX-container user-not-authorized-page">
          <div className="-oneX-col">
            <Breadcrumb
              title="Back To Main Page"
              history={this.props.history}
            />
          </div>
          <div className="-oneX-row">
            <div className="-onex-col">
              <Notification type="error" className="notification">
                An error occurred during processing.
              </Notification>
            </div>
          </div>
          <div className="-oneX-col">
            <div className="-oneX-row">
              <div className="-oneX-col -oneX-heading--h4">
                If you have reached this page, an unexpected error has occurred.
                &nbsp;
                <span className="error-action-wording">{actionInError}</span>
              </div>
            </div>
            <div className="-oneX-row">
              <div className="-oneX-col -oneX-heading--h6">
                <p>
                  Please try your action again. If the problem persists, contact
                  the{" "}
                  <span className="error-action-bold-text">
                    Customer Care Center - Support Center
                  </span>{" "}
                  at{" "}
                  <span className="error-action-bold-text">877-889-2294</span>{" "}
                  for further assistance.
                </p>
              </div>
            </div>
          </div>
          {errorDetails && (
            <div className="-oneX-row">
              <div className="-oneX-col">
                {/* start of manage notifications expansion */}
                <hr className="-oneX-panel-divider" />
                <div ref={"expansionPanel"} className="-oneX-panel--expansion">
                  <div className="-oneX-panel-control">
                    <button
                      type="button"
                      className="-oneX-panel-button"
                      aria-expanded="false"
                      aria-controls="errorDetailsDiv"
                      tabIndex="0">
                      Expand for technical error details
                    </button>
                    <span
                      className="-oneX-panel__chevron-down"
                      id="expand_Error"></span>
                  </div>
                  <div
                    id="errorDetailsDiv"
                    tabIndex="0"
                    className="-oneX-panel-content -oneX-hidden">
                    <strong>Technical Details: </strong>
                    {errorDetails}
                  </div>
                </div>
                {/* End of manage notifications expander */}
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}
