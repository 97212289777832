import axios from "axios";
import {
  GET_ASSOCIATE_INFORMATION,
  GET_ASSOCIATE_INFORMATION_ERROR,
  GET_ASSOCIATE_PHONE_INFORMATION,
  GET_ASSOCIATE_PHONE_INFORMATION_ERROR,
  SET_LOGGED_IN_USER,
} from "./types";

export const getAssociateInformation = (token) => async (dispatch) => {
  axios
    .get(process.env.REACT_APP_AWS_ROOT_URL + `/api/user/info`, {
      headers: {
        "X-CONSUMER_KEY": "SFCONNECT_UI",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      dispatch({ type: GET_ASSOCIATE_INFORMATION, payload: response.data });
      dispatch({ type: SET_LOGGED_IN_USER, payload: response.data });
    })
    .catch((error) =>
      dispatch({ type: GET_ASSOCIATE_INFORMATION_ERROR, error })
    );
};

export const getAssociatePhoneInformation = (token) => (dispatch) => {
  axios
    .get(process.env.REACT_APP_AWS_ROOT_URL + `/api/user/phone`, {
      headers: {
        "X-CONSUMER_KEY": "SFCONNECT_UI",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) =>
      dispatch({
        type: GET_ASSOCIATE_PHONE_INFORMATION,
        payload: response.data,
      })
    )
    .catch((error) =>
      dispatch({ type: GET_ASSOCIATE_PHONE_INFORMATION_ERROR, error })
    );
};

export const updateAssociateId = (officeAssociateId) => {
  return {
    type: "UPDATE_ASSOCIATE_ID",
    officeAssociateId,
  };
};
