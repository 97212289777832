import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import { mainAction } from "../../../redux/actions/interactionsActions";
import SFLogo from "./assets/img/SF_logo.svg";
import SFConnectLogo from "./assets/img/SF_Connect_Logo.svg";
import { sfc_routes } from "../../../utils/general";
import { Component } from "react";
import { SHOW_GEAR_ICON } from "../../../redux/actions/types";
class Header extends Component {
  componentDidMount() {
    if (
      window.location.pathname === "/moa" ||
      window.location.pathname === "/error" ||
      window.location.pathname === "/signup"
    ) {
      this.props.actions.mainAction(SHOW_GEAR_ICON, false);
    } else {
      this.props.actions.mainAction(SHOW_GEAR_ICON, true);
    }
  }

  openDrawer = () => {
    window.oneX.Drawer.showDrawer("lnk1");
  };

  render() {
    return (
      <>
        <header className="sfconnect-header">
          <div className="-oneX-container">
            <div className="-oneX-row header-row">
              <div className="-oneX-col-8 -oneX-col-sm-9 -oneX-col-md-8 -oneX-col-lg-3">
                <img src={SFLogo} alt="State Farm" />
              </div>

              <div className="-oneX-col-3 -oneX-col-sm-3 -oneX-col-md-4 -oneX-col-lg-3">
                <img src={SFConnectLogo} alt="SF Connect" />
              </div>

              <div className="-oneX-col-6 -oneX-col-sm-9 -oneX-col-md-8 -oneX-col-lg-3 -mt-10">
                {this.props.userInfoReducer.loggedInAssociateName && (
                  <div className="account-info">
                    <div className="-oneX-heading--h6 heading">Account</div>
                    <div className="-oneX-row info-container">
                      <div className="name">
                        {this.props.userInfoReducer.loggedInAssociateName}&nbsp;
                      </div>
                      {this.props.userInfoReducer.isMoa && (
                        <div className="moaDiv">
                          <button
                            className="changeOfficeButton"
                            onClick={() => {
                              this.props.history.push(sfc_routes.pages.moa);
                            }}>
                            ( Change Office )
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div className="-oneX-col-6 -oneX-col-sm-3 -oneX-col-md-4 -oneX-col-lg-3 -mt-10">
                <div>
                  {this.props.userInfoReducer.loggedInAssociateName &&
                    this.props.showGearIcon && (
                      <div className="-oneX-icon-container -oneX-icon--interactive gear-icon">
                        <div
                          id="lnk1"
                          data-onex-opensdrawer="myDrawer"
                          className="-oneX-icon"
                          data-icon="webui_settings_fill"
                          onClick={() => this.openDrawer()}
                          onKeyPress={() => this.openDrawer()}
                          tabIndex="0"
                          aria-label="Settings Gear"></div>
                      </div>
                    )}
                  <div className="gear-text">
                    {this.props.userInfoReducer.loggedInAssociateName &&
                    this.props.showGearIcon ? (
                      <>
                        {this.props.displayedOfficePhone && (
                          <span
                            className="-oneX-heading--h6 heading"
                            onClick={() => this.openDrawer()}></span>
                        )}
                      </>
                    ) : (
                      <>
                        {/* Extra check for props.officeSettingsIsFetched to hide phone number on signup page*/}
                        {this.props.displayedOfficePhone &&
                          this.props.officeSettingsIsFetched && (
                            <span className="-oneX-heading--h6 heading">
                              Office Phone
                              <div className="-oneX-row info-container">
                                {this.props.displayedOfficePhone}
                              </div>
                            </span>
                          )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.authReducer.token,
    officeAssociateId: state.userInfoReducer.officeAssociateId,
    userInfoReducer: state.userInfoReducer,
    displayedOfficePhone:
      state.officeSettingsReducer.officePhone.displayedOfficePhone,
    officeSettingsIsFetched: state.officeSettingsReducer.isFetched,
    showGearIcon: state.officeSettingsReducer.showGearIcon,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        mainAction,
      },
      dispatch
    ),
  };
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
