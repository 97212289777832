import React, { Component } from "react";
import { Helmet } from "react-helmet";
import queryString from "query-string";

const TITLE = "Sign up for SFConnect";

export default class gateway extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: {
        stateAgentCode: "",
        clientId: "",
        firstName: "",
        lastName: "",
        firstTextableMobilePhone: "",
      },
    };
  }
  componentDidMount() {
    let values = queryString.parse(this.props.location.search);
    this.setState({
      values: {
        stateAgentCode: values.stateAgentCode,
        clientId: values.clientId,
        firstName: values.firstName,
        lastName: values.lastName,
        firstTextableMobilePhone: values.firstTextableMobilePhone,
      },
    });
  }

  render() {
    /*  let stateAgentCode = this.props.match.params.C;
    let clientId = this.props.match.params.clientId;
    let firstName = this.props.match.params.firstName;
    let lastName = this.props.match.params.lastName;
    let firstTextableMobilePhone = this.props.match.params
      .firstTextableMobilePhone; */

    return (
      <>
        <Helmet>
          {this.props.officeMessagesUnreadCount &&
          this.props.officeMessagesUnreadCount > 0 ? (
            <title>
              {"( " + this.props.officeMessagesUnreadCount + " ) " + TITLE}
            </title>
          ) : (
            <title>{TITLE}</title>
          )}
        </Helmet>

        <div>{this.state.values.stateAgentCode}</div>
        <div>{this.state.values.clientId}</div>
        <div>{this.state.values.firstName}</div>
        <div>{this.state.values.lastName}</div>
        <div>{this.state.values.firstTextableMobilePhone}</div>
      </>
    );
  }
}
