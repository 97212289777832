import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { mainAction } from "../../redux/actions/interactionsActions";

class WordTrackDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      officePhone: this.props.displayedOfficePhone,
      panel: {
        visible: "",
        expansionHeight: { height: "41.6px" },
      },
    };
  }

  componentDidMount() {
    //This is needed by oneX onLoad of the object, the content panel must be hidden, then oneX toggles the class.
    this.setState({ panel: { visible: " -oneX-hidden" } });
    if (window.oneX) {
      let oneXItem = {};
      for (oneXItem in this.refs) {
        window.oneX.addElement(this.refs[oneXItem]);
      }
      window.oneX.ExpansionPanel.init();
    }
  }

  setVisible(event) {
    //This is needed by oneX onClick of the object
    this.setState({
      panel: {
        visible: this.state.panel.visible !== ":visible" ? ":visible" : "",
      },
    });
  }

  onClickHandler = (track) => {
    const _wordTrack = track;
    if (typeof _wordTrack === "string") {
      const _newWordTrack = _wordTrack
        .replace(/(&lt;)/g, "<")
        .replace(/(&gt;)/g, ">")
        .replace(/(&amp;)/g, "&")
        .replace(/<office phone number>/g, this.state.officePhone)
        .replace(/<office phone#>/g, this.state.officePhone);

      const { cursorPos } = this.props.textMessageBox;
      const _startMessage = this.props.messageBody
        ? this.props.messageBody.slice(0, cursorPos.start)
        : "";
      const _endMessage = this.props.messageBody
        ? this.props.messageBody.slice(cursorPos.end)
        : "";
      this.props.actions.mainAction(
        "UPDATE_CHAT",
        _startMessage + _newWordTrack + _endMessage
      );

      this.hideDrawer();
      // Give the document focus
      setTimeout(() => {
        const _textArea = document.getElementById("conTA");
        _textArea.focus();
      }, 1000);
    }
  };

  hideDrawer = () => {
    window.oneX.Drawer.hideDrawer();
  };

  keyPressed = (event, track) => {
    if (event.key === "Enter" || event.key === " ") {
      this.onClickHandler(track);
    }
    if (event.key === "Esc") {
      this.hideDrawer(event);
    }
  };

  render() {
    const { wordTracks } = this.props;
    return (
      <>
        <section
          ref={"wordTrackDrawer"}
          id="WordTrackDrawer"
          className="-oneX -oneX-drawer-container"
          style={{ display: "none" }}>
          <div role="dialog" aria-labelledby="WT_dialogLnkHdr">
            <div
              id="WT_dialogLnkHdr"
              className="textTemplatesHeaderBkGround"
              tabIndex="-1">
              <div className="-oneX-icon-container -oneX-icon--interactive textTemplatesHeaderBkGround">
                <div
                  className="-oneX-icon drawer-icon chevronDiv"
                  data-icon="web_ui_chevron"
                  aria-label="Close Drawer"
                  onClick={this.hideDrawer}></div>

                <div className="officeSettingHeaderTextGroupDiv">
                  {/* <span className="officeSettingHeaderTitleSmall">
                    SF Connect
                  </span> */}
                  <h3 className="-oneX-drawer-header ">Text Templates</h3>

                  <div className="sub-title-discription">
                    To avoid a carrier flagging the message as SPAM and blocking
                    delivery, make sure to modify the populated template before
                    sending.
                  </div>
                </div>
              </div>
            </div>
            <div
              ref={"ExpansionPanel"}
              className="-oneX-body--primary -oneX-drawer-body">
              {wordTracks.list.map((list, i) => {
                return (
                  <div key={`list_` + i}>
                    {list.enabled ? (
                      <>
                        <hr className="-oneX-panel-divider" />
                        <div
                          ref={"details_small_" + i}
                          className={
                            "-oneX-panel--expansion--small  word-track-" +
                            list.type
                          }
                          style={this.state.panel.expansionHeight}>
                          <div className="-oneX-panel-control">
                            <button
                              type="button"
                              className="-oneX-panel-button"
                              tabIndex="0"
                              aria-expanded="false"
                              aria-controls={"details_small_" + i}>
                              {list.title}
                            </button>
                            <span className="-oneX-panel__chevron-down"></span>
                          </div>
                          <div
                            id={"details_small_" + i}
                            className="-oneX-panel-content -oneX-hidden">
                            <div className="word-track-content">
                              <ul>
                                {list.items.map((wording, i) => {
                                  return (
                                    <li
                                      key={`wording_` + i}
                                      tabIndex="0"
                                      onClick={(e) =>
                                        this.onClickHandler(wording.track)
                                      }
                                      onKeyPress={(e) =>
                                        this.keyPressed(e, wording.track)
                                      }>
                                      <strong>{wording.title}</strong>
                                      <div className="wordTrack">
                                        {wording.track}
                                      </div>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          </div>
                        </div>
                        <hr className="-oneX-panel-divider" />
                      </>
                    ) : null}
                  </div>
                );
              })}
            </div>
            <button
              id="WT_closeButtonX"
              className="-oneX-close-X-button"
              aria-label="Close Drawer"
              onClick={this.hideDrawer}>
              <span className="-oneX-icon--close"></span>
            </button>
          </div>
        </section>
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    messageBody: state.interactionsReducer.currentInteraction.messageBody,
    textMessageBox: state.interactionsReducer.textMessageBox,
    displayedOfficePhone:
      state.officeSettingsReducer.officePhone.displayedOfficePhone,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        mainAction,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WordTrackDrawer);
