export const ADD_MESSAGE = "ADD_MESSAGE";
export const GET_UUID = "GET_UUID";
export const FETCH_MESSAGES = "FETCH_MESSAGES";
export const FETCH_INTERACTIONS = "FETCH_INTERACTIONS";
export const UPDATE_CHAT = "UPDATE_CHAT";
export const CLEAR_CURRENT_INTERACTION = "CLEAR_CURRENT_INTERACTION";
export const CANCEL_CURRENT_DRAFT = "CANCEL_CURRENT_DRAFT";
export const UPDATE_CHAT_LOG = "UPDATE_CHAT_LOG";
export const SET_CURRENT_INTERACTION = "SET_CURRENT_INTERACTION";
export const SET_AZURE_TOKEN = "SET_AZURE_TOKEN";
export const SET_WEBSOCKET = "SET_WEBSOCKET";
export const UPDATE_INTERACTIONS = "UPDATE_INTERACTIONS";
export const GET_OFFICE_PREFERENCES = "GET_OFFICE_PREFERENCES";
export const PATCH_OFFICE_PREFERENCES = "PATCH_OFFICE_PREFERENCES";
export const FETCH_CUSTOMERS_SUCCESS = "FETCH_CUSTOMERS_SUCCESS";
export const FETCH_CUSTOMERS_ERROR = "FETCH_CUSTOMERS_ERROR";
export const CLEAR_CUSTOMERS = "CLEAR_CUSTOMERS";
export const FETCH_CUSTOMERS_REQUEST = "FETCH_CUSTOMERS_REQUEST";
export const GET_ASSOCIATE_INFORMATION = "GET_ASSOCIATE_INFORMATION";
export const GET_ASSOCIATE_INFORMATION_ERROR =
  "GET_ASSOCIATE_INFORMATION_ERROR";
export const UPDATE_ASSOCIATE_ID = "UPDATE_ASSOCIATE_ID";
export const CREATE_NEW_INTERACTION = "CREATE_NEW_INTERACTION";
export const GET_MMS_URL_SUCCESS = "GET_MMS_URL_SUCCESS";
export const MARK_SPI_SUCCESS = "MARK_SPI_SUCCESS";
export const PATCH_UPDATE_CUSTOMER_PREFERENCES =
  "PATCH_UPDATE_CUSTOMER_PREFERENCES";
export const PATCH_UPDATE_INTERACTION = "PATCH_UPDATE_INTERACTION";
export const PATCH_UPDATE_INTERACTION_RESOLVE_MULTIMATCH =
  "PATCH_UPDATE_INTERACTION_RESOLVE_MULTIMATCH";
export const UPDATE_TEXTAREA_ATTRIBUTES = "UPDATE_TEXTAREA_ATTRIBUTES";
export const GET_INTERACTIONS = "GET_INTERACTIONS";
export const PUT_READ_INTERACTION = "PUT_READ_INTERACTION";
export const GET_CUSTOMER_PREFERENCES = "GET_CUSTOMER_PREFERENCES";
export const GENERIC_ERROR = "GENERIC_ERROR";
export const SET_MMS_INDEX = "SET_MMS_INDEX";
export const MESSAGE_DELIVERY_STATUS = "MESSAGE_DELIVERY_STATUS";
export const UPDATE_MESSAGE_DELIVERY_STATUS = "UPDATE_MESSAGE_DELIVERY_STATUS";
export const UPDATE_MOA_REDIRECT = "UPDATE_MOA_REDIRECT";
export const SEND_WEB_MESSAGE_SUCCESS = "SEND_WEB_MESSAGE_SUCCESS";
export const SHOULD_TEXT_SEND = "SHOULD_TEXT_SEND";
export const UPDATE_DO_NOT_TEXT_STATUS_BAR = "UPDATE_DO_NOT_TEXT_STATUS_BAR";
export const UPDATE_CLIENT_TYPE_FOR_MULTIMATCH =
  "UPDATE_CLIENT_TYPE_FOR_MULTIMATCH";
export const GO_BACK_TO_DRAFT_MESSAGE = "GO_BACK_TO_DRAFT_MESSAGE";
export const GET_ASSOCIATE_PHONE_INFORMATION =
  "GET_ASSOCIATE_PHONE_INFORMATION";
export const GET_ASSOCIATE_PHONE_INFORMATION_ERROR =
  "GET_ASSOCIATE_PHONE_INFORMATION_ERROR";
export const RESET_FETCHED_VALUES = "RESET_FETCHED_VALUES";
export const CLEAR_GENERIC_ERROR = "CLEAR_GENERIC_ERROR";
export const GET_CUSTOMER_INFO = "GET_CUSTOMER_INFO";
export const RESET_CUSTOMER_INFO_RETRIEVED = "RESET_CUSTOMER_INFO_RETRIEVED";
export const ADD_ECRM_MOBILE_NUMBER = "ADD_ECRM_MOBILE_NUMBER";
export const ARCHIVE_INTERACTION = "ARCHIVE_INTERACTION";
export const CHANGE_FILTER_OPTIONS = "CHANGE_FILTER_OPTIONS";
export const SET_CURRENT_FILTER = "SET_CURRENT_FILTER";
export const CLEAR_FILTERS = "CLEAR_FILTERS";
export const SHOW_GEAR_ICON = "SHOW_GEAR_ICON";
export const STORE_CUSTOMER_INFO_FOR_PROSPECT_RESOLUTION =
  "STORE_CUSTOMER_INFO_FOR_PROSPECT_RESOLUTION";
export const PATCH_UPDATE_INTERACTION_PROSPECT_TO_CUSTOMER =
  "PATCH_UPDATE_INTERACTION_PROSPECT_TO_CUSTOMER";
export const PATCH_UPDATE_STARRED_INTERACTION =
  "PATCH_UPDATE_STARRED_INTERACTION";
export const ATTACH_TO_MESSAGE = "ATTACH_TO_MESSAGE";
export const UPDATE_MESSAGE_BODY = "UPDATE_MESSAGE_BODY";
export const PATCH_INTERACTION_HIDDEN_SETTING =
  "PATCH_INTERACTION_HIDDEN_SETTING";
export const REMOVE_INTERACTION = "REMOVE_INTERACTION";
export const RESET_INTERACTIONS = "RESET_INTERACTIONS";
export const PATCH_INTERACTION_ASSIGN_SETTING =
  "PATCH_INTERACTION_ASSIGN_SETTING";
export const ADD_INTERACTION = "ADD_INTERACTION";
export const HIDE_INTERACTION = "HIDE_INTERACTION";
export const UNHIDE_INTERACTION = "UNHIDE_INTERACTION";
export const SET_LOGGED_IN_USER = "SET_LOGGED_IN_USER";
export const SET_ASSIGNMENTS_FILTER = "SET_ASSIGNMENTS_FILTER";
export const UPDATE_UNASSIGNED_FILTER = "UPDATE_UNASSIGNED_FILTER";
export const CLEAR_INTERACTIONS = "CLEAR_INTERACTIONS";
export const ASSIGN_INTERACTION = "ASSIGN_INTERACTION";
export const UNASSIGN_INTERACTION = "UNASSIGN_INTERACTION";
