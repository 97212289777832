import React, { useLayoutEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  markAsSPI,
  archiveInteraction,
} from "redux/actions/interactionsActions";
import LineOfBusinessDropDown from "../../components/Message/LineOfBusinessDropDown";

const Modal = React.forwardRef((props, ref) => {
  const [intent, setIntent] = useState("Unknown");
  const [lineOfBusiness, setLineOfBusiness] = useState("Unknown");
  const {
    messageId,
    messageIndex,
    attachmentIndex,
    attachmentMessage,
    agentId,
    token,
    clientDataForArchive,
    interactionId,
  } = props;
  // const uploadFileRef = useRef();
  const modalObject = useRef();
  useLayoutEffect(() => {
    const current = modalObject.current;
    window.oneX.addElement(current);

    return () => {
      window.oneX.removeElement(current);
    };
  }, []);

  const onClickModalOption = (event) => {
    closeModal();
    props.callback(props.clientData, event.target.value);
  };

  const onClickModalSPI = (event) => {
    closeModal();
    props.actions.markAsSPI(
      messageId,
      messageIndex,
      attachmentIndex,
      attachmentMessage,
      token,
      agentId
    );
    setTimeout(() => {
      window.location.reload();
    }, 10000);
  };

  const onClickModalAttach = (event) => {
    ref.current.click();
  };

  const closeModal = () => {
    window.oneX.Modal.hideModal();
  };

  const keyPressed = (event, onClickHandler) => {
    if (event.key === "Enter" || event.key === " ") {
      onClickHandler();
    }
    if (/[E|e][S|s][C|c]/.test(event.key)) {
      closeModal();
    }
  };

  const capitalize = (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  };

  const onClickModalArchive = (event) => {
    event.stopPropagation();
    closeModal();
    props.actions.archiveInteraction(
      token,
      agentId,
      interactionId,
      clientDataForArchive.clientId,
      lineOfBusiness,
      intent,
      props.userInfoReducer.currentOfficeMembers,
      props.userInfoReducer.loggedInAssociateId
    );
  };

  const storeDropdownValues = (name, value) => {
    name === "lineOfBusiness" ? setLineOfBusiness(value) : setIntent(value);
  };

  let clientDataModal = {},
    markSpiModal = {},
    archiveModal = {},
    attachFileModal = {};
  if (props.clientData) {
    clientDataModal = {
      id: "oneX-modal-do-not-text-" + props.clientData.hashedClientId,
      labelledby: "dialogForSetDoNotText" + props.clientData.hashedClientId,
      header:
        "Do Not Text " +
        capitalize(props.clientData.customerDetails.firstName) +
        " " +
        capitalize(props.clientData.customerDetails.lastName),
      body_text: [
        `You have chosen "Do Not Text" for this customer. You will not be able to undo this change. The customer would have to do so by texting "UNSTOP" to the office.`,
        <br key={props.clientData.hashedClientId + "_a"} />,
        <br key={props.clientData.hashedClientId + "_b"} />,
        `Any ongoing interactions with this customer will be moved to ECRM Activity Outline and removed from your Interaction Feed.`,
      ],
      primary_button: {
        text: "Set to Do Not Text",
        onClick: onClickModalOption,
      },
      cancel_button: {
        text: "Cancel",
        onClick: closeModal,
      },
    };
  }

  if (props.attachmentUrl) {
    markSpiModal = {
      id: "oneX-modal-spi-confirmation-" + props.messageIndex,
      labelledby: "dialogNoAction-" + props.messageIndex,
      header: "Mark as SPI",
      body_text:
        "Are you sure you want to mark this attachment as SPI? Once marked, the attachment is no longer viewable.",
      primary_button: {
        text: "Mark as SPI",
        onClick: onClickModalSPI,
      },
      cancel_button: {
        text: "Cancel",
        onClick: closeModal,
      },
    };
  }

  if (props.interactionId) {
    const headerName =
      clientDataForArchive.customerName === ""
        ? clientDataForArchive.phoneNumber
        : clientDataForArchive.customerName;
    archiveModal = {
      id: "oneX-modal-archive-" + props.interactionId,
      labelledby: "dialogforArchival-" + props.interactionId,
      header: "Archive Interaction for " + headerName,
      body_text: [
        `For compliance reasons, all texts are saved. Saved customer and prospect texts are viewed in ECRM on the Customer Page.`,
        <br key={props.interactionId + "_a"} />,
        <br key={props.interactionId + "_b"} />,
        `Line of Business and Intent will be used in ECRM to filter archived messages.`,
        <LineOfBusinessDropDown
          key={props.interactionId + "_c"}
          interactionId={props.interactionId}
          dropdownValues={storeDropdownValues}
        />,
      ],
      primary_button: {
        text: "Archive",
        onClick: onClickModalArchive,
      },
      cancel_button: {
        text: "Cancel",
        onClick: closeModal,
      },
    };
  }

  if (props.attachFile) {
    attachFileModal = {
      id: "oneX-modal-attach-" + props.currentInteraction,
      labelledby: "dialogforAttachment-" + props.currentInteraction,
      header: "Attach A File",
      body_text: [
        "To protect sensitive personal information, do not include files that include full SSN, TIN, SIN, DL#, financial account numbers, credit/debit card numbers, PHI or any medical information.",
        <br />,
        <br />,
        "Accepted file types include jpeg, gif, png, bmp, vcf and PDF (dependent on carrier).  Audio/video files are not supported. File size must be less than 1MB. Do not include any SPI.",
        <br />,
        <br />,
        "I affirm that this attachment meets the criteria defined.",
      ],
      primary_button: {
        text: "Accept & Upload",
        onClick: onClickModalAttach,
      },
      cancel_button: {
        text: "Cancel",
        onClick: closeModal,
      },
    };
  }

  //const oneXModal = props.clientData ? clientDataModal : markSpiModal
  let oneXModal = props.clientData
    ? clientDataModal
    : props.interactionId
    ? archiveModal
    : props.attachmentUrl
    ? markSpiModal
    : props.attachFile
    ? attachFileModal
    : {};

  //   if (props.clientData) {
  //     oneXModal = clientDataModal;
  //   }

  // if (props.attachFile) oneXModal = attachFileModal;

  return (
    <>
      <div
        ref={modalObject}
        key={props.interactionId}
        id={oneXModal.id}
        className="-oneX -oneX-modal__container"
        style={{ display: "none" }}>
        <div
          className="-oneX-modal-content"
          aria-labelledby={oneXModal.labelledby}
          role="dialog">
          <div
            id={oneXModal.labelledby}
            className="-oneX-modal-heading"
            role="heading"
            aria-level="2"
            tabIndex="-1">
            {oneXModal.header}
          </div>
          <div className="-oneX-modal-body">{oneXModal.body_text}</div>

          {props.attachmentUrl && (
            <img
              src={props.attachmentPreviewUrl}
              className="-sfc-attachment -oneX-offset-4"
              alt="Attachment Preview"
              style={{ height: "300px" }}
            />
          )}

          <div className="-oneX-modal-footer">
            <div className="-oneX-row">
              <div className="-oneX-modal-primary__div -oneX-col">
                <button
                  id={"oneX-modal-primary-action-" + oneXModal.id}
                  className="-oneX-btn-primary"
                  onClick={(e) => {
                    oneXModal.primary_button.onClick(e);
                  }}
                  onKeyPress={(e) => {
                    keyPressed(e, oneXModal.primary_button.onClick);
                  }}
                  value="true">
                  {oneXModal.primary_button.text}
                </button>
              </div>
              <div className="-oneX-modal-secondary__div -oneX-col">
                <button
                  id={"oneX-modal-secondary-action-" + oneXModal.id}
                  className="-oneX-btn-secondary"
                  onClick={() => {
                    closeModal();
                  }}
                  onKeyPress={(e) => {
                    keyPressed(e, oneXModal.cancel_button.onClick);
                  }}
                  value="false">
                  {oneXModal.cancel_button.text}
                </button>
              </div>
            </div>
          </div>
          <button
            type="button"
            className="-oneX-close-X-button"
            aria-label="Close Modal"
            onKeyPress={(e) => {
              keyPressed(e, oneXModal.cancel_button.onClick);
            }}>
            <span
              className="-oneX-icon--close"
              onClick={() => {
                closeModal();
              }}
              value="false"></span>
          </button>
        </div>
      </div>
      <input
        type="file"
        id="attachment-upload"
        ref={ref}
        onChange={(e) => {
          if (e.target.files.length > 0) {
            props.attachFile(e);
          }
        }}
        style={{ display: "none" }}></input>
    </>
  );
});

function mapStateToProps(state) {
  return {
    currentInteraction:
      state.interactionsReducer.currentInteraction.interactionId,
    messageId: state.interactionsReducer.messageId,
    messageIndex: state.interactionsReducer.messageIndex,
    attachmentIndex: state.interactionsReducer.attachmentIndex,
    attachmentMessage: state.interactionsReducer.attachmentMessage,
    agentId: state.userInfoReducer.officeAssociateId,
    token: state.authReducer.token,
    userInfoReducer: state.userInfoReducer,
    attachmentPreviewUrl: state.interactionsReducer.attachmentUrl,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        markAsSPI: markAsSPI,
        archiveInteraction: archiveInteraction,
      },
      dispatch
    ),
  };
}
export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(Modal);
