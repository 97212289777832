import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Cancel from "./Cancel";
import { patchInteractionHiddenSetting } from "../../redux/actions/interactionsActions";

class Hide extends Component {
  setHiddenFlag(agentId, interactionId, flag, clientId, loggedInAssociateId) {
    console.log("setHiddenFlag params: ", {
      agentId,
      interactionId,
      flag,
      clientId,
      loggedInAssociateId,
    });
    this.props.actions.patchInteractionHiddenSetting(
      agentId,
      interactionId,
      !flag,
      clientId,
      loggedInAssociateId
    );
  }

  render() {
    const {
      interactionId,
      virtualAssistantActive,
      hidden,
      agentId,
      clientId,
      loggedInAssociateId,
    } = this.props;
    let textToDisplayOnButton = hidden.value ? "Unhide" : "Hide";
    let classNameValue = hidden.value ? "-sfc-hide-button-left -oneX-col-3" : "-sfc-hide-button -oneX-col-3";
    let titleValue = hidden.value
      ? "click to unhide this interaction"
      : "click to hide this interaction";
    return (
      <>
        {virtualAssistantActive === true ? (
          ""
        ) : interactionId === "tempInteractionId" ? (
          <Cancel interactionId={interactionId} />
        ) : (
          <div className={classNameValue} title={titleValue}>
            <button
              className="-oneX-btn-secondary -oneX-btn-small"
              onClick={() =>
                this.setHiddenFlag(
                  agentId,
                  interactionId,
                  hidden.value,
                  clientId,
                  loggedInAssociateId
                )
              }>
              {textToDisplayOnButton}
            </button>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  interactionsReducer: state.interactionsReducer,
});

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        patchInteractionHiddenSetting,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Hide);
