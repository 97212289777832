export const emoji = {
    list: [
        {
            type: 'frequent',
            section: "Approved List",
            enabled: true,
            items: [
                { title: "smile", image: "🙂" },
                { title: "smiley", image: "😂" },
                { title: "sob", image: "😃" },
                { title: "wink", image: "😉" },
                { title: "sunglasses", image: "😎" },
                { title: "disappointed_relieved", image: "😥" },
                { title: "100_precent", image: "💯" },
                { title: "phone_call", image: "📞" },
                { title: "heart", image: "❤" },
                { title: "star", image: "⭐" },
                { title: "car", image: "🚗" },
                { title: "truck", image: "🚙" },
                { title: "tractor", image: "🚜" },
                { title: "house", image: "🏠" },
                { title: "office", image: "🏢" },
                { title: "calendar", image: "📆" },
                { title: "thumbs_up", image: "👍" },
                { title: "thumbs_down", image: "👎" }
            ]
        },
        {
            type: 'people',
            section: "People",
            enabled: false,
            items: [
                { title: "smile", image: "😄" },
                { title: "smiley", image: "😃" },
                { title: "grinning", image: "😀" },
                { title: "blush", image: "😊" },
                { title: "wink", image: "😉" },
                { title: "heart_eyes", image: "😍" },
                { title: "kissing_heart", image: "😘" },
                { title: "kissing_closed_eyes", image: "😚" },
                { title: "kissing", image: "😗" },
                { title: "kissing_smiling_eyes", image: "😙" },
                { title: "stuck_out_tongue_winking_eye", image: "😜" },
                { title: "stuck_out_tongue_closed_eyes", image: "😝" },
                { title: "stuck_out_tongue", image: "😛" },
                { title: "flushed", image: "😳" },
                { title: "grin", image: "😁" },
                { title: "pensive", image: "😔" },
                { title: "relieved", image: "😌" },
                { title: "unamused", image: "😒" },
                { title: "disappointed", image: "😞" },
                { title: "persevere", image: "😣" },
                { title: "cry", image: "😢" },
                { title: "joy", image: "😂" },
                { title: "sob", image: "😭" },
                { title: "sleepy", image: "😪" },
                { title: "disappointed_relieved", image: "😥" },
                { title: "cold_sweat", image: "😰" },
                { title: "sweat_smile", image: "😅" },
                { title: "sweat", image: "😓" },
                { title: "weary", image: "😩" },
                { title: "tired_face", image: "😫" },
                { title: "fearful", image: "😨" },
                { title: "scream", image: "😱" },
                { title: "angry", image: "😠" },
                { title: "rage", image: "😡" },
                { title: "triumph", image: "😤" },
                { title: "confounded", image: "😖" },
                { title: "laughing", image: "😆" },
                { title: "yum", image: "😋" },
                { title: "mask", image: "😷" },
                { title: "sunglasses", image: "😎" },
                { title: "sleeping", image: "😴" },
                { title: "dizzy_face", image: "😵" },
                { title: "astonished", image: "😲" },
                { title: "worried", image: "😟" },
                { title: "frowning", image: "😦" },
                { title: "anguished", image: "😧" },
                { title: "imp", image: "👿" },
                { title: "open_mouth", image: "😮" },
                { title: "grimacing", image: "😬" },
                { title: "neutral_face", image: "😐" },
                { title: "confused", image: "😕" },
                { title: "hushed", image: "😯" },
                { title: "smirk", image: "😏" },
                { title: "expressionless", image: "😑" },
                { title: "man_with_gua_pi_mao", image: "👲" },
                { title: "man_with_turban", image: "👳" },
                { title: "cop", image: "👮" },
                { title: "construction_worker", image: "👷" },
                { title: "guardsman", image: "💂" },
                { title: "baby", image: "👶" },
                { title: "boy", image: "👦" },
                { title: "girl", image: "👧" },
                { title: "man", image: "👨" },
                { title: "woman", image: "👩" },
                { title: "older_man", image: "👴" },
                { title: "older_woman", image: "👵" },
                { title: "person_with_blond_hair", image: "👱" },
                { title: "angel", image: "👼" },
                { title: "princess", image: "👸" },
                { title: "smiley_cat", image: "😺" },
                { title: "smile_cat", image: "😸" },
                { title: "heart_eyes_cat", image: "😻" },
                { title: "kissing_cat", image: "😽" },
                { title: "smirk_cat", image: "😼" },
                { title: "scream_cat", image: "🙀" },
                { title: "crying_cat_face", image: "😿" },
                { title: "joy_cat", image: "😹" },
                { title: "pouting_cat", image: "😾" },
                { title: "japanese_ogre", image: "👹" },
                { title: "japanese_goblin", image: "👺" },
                { title: "see_no_evil", image: "🙈" },
                { title: "hear_no_evil", image: "🙉" },
                { title: "speak_no_evil", image: "🙊" },
                { title: "skull", image: "💀" },
                { title: "alien", image: "👽" },
                { title: "hankey", image: "💩" },
                { title: "fire", image: "🔥" },
                { title: "sparkles", image: "✨" },
                { title: "star2", image: "🌟" },
                { title: "dizzy", image: "💫" },
                { title: "boom", image: "💥" },
                { title: "anger", image: "💢" },
                { title: "sweat_drops", image: "💦" },
                { title: "droplet", image: "💧" },
                { title: "zzz", image: "💤" },
                { title: "dash", image: "💨" },
                { title: "ear", image: "👂" },
                { title: "eyes", image: "👀" },
                { title: "nose", image: "👃" },
                { title: "tongue", image: "👅" },
                { title: "lips", image: "👄" },
                { title: "thumbs_up", image: "👍" },
                { title: "-1", image: "👎" },
                { title: "ok_hand", image: "👌" },
                { title: "facepunch", image: "👊" },
                { title: "fist", image: "✊" },
                { title: "wave", image: "👋" },
                { title: "hand", image: "✋" },
                { title: "open_hands", image: "👐" },
                { title: "point_up_2", image: "👆" },
                { title: "point_down", image: "👇" },
                { title: "point_right", image: "👉" },
                { title: "point_left", image: "👈" },
                { title: "raised_hands", image: "🙌" },
                { title: "pray", image: "🙏" },
                { title: "clap", image: "👏" },
                { title: "muscle", image: "💪" },
                { title: "walking", image: "🚶" },
                { title: "runner", image: "🏃" },
                { title: "dancer", image: "💃" },
                { title: "couple", image: "👫" },
                { title: "family", image: "👪" },
                { title: "couplekiss", image: "💏" },
                { title: "couple_with_heart", image: "💑" },
                { title: "dancers", image: "👯" },
                { title: "ok_woman", image: "🙆" },
                { title: "no_good", image: "🙅" },
                { title: "information_desk_person", image: "💁" },
                { title: "raising_hand", image: "🙋" },
                { title: "massage", image: "💆" },
                { title: "haircut", image: "💇" },
                { title: "nail_care", image: "💅" },
                { title: "bride_with_veil", image: "👰" },
                { title: "person_with_pouting_face", image: "🙎" },
                { title: "person_frowning", image: "🙍" },
                { title: "bow", image: "🙇" },
                { title: "tophat", image: "🎩" },
                { title: "crown", image: "👑" },
                { title: "womans_hat", image: "👒" },
                { title: "athletic_shoe", image: "👟" },
                { title: "mans_shoe", image: "👞" },
                { title: "sandal", image: "👡" },
                { title: "high_heel", image: "👠" },
                { title: "boot", image: "👢" },
                { title: "shirt", image: "👕" },
                { title: "necktie", image: "👔" },
                { title: "womans_clothes", image: "👚" },
                { title: "dress", image: "👗" },
                { title: "running_shirt_with_sash", image: "🎽" },
                { title: "jeans", image: "👖" },
                { title: "kimono", image: "👘" },
                { title: "bikini", image: "👙" },
                { title: "briefcase", image: "💼" },
                { title: "handbag", image: "👜" },
                { title: "pouch", image: "👝" },
                { title: "purse", image: "👛" },
                { title: "eyeglasses", image: "👓" },
                { title: "ribbon", image: "🎀" },
                { title: "closed_umbrella", image: "🌂" },
                { title: "lipstick", image: "💄" },
                { title: "yellow_heart", image: "💛" },
                { title: "blue_heart", image: "💙" },
                { title: "purple_heart", image: "💜" },
                { title: "green_heart", image: "💚" },
                { title: "broken_heart", image: "💔" },
                { title: "heartpulse", image: "💗" },
                { title: "heartbeat", image: "💓" },
                { title: "two_hearts", image: "💕" },
                { title: "sparkling_heart", image: "💖" },
                { title: "revolving_hearts", image: "💞" },
                { title: "cupid", image: "💘" },
                { title: "love_letter", image: "💌" },
                { title: "kiss", image: "💋" },
                { title: "ring", image: "💍" },
                { title: "gem", image: "💎" },
                { title: "bust_in_silhouette", image: "👤" },
                { title: "speech_balloon", image: "💬" },
                { title: "footprints", image: "👣" }
            ]
        },
        {
            type: 'nature',
            section: "Nature",
            enabled: false,
            items: [
                { title: "dog", image: "🐶" },
                { title: "wolf", image: "🐺" },
                { title: "cat", image: "🐱" },
                { title: "mouse", image: "🐭" },
                { title: "hamster", image: "🐹" },
                { title: "rabbit", image: "🐰" },
                { title: "frog", image: "🐸" },
                { title: "tiger", image: "🐯" },
                { title: "koala", image: "🐨" },
                { title: "bear", image: "🐻" },
                { title: "pig", image: "🐷" },
                { title: "pig_nose", image: "🐽" },
                { title: "cow", image: "🐮" },
                { title: "boar", image: "🐗" },
                { title: "monkey_face", image: "🐵" },
                { title: "monkey", image: "🐒" },
                { title: "horse", image: "🐴" },
                { title: "sheep", image: "🐑" },
                { title: "elephant", image: "🐘" },
                { title: "panda_face", image: "🐼" },
                { title: "penguin", image: "🐧" },
                { title: "bird", image: "🐦" },
                { title: "baby_chick", image: "🐤" },
                { title: "hatched_chick", image: "🐥" },
                { title: "hatching_chick", image: "🐣" },
                { title: "chicken", image: "🐔" },
                { title: "snake", image: "🐍" },
                { title: "turtle", image: "🐢" },
                { title: "bug", image: "🐛" },
                { title: "bee", image: "🐝" },
                { title: "ant", image: "🐜" },
                { title: "beetle", image: "🐞" },
                { title: "snail", image: "🐌" },
                { title: "octopus", image: "🐙" },
                { title: "shell", image: "🐚" },
                { title: "tropical_fish", image: "🐠" },
                { title: "fish", image: "🐟" },
                { title: "dolphin", image: "🐬" },
                { title: "whale", image: "🐳" },
                { title: "racehorse", image: "🐎" },
                { title: "dragon_face", image: "🐲" },
                { title: "blowfish", image: "🐡" },
                { title: "camel", image: "🐫" },
                { title: "poodle", image: "🐩" },
                { title: "feet", image: "🐾" },
                { title: "bouquet", image: "💐" },
                { title: "cherry_blossom", image: "🌸" },
                { title: "tulip", image: "🌷" },
                { title: "four_leaf_clover", image: "🍀" },
                { title: "rose", image: "🌹" },
                { title: "sunflower", image: "🌻" },
                { title: "hibiscus", image: "🌺" },
                { title: "maple_leaf", image: "🍁" },
                { title: "leaves", image: "🍃" },
                { title: "fallen_leaf", image: "🍂" },
                { title: "herb", image: "🌿" },
                { title: "ear_of_rice", image: "🌾" },
                { title: "mushroom", image: "🍄" },
                { title: "cactus", image: "🌵" },
                { title: "palm_tree", image: "🌴" },
                { title: "chestnut", image: "🌰" },
                { title: "seedling", image: "🌱" },
                { title: "blossom", image: "🌼" },
                { title: "new_moon", image: "🌑" },
                { title: "first_quarter_moon", image: "🌓" },
                { title: "moon", image: "🌔" },
                { title: "full_moon", image: "🌕" },
                { title: "first_quarter_moon_with_face", image: "🌛" },
                { title: "crescent_moon", image: "🌙" },
                { title: "earth_asia", image: "🌏" },
                { title: "volcano", image: "🌋" },
                { title: "milky_way", image: "🌌" },
                { title: "stars", image: "🌠" },
                { title: "partly_sunny", image: "⛅" },
                { title: "snowman", image: "⛄" },
                { title: "cyclone", image: "🌀" },
                { title: "foggy", image: "🌁" },
                { title: "rainbow", image: "🌈" },
                { title: "ocean", image: "🌊" }
            ]
        },
        {
            type: "objects",
            section: "Objects",
            enabled: false,
            items: [
                { title: "bamboo", image: "🎍" },
                { title: "gift_heart", image: "💝" },
                { title: "dolls", image: "🎎" },
                { title: "school_satchel", image: "🎒" },
                { title: "mortar_board", image: "🎓" },
                { title: "flags", image: "🎏" },
                { title: "fireworks", image: "🎆" },
                { title: "sparkler", image: "🎇" },
                { title: "wind_chime", image: "🎐" },
                { title: "rice_scene", image: "🎑" },
                { title: "jack_o_lantern", image: "🎃" },
                { title: "ghost", image: "👻" },
                { title: "santa", image: "🎅" },
                { title: "christmas_tree", image: "🎄" },
                { title: "gift", image: "🎁" },
                { title: "tanabata_tree", image: "🎋" },
                { title: "tada", image: "🎉" },
                { title: "confetti_ball", image: "🎊" },
                { title: "balloon", image: "🎈" },
                { title: "crossed_flags", image: "🎌" },
                { title: "crystal_ball", image: "🔮" },
                { title: "movie_camera", image: "🎥" },
                { title: "camera", image: "📷" },
                { title: "video_camera", image: "📹" },
                { title: "vhs", image: "📼" },
                { title: "cd", image: "💿" },
                { title: "dvd", image: "📀" },
                { title: "minidisc", image: "💽" },
                { title: "floppy_disk", image: "💾" },
                { title: "computer", image: "💻" },
                { title: "iphone", image: "📱" },
                { title: "telephone_receiver", image: "📞" },
                { title: "pager", image: "📟" },
                { title: "fax", image: "📠" },
                { title: "satellite", image: "📡" },
                { title: "tv", image: "📺" },
                { title: "radio", image: "📻" },
                { title: "loud_sound", image: "🔊" },
                { title: "bell", image: "🔔" },
                { title: "loudspeaker", image: "📢" },
                { title: "mega", image: "📣" },
                { title: "hourglass_flowing_sand", image: "⏳" },
                { title: "hourglass", image: "⌛" },
                { title: "alarm_clock", image: "⏰" },
                { title: "watch", image: "⌚" },
                { title: "unlock", image: "🔓" },
                { title: "lock", image: "🔒" },
                { title: "lock_with_ink_pen", image: "🔏" },
                { title: "closed_lock_with_key", image: "🔐" },
                { title: "key", image: "🔑" },
                { title: "mag_right", image: "🔎" },
                { title: "bulb", image: "💡" },
                { title: "flashlight", image: "🔦" },
                { title: "electric_plug", image: "🔌" },
                { title: "battery", image: "🔋" },
                { title: "mag", image: "🔍" },
                { title: "bath", image: "🛀" },
                { title: "toilet", image: "🚽" },
                { title: "wrench", image: "🔧" },
                { title: "nut_and_bolt", image: "🔩" },
                { title: "hammer", image: "🔨" },
                { title: "door", image: "🚪" },
                { title: "smoking", image: "🚬" },
                { title: "bomb", image: "💣" },
                { title: "gun", image: "🔫" },
                { title: "hocho", image: "🔪" },
                { title: "pill", image: "💊" },
                { title: "syringe", image: "💉" },
                { title: "moneybag", image: "💰" },
                { title: "yen", image: "💴" },
                { title: "dollar", image: "💵" },
                { title: "credit_card", image: "💳" },
                { title: "money_with_wings", image: "💸" },
                { title: "calling", image: "📲" },
                { title: "e-mail", image: "📧" },
                { title: "inbox_tray", image: "📥" },
                { title: "outbox_tray", image: "📤" },
                { title: "envelope_with_arrow", image: "📩" },
                { title: "incoming_envelope", image: "📨" },
                { title: "mailbox", image: "📫" },
                { title: "mailbox_closed", image: "📪" },
                { title: "postbox", image: "📮" },
                { title: "package", image: "📦" },
                { title: "memo", image: "📝" },
                { title: "page_facing_up", image: "📄" },
                { title: "page_with_curl", image: "📃" },
                { title: "bookmark_tabs", image: "📑" },
                { title: "bar_chart", image: "📊" },
                { title: "chart_with_upwards_trend", image: "📈" },
                { title: "chart_with_downwards_trend", image: "📉" },
                { title: "scroll", image: "📜" },
                { title: "clipboard", image: "📋" },
                { title: "date", image: "📅" },
                { title: "calendar", image: "📆" },
                { title: "card_index", image: "📇" },
                { title: "file_folder", image: "📁" },
                { title: "open_file_folder", image: "📂" },
                { title: "pushpin", image: "📌" },
                { title: "paperclip", image: "📎" },
                { title: "straight_ruler", image: "📏" },
                { title: "triangular_ruler", image: "📐" },
                { title: "closed_book", image: "📕" },
                { title: "green_book", image: "📗" },
                { title: "blue_book", image: "📘" },
                { title: "orange_book", image: "📙" },
                { title: "notebook", image: "📓" },
                { title: "notebook_with_decorative_cover", image: "📔" },
                { title: "ledger", image: "📒" },
                { title: "books", image: "📚" },
                { title: "book", image: "📖" },
                { title: "bookmark", image: "🔖" },
                { title: "name_badge", image: "📛" },
                { title: "newspaper", image: "📰" },
                { title: "art", image: "🎨" },
                { title: "clapper", image: "🎬" },
                { title: "microphone", image: "🎤" },
                { title: "headphones", image: "🎧" },
                { title: "musical_score", image: "🎼" },
                { title: "musical_note", image: "🎵" },
                { title: "notes", image: "🎶" },
                { title: "musical_keyboard", image: "🎹" },
                { title: "violin", image: "🎻" },
                { title: "trumpet", image: "🎺" },
                { title: "saxophone", image: "🎷" },
                { title: "guitar", image: "🎸" },
                { title: "space_invader", image: "👾" },
                { title: "video_game", image: "🎮" },
                { title: "black_joker", image: "🃏" },
                { title: "flower_playing_cards", image: "🎴" },
                { title: "mahjong", image: "🀄" },
                { title: "game_die", image: "🎲" },
                { title: "dart", image: "🎯" },
                { title: "football", image: "🏈" },
                { title: "basketball", image: "🏀" },
                { title: "soccer", image: "⚽" },
                { title: "baseball", image: "⚾" },
                { title: "tennis", image: "🎾" },
                { title: "8ball", image: "🎱" },
                { title: "bowling", image: "🎳" },
                { title: "golf", image: "⛳" },
                { title: "checkered_flag", image: "🏁" },
                { title: "trophy", image: "🏆" },
                { title: "ski", image: "🎿" },
                { title: "snowboarder", image: "🏂" },
                { title: "swimmer", image: "🏊" },
                { title: "surfer", image: "🏄" },
                { title: "fishing_pole_and_fish", image: "🎣" },
                { title: "tea", image: "🍵" },
                { title: "sake", image: "🍶" },
                { title: "beer", image: "🍺" },
                { title: "beers", image: "🍻" },
                { title: "cocktail", image: "🍸" },
                { title: "tropical_drink", image: "🍹" },
                { title: "wine_glass", image: "🍷" },
                { title: "fork_and_knife", image: "🍴" },
                { title: "pizza", image: "🍕" },
                { title: "hamburger", image: "🍔" },
                { title: "fries", image: "🍟" },
                { title: "poultry_leg", image: "🍗" },
                { title: "meat_on_bone", image: "🍖" },
                { title: "spaghetti", image: "🍝" },
                { title: "curry", image: "🍛" },
                { title: "fried_shrimp", image: "🍤" },
                { title: "bento", image: "🍱" },
                { title: "sushi", image: "🍣" },
                { title: "fish_cake", image: "🍥" },
                { title: "rice_ball", image: "🍙" },
                { title: "rice_cracker", image: "🍘" },
                { title: "rice", image: "🍚" },
                { title: "ramen", image: "🍜" },
                { title: "stew", image: "🍲" },
                { title: "oden", image: "🍢" },
                { title: "dango", image: "🍡" },
                { title: "egg", image: "🍳" },
                { title: "bread", image: "🍞" },
                { title: "doughnut", image: "🍩" },
                { title: "custard", image: "🍮" },
                { title: "icecream", image: "🍦" },
                { title: "ice_cream", image: "🍨" },
                { title: "shaved_ice", image: "🍧" },
                { title: "birthday", image: "🎂" },
                { title: "cake", image: "🍰" },
                { title: "cookie", image: "🍪" },
                { title: "chocolate_bar", image: "🍫" },
                { title: "candy", image: "🍬" },
                { title: "lollipop", image: "🍭" },
                { title: "honey_pot", image: "🍯" },
                { title: "apple", image: "🍎" },
                { title: "green_apple", image: "🍏" },
                { title: "tangerine", image: "🍊" },
                { title: "cherries", image: "🍒" },
                { title: "grapes", image: "🍇" },
                { title: "watermelon", image: "🍉" },
                { title: "strawberry", image: "🍓" },
                { title: "peach", image: "🍑" },
                { title: "melon", image: "🍈" },
                { title: "banana", image: "🍌" },
                { title: "pineapple", image: "🍍" },
                { title: "sweet_potato", image: "🍠" },
                { title: "eggplant", image: "🍆" },
                { title: "tomato", image: "🍅" },
                { title: "corn", image: "🌽" },
            ]
        },
        {
            type: "places",
            section: "Places",
            enabled: false,
            items: [
                { title: "house", image: "🏠" },
                { title: "house_with_garden", image: "🏡" },
                { title: "school", image: "🏫" },
                { title: "office", image: "🏢" },
                { title: "post_office", image: "🏣" },
                { title: "hospital", image: "🏥" },
                { title: "bank", image: "🏦" },
                { title: "convenience_store", image: "🏪" },
                { title: "love_hotel", image: "🏩" },
                { title: "hotel", image: "🏨" },
                { title: "wedding", image: "💒" },
                { title: "church", image: "⛪" },
                { title: "department_store", image: "🏬" },
                { title: "city_sunrise", image: "🌇" },
                { title: "city_sunset", image: "🌆" },
                { title: "japanese_castle", image: "🏯" },
                { title: "european_castle", image: "🏰" },
                { title: "tent", image: "⛺" },
                { title: "factory", image: "🏭" },
                { title: "tokyo_tower", image: "🗼" },
                { title: "japan", image: "🗾" },
                { title: "mount_fuji", image: "🗻" },
                { title: "sunrise_over_mountains", image: "🌄" },
                { title: "sunrise", image: "🌅" },
                { title: "night_with_stars", image: "🌃" },
                { title: "statue_of_liberty", image: "🗽" },
                { title: "bridge_at_night", image: "🌉" },
                { title: "carousel_horse", image: "🎠" },
                { title: "ferris_wheel", image: "🎡" },
                { title: "fountain", image: "⛲" },
                { title: "roller_coaster", image: "🎢" },
                { title: "ship", image: "🚢" },
                { title: "boat", image: "⛵" },
                { title: "speedboat", image: "🚤" },
                { title: "rocket", image: "🚀" },
                { title: "seat", image: "💺" },
                { title: "station", image: "🚉" },
                { title: "bullettrain_side", image: "🚄" },
                { title: "bullettrain_front", image: "🚅" },
                { title: "metro", image: "🚇" },
                { title: "railway_car", image: "🚃" },
                { title: "bus", image: "🚌" },
                { title: "blue_car", image: "🚙" },
                { title: "car", image: "🚗" },
                { title: "taxi", image: "🚕" },
                { title: "truck", image: "🚚" },
                { title: "rotating_light", image: "🚨" },
                { title: "police_car", image: "🚓" },
                { title: "fire_engine", image: "🚒" },
                { title: "ambulance", image: "🚑" },
                { title: "bike", image: "🚲" },
                { title: "barber", image: "💈" },
                { title: "busstop", image: "🚏" },
                { title: "ticket", image: "🎫" },
                { title: "traffic_light", image: "🚥" },
                { title: "construction", image: "🚧" },
                { title: "beginner", image: "🔰" },
                { title: "fuelpump", image: "⛽" },
                { title: "izakaya_lantern", image: "🏮" },
                { title: "slot_machine", image: "🎰" },
                { title: "moyai", image: "🗿" },
                { title: "circus_tent", image: "🎪" },
                { title: "performing_arts", image: "🎭" },
                { title: "round_pushpin", image: "📍" },
                { title: "triangular_flag_on_post", image: "🚩" }
            ]
        },
        {
            type: "symbols",
            section: "Symbols",
            enabled: false,
            items: [
                { title: "keycap_ten", image: "🔟" },
                { title: "1234", image: "🔢" },
                { title: "symbols", image: "🔣" },
                { title: "capital_abcd", image: "🔠" },
                { title: "abcd", image: "🔡" },
                { title: "abc", image: "🔤" },
                { title: "arrow_up_small", image: "🔼" },
                { title: "arrow_down_small", image: "🔽" },
                { title: "rewind", image: "⏪" },
                { title: "fast_forward", image: "⏩" },
                { title: "arrow_double_up", image: "⏫" },
                { title: "arrow_double_down", image: "⏬" },
                { title: "ok", image: "🆗" },
                { title: "new", image: "🆕" },
                { title: "up", image: "🆙" },
                { title: "cool", image: "🆒" },
                { title: "free", image: "🆓" },
                { title: "ng", image: "🆖" },
                { title: "signal_strength", image: "📶" },
                { title: "cinema", image: "🎦" },
                { title: "koko", image: "🈁" },
                { title: "u6307", image: "🈯" },
                { title: "u7a7a", image: "🈳" },
                { title: "u6e80", image: "🈵" },
                { title: "u5408", image: "🈴" },
                { title: "u7981", image: "🈲" },
                { title: "ideograph_advantage", image: "🉐" },
                { title: "u5272", image: "🈹" },
                { title: "u55b6", image: "🈺" },
                { title: "u6709", image: "🈶" },
                { title: "u7121", image: "🈚" },
                { title: "restroom", image: "🚻" },
                { title: "mens", image: "🚹" },
                { title: "womens", image: "🚺" },
                { title: "baby_symbol", image: "🚼" },
                { title: "wc", image: "🚾" },
                { title: "no_smoking", image: "🚭" },
                { title: "u7533", image: "🈸" },
                { title: "accept", image: "🉑" },
                { title: "cl", image: "🆑" },
                { title: "sos", image: "🆘" },
                { title: "id", image: "🆔" },
                { title: "no_entry_sign", image: "🚫" },
                { title: "underage", image: "🔞" },
                { title: "no_entry", image: "⛔" },
                { title: "negative_squared_cross_mark", image: "❎" },
                { title: "white_check_mark", image: "✅" },
                { title: "heart_decoration", image: "💟" },
                { title: "vs", image: "🆚" },
                { title: "vibration_mode", image: "📳" },
                { title: "mobile_phone_off", image: "📴" },
                { title: "ab", image: "🆎" },
                { title: "diamond_shape_with_a_dot_inside", image: "💠" },
                { title: "ophiuchus", image: "⛎" },
                { title: "six_pointed_star", image: "🔯" },
                { title: "atm", image: "🏧" },
                { title: "chart", image: "💹" },
                { title: "heavy_dollar_sign", image: "💲" },
                { title: "currency_exchange", image: "💱" },
                { title: "x", image: "❌" },
                { title: "exclamation", image: "❗" },
                { title: "question", image: "❓" },
                { title: "grey_exclamation", image: "❕" },
                { title: "grey_question", image: "❔" },
                { title: "o", image: "⭕" },
                { title: "top", image: "🔝" },
                { title: "end", image: "🔚" },
                { title: "back", image: "🔙" },
                { title: "on", image: "🔛" },
                { title: "soon", image: "🔜" },
                { title: "arrows_clockwise", image: "🔃" },
                { title: "clock12", image: "🕛" },
                { title: "clock1", image: "🕐" },
                { title: "clock2", image: "🕑" },
                { title: "clock3", image: "🕒" },
                { title: "clock4", image: "🕓" },
                { title: "clock5", image: "🕔" },
                { title: "clock6", image: "🕕" },
                { title: "clock7", image: "🕖" },
                { title: "clock8", image: "🕗" },
                { title: "clock9", image: "🕘" },
                { title: "clock10", image: "🕙" },
                { title: "clock11", image: "🕚" },
                { title: "heavy_plus_sign", image: "➕" },
                { title: "heavy_minus_sign", image: "➖" },
                { title: "heavy_division_sign", image: "➗" },
                { title: "white_flower", image: "💮" },
                { title: "100", image: "💯" },
                { title: "radio_button", image: "🔘" },
                { title: "link", image: "🔗" },
                { title: "curly_loop", image: "➰" },
                { title: "trident", image: "🔱" },
                { title: "small_red_triangle", image: "🔺" },
                { title: "black_square_button", image: "🔲" },
                { title: "white_square_button", image: "🔳" },
                { title: "red_circle", image: "🔴" },
                { title: "large_blue_circle", image: "🔵" },
                { title: "small_red_triangle_down", image: "🔻" },
                { title: "white_large_square", image: "⬜" },
                { title: "black_large_square", image: "⬛" },
                { title: "large_orange_diamond", image: "🔶" },
                { title: "large_blue_diamond", image: "🔷" },
                { title: "small_orange_diamond", image: "🔸" },
                { title: "small_blue_diamond", image: "🔹" },
            ]
        }
    ]
}