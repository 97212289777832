import React, { useState, useEffect, useContext } from "react";
import * as msal from "@azure/msal-browser";

export const MsalContext = React.createContext();
export const useMsal = () => useContext(MsalContext);
export const AuthProvider = ({ children, config, scopes }) => {
  const [isRoutingEnabled, setIsRoutingEnabled] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(true);
  const [user, setUser] = useState();
  const [publicClient, setPublicClient] = useState();
  const [loading, setLoading] = useState(false);
  const msalApp = new msal.PublicClientApplication(config);

  useEffect(() => {
    try {
      setLoading(true);
      //Setting this to prevent Access denied screen blink on load
      setIsAuthorized(true);
      setPublicClient(msalApp);
      ssoLogin(scopes, msalApp);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  }, [scopes]);

  async function ssoLogin(silentRequest, msalApp) {
    await msalApp
      .handleRedirectPromise()
      .then((response) => {
        setLoading(false);
        if (response) {
          setIsAuthorized(true);
          setIsRoutingEnabled(true);
          setUser(response.account);
        } else {
          setLoading(true);
          msalApp.loginRedirect(silentRequest);
        }
      })
      .catch((error) => {
        setLoading(false);
        setIsAuthorized(false);
        setIsRoutingEnabled(false);
      });
    setUser(msalApp.getAllAccounts()[0]);
  }

  // you can select which account application should sign out
  const logoutRequest = {
    account: user,
  };

  const logout = () => {
    publicClient.logout(logoutRequest.account.username);
  };

  return (
    <MsalContext.Provider
      value={{
        isAuthorized,
        isRoutingEnabled,
        user,
        loading,
        logout,
        acquireTokenSilent: (...p) => publicClient.acquireTokenSilent(...p),
        acquireTokenRedirect: (...p) => publicClient.acquireTokenRedirect(...p),
      }}>
      {children}
    </MsalContext.Provider>
  );
};
