import React from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Switch from "@mui/material/Switch";
import { useSelector, useDispatch } from "react-redux";
import { patchOfficesPreferences } from "../../redux/actions/officeSettingsActions";

export default function OfficeAdministratorsSettings() {
  const dispatch = useDispatch();

  const officeAssociateId = useSelector(
    (state) => state.userInfoReducer.officeAssociateId
  );

  let officeMembers = useSelector(
    (state) => state.userInfoReducer.currentOfficeMembers || []
  );

  officeMembers = officeMembers.filter(
    (officeMember) =>
      !officeMember.system && officeMember.associateId !== officeAssociateId
  );

  const officeAdministrators = useSelector(
    (state) => state.officeSettingsReducer.officeAdministrators || []
  );

  const requireOfficeAdministratorToAssign = useSelector(
    (state) =>
      state.officeSettingsReducer.requireOfficeAdministratorToAssign || false
  );

  const loggedInAssociateId = useSelector(
    (state) => state.userInfoReducer.loggedInAssociateId
  );

  const handleChange = (event) => {
    let checked = event.target.checked;
    let associateId = event.target.name;

    if (checked) {
      officeAdministrators.push(associateId);
    } else {
      let indexToRemove = officeAdministrators.findIndex(
        (tempAssociateId) => tempAssociateId === associateId
      );
      officeAdministrators.splice(indexToRemove, 1);
    }

    dispatch(
      patchOfficesPreferences(officeAssociateId, { officeAdministrators })
    );
  };

  const setRequireOfficeAdministratorToAssign = () => {
    dispatch(
      patchOfficesPreferences(officeAssociateId, {
        requireOfficeAdministratorToAssign: !requireOfficeAdministratorToAssign,
      })
    );
  };

  return (
    <>
      <p className="panel-details">
        Only agents and office administrators can edit these values.
      </p>
      <p className="panel-details">
        Agents will always have full permissions for their office.
      </p>
      <br />
      <p className="-oneX-heading--h5">Select Administrators</p>
      <br />
      <FormGroup>
        {officeMembers.length &&
          officeMembers.map((officeMember) => (
            <FormControlLabel
              key={officeMember.associateId}
              disabled={
                loggedInAssociateId === officeAssociateId ||
                officeAdministrators.some(
                  (officeAdministrator) =>
                    officeAdministrator === loggedInAssociateId
                )
                  ? false
                  : true
              }
              control={
                <Checkbox
                  key={officeMember.associateId}
                  onChange={handleChange}
                  name={officeMember.associateId}
                  checked={officeAdministrators.some(
                    (officeAdministrator) =>
                      officeAdministrator === officeMember.associateId
                  )}
                />
              }
              label={officeMember.associateName}
            />
          ))}
      </FormGroup>

      <br />
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={requireOfficeAdministratorToAssign}
              onChange={setRequireOfficeAdministratorToAssign}
              disabled={
                loggedInAssociateId === officeAssociateId ||
                officeAdministrators.some(
                  (officeAdministrator) =>
                    officeAdministrator === loggedInAssociateId
                )
                  ? false
                  : true
              }
            />
          }
          label="Only allow administrators to perform assignments"
        />
      </FormGroup>
    </>
  );
}
