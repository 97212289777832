/*

https://developer.mozilla.org/en-US/docs/Web/API/notification

title - takes a string and is the title of the Notification

options - takes an object and that object with all of the data for the notification

activeTime - takes in an integer in miliseconds of how long the notification will display 

*/

function notifyUser(options, title, activeTime) {
  const _holdSeconds = activeTime;
  const defaultTimeStamp = Date.now() + _holdSeconds;
  const sfConnectOrginContextRoot =
    window.location.origin + window.location.pathname;

  let defaultOptions = {
    body: `There ${
      options.unreadCount === 0 ? "is a" : "are " + options.unreadCount
    } new message${
      options.unreadCount === 0 ? "" : "s"
    } received in SF Connect`,
    icon: `$sfConnectOrginContextRoot}apple-touch-icon.png`,
    image: `${sfConnectOrginContextRoot}images/sfc_banner.png`,
    // sound: `${sfConnectOrginContextRoot}/images/seasons.mp3`,
    tag: "SFConnectNotifications",
    data: { interactionId: "000" },
    lang: "en-US",
    timestamp: defaultTimeStamp,
    vibrate: [100, 200, 100],
  };

  //overwriting the notification default params with the ones passed in
  //this is probably very stupid but hey
  let mergedOptions = {
    ...defaultOptions,
    data: {
      ...options,
    },
  };

  //create a new instance of notification
  const notification = new Notification(title, mergedOptions);
  setTimeout(
    () =>
      // Hide the notification after X amount of seconds
      notification.close(),
    _holdSeconds
  );

  notification.addEventListener("click", () => {
    window.open(window.location.href, "_blank");
  });

  return (notification.onclick = onclick || notification.close);
}

// show an error message
const showError = (options) => {
  const error = document.createElement("div");
  error.style.display = "block";
  error.id = "noNotification";
  error.textContent = `Message notifications are blocked, there is a message`;
  const root = document.querySelector("#root");
  root.parentNode.prepend(error);

  setTimeout(() => {
    var elem = document.getElementById("noNotification");
    elem.parentNode.removeChild(elem);
  }, 5000);
};

export const SFNotifications = (
  options = {},
  title = "SF Connect Web Application",
  activeTime = 12000
) => {
  if ("Notification" in window) {
    Notification.permission === "granted"
      ? notifyUser(options, title, activeTime)
      : showError(options);
    if (Notification.permission !== "granted") {
      Notification.requestPermission()
        .then((result) => {
          Notification.permission === "granted"
            ? notifyUser(options, title, activeTime)
            : showError(options);
        })
        .catch((err) => {
          //A banner can be thrown here if needed to show that the user does not have notifications turned on
          showError(options);
        });
    }
  }
};
