import React, { Component } from "react";

export default class CustomerSearchByPhone extends Component {

  componentDidMount() {
    if (window.oneX) {
      window.oneX.addElement(this.refs.phoneCriteria);
      window.oneX.AutoFormat.init();
    }
  }

  render() {
    const { inputFieldPhoneNumber, onChangeValue } = this.props;
    return (
      <div className="-oneX-container">
        <div ref={'phoneCriteria'} className="-oneX-row">
          <div className="-oneX-col-12 -oneX-col-lg-5">
            <div className="-oneX-widget"> {/* -oneX-widget--err */}
              <label htmlFor="customerPhone" className="-oneX-search-textfield -oneX-textfield__floatingLabel">Phone*</label>
              <input id="customerPhone" name="phoneNumber" type="tel" className="-oneX-textfield--floating-input" data-formatter="phone" aria-required="true" aria-label="Search for customers" placeholder="(___)-___-____" defaultValue={inputFieldPhoneNumber} onChange={onChangeValue} maxLength="14"/>
              {/* <div id="name-err" className="-oneX-widget__err-text" aria-live="assertive"> 10 digit phone number required</div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}