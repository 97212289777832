import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Notification from "../components/Notification";

//import ".././scss/unauthorized.scss";

const TITLE = "SF Connect Unauthorized";
export default class UserNotAuthorized extends Component {
  render() {
    return (
      <>
        <header></header>
        <Helmet>
          <title>{TITLE}</title>
        </Helmet>

        <br />
        <div className="-oneX-container">
          <div className="-oneX-row user-not-authorized-row">
            <div className="-onex-col-2 -onex-col-lg-4 user-not-authorized-page">
              <br />
              <Notification type="error" className="notification">
                User Not Authorized
              </Notification>
              <br />
              <h5>
                If you have reached this page, please review the following:
              </h5>
              <br />
              <h6>
                <p>
                  Sorry, you are not authorized to access SF Connect. Access is
                  limited to Agents and Agent Team Members.
                </p>
              </h6>
            </div>
          </div>
        </div>
      </>
    );
  }
}
