import React from "react";
import { bindActionCreators } from "redux";
import { patchOfficesPreferences } from "../../redux/actions/officeSettingsActions";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { useSelector, useDispatch } from "react-redux";

export default function OutOfOfficeCheckbox() {
  const dispatch = useDispatch();

  const outOfOfficeEnabled = useSelector(
    (state) => state.officeSettingsReducer.oooEnabled
  );

  const officeId = useSelector(
    (state) => state.userInfoReducer.officeAssociateId
  );

  const setOOOflag = () => {
    dispatch(
      patchOfficesPreferences(officeId, { oooEnabled: !outOfOfficeEnabled })
    );
  };

  return (
    <>
      <div>
        <p className="panel-details">
          SF Connect will respond to customer texts received by the office with
          a message when the Out of Office Message Service is activated.
        </p>
      </div>
      <br />

      <FormGroup>
        <FormControlLabel
          control={
            <Switch checked={outOfOfficeEnabled} onChange={setOOOflag} />
          }
          label="Out of Office Message Service"
        />
      </FormGroup>
    </>
  );
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators({ patchOfficesPreferences }, dispatch) };
}
