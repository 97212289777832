import {
  FETCH_INTERACTIONS,
  FETCH_MESSAGES,
  UPDATE_INTERACTIONS,
  CLEAR_CURRENT_INTERACTION,
  CANCEL_CURRENT_DRAFT,
  UPDATE_CHAT,
  UPDATE_CHAT_LOG,
  SET_CURRENT_INTERACTION,
  GET_UUID,
  CREATE_NEW_INTERACTION,
  MARK_SPI_SUCCESS,
  GET_MMS_URL_SUCCESS,
  PATCH_UPDATE_INTERACTION_RESOLVE_MULTIMATCH,
  PATCH_UPDATE_INTERACTION,
  PATCH_UPDATE_STARRED_INTERACTION,
  UPDATE_TEXTAREA_ATTRIBUTES,
  GET_INTERACTIONS,
  PUT_READ_INTERACTION,
  GENERIC_ERROR,
  SET_MMS_INDEX,
  UPDATE_MESSAGE_DELIVERY_STATUS,
  MESSAGE_DELIVERY_STATUS,
  SEND_WEB_MESSAGE_SUCCESS,
  SHOULD_TEXT_SEND,
  UPDATE_DO_NOT_TEXT_STATUS_BAR,
  UPDATE_CLIENT_TYPE_FOR_MULTIMATCH,
  GO_BACK_TO_DRAFT_MESSAGE,
  CLEAR_GENERIC_ERROR,
  ADD_ECRM_MOBILE_NUMBER,
  ARCHIVE_INTERACTION,
  CHANGE_FILTER_OPTIONS,
  STORE_CUSTOMER_INFO_FOR_PROSPECT_RESOLUTION,
  PATCH_UPDATE_INTERACTION_PROSPECT_TO_CUSTOMER,
  ATTACH_TO_MESSAGE,
  UPDATE_MESSAGE_BODY,
  PATCH_INTERACTION_HIDDEN_SETTING,
  PATCH_INTERACTION_ASSIGN_SETTING,
  SET_CURRENT_FILTER,
  CLEAR_FILTERS,
  REMOVE_INTERACTION,
  RESET_INTERACTIONS,
  ADD_INTERACTION,
  HIDE_INTERACTION,
  UNHIDE_INTERACTION,
  SET_LOGGED_IN_USER,
  SET_ASSIGNMENTS_FILTER,
  UPDATE_UNASSIGNED_FILTER,
  CLEAR_INTERACTIONS,
  ASSIGN_INTERACTION,
  UNASSIGN_INTERACTION,
} from "../actions/types";
import {
  translateInteractionsForReducer,
  translateWSInteractionForReducer,
  translateWSMessage,
  translateMessagesForReducer,
  buildMessageObject,
  convertEpochToDate,
  translateInteractionForNewInteraction,
  createWSEmptyMessage,
  translateClientInfosForReducer,
  getClientInfoDetails,
  addNewClientDataToClientInfos,
  lastOutgoingSender,
  virtualAssistantFollowup,
  getAssociateName,
  translateInteractionFromModernizedStructure,
} from "../../utils/translators.js";
import { validateArray } from "../../utils/arrayValidator";
import { toast } from "react-toastify";

const createInitialState = () => {
  return {
    //clean version of the list
    interactions: [],
    filteredInteractions: [],
    //this will be set to get the index of mms when marking SPI
    attachmentIndex: null,
    messageIndex: null,
    messageId: null,
    attachmentMessage: {},
    defaultScrollLen: 100,
    currentSort: "Newest",
    chatLog: [],
    currentInteraction: { interactionId: null },
    resultsStatus: "Loading ...",
    createdInteraction: [],
    newDraftMessage: false,
    textMessageBox: {},
    clientInfos: [],
    shouldTextSend: false,
    draftCustomerDetails: [],
    initialQueryExecuted: false,
    filterOptions: {
      MyMessages: false,
      MessageStatus: "",
      SearchByNameNumber: {
        selectedSearchCriteria: "",
        selectedSearchValue: "",
      },
    },
    loggedInAssociateId: "",
    isNewlyRead: false,
    prospectToCustomerDetails: [],
    nextToken: "",
    isNewFilterOn: false,
    isReadFilterOn: false,
    isRepliedToFilterOn: false,
    isFollowUpFilterOn: false,
    isHiddenFilterOn: false,
    isTextFilterOn: false,
    isMyMessagesFilterOn: false,
    currentInteractionMessagesFetched: true,
    selectedAssignmentsFilter: [],
    returnUnassigned: true,
    selectAllAssignments: false,
  };
};

export default function (state = createInitialState(), action) {
  switch (action.type) {
    case FETCH_INTERACTIONS: {
      let interactions = [...state.interactions];

      if (!action.payload) {
        action.payload = [];
      }
      let newInteractions = translateInteractionsForReducer(
        action.payload,
        action.currentOfficeMembers
      );

      for (let newInteraction of newInteractions) {
        if (
          !interactions.some(
            (tempInteraction) =>
              tempInteraction.interactionId === newInteraction.interactionId
          )
        ) {
          interactions.push(newInteraction);
        }
      }

      let clientInfos = action.payload.map((interaction) =>
        translateClientInfosForReducer(interaction.client.details)
      );

      clientInfos = clientInfos.concat(state.clientInfos);
      let officeMessagesUnreadCount = interactions.filter(
        (interaction) =>
          !interaction.isMessageViewed && !interaction.hidden.value
      ).length;

      return {
        ...state,
        interactions,
        clientInfos,
        resultsStatus: "No Messages",
        newDraftMessage: false,
        draftCustomerDetails: [],
        officeMessagesUnreadCount,
        nextToken: action.nextToken,
        initialQueryExecuted: true,
      };
    }

    case FETCH_MESSAGES: {
      const interactions = [...state.interactions];
      const messageBody = "";

      let currentInteraction = interactions.filter(
        (interaction) => interaction.interactionId === action.interactionId
      )[0];

      let webSocket = translateWSMessage(currentInteraction);

      let chatLog = translateMessagesForReducer(
        action.payload,
        action.currentOfficeMembers,
        currentInteraction
      );
      return {
        ...state,
        chatLog: chatLog,
        currentInteraction: {
          ...currentInteraction,
          hasUUID: null,
          messageBody,
          webSocketJSON: {
            ...webSocket,
            message: {
              body: messageBody,
            },
          },
        },
        currentInteractionMessagesFetched: true,
      };
    }

    // UPDATE_INTERACTIONS is called on a WebSocket push of type "message"
    case UPDATE_INTERACTIONS: {
      let response = action.payload.data;
      let currentInteraction = state.currentInteraction;
      let interactions = [...state.interactions];
      let interaction = null;
      let updatedClientInfos = state.clientInfos;

      let interactionToUpdate = interactions.filter(
        (interaction) =>
          interaction.interactionId === response.interactionID ||
          interaction.clientId === response.ownerClient.clientID
      );

      interactions = interactions.filter(
        (interaction) =>
          interaction.interactionId !== response.interactionID &&
          interaction.clientId !== response.ownerClient.clientID
      );

      //if it exists update it
      if (interactionToUpdate.length > 0) {
        interaction = interactionToUpdate[0];
        if (response.ownerClient.clientID === currentInteraction.clientId) {
          currentInteraction.interactionId = response.interactionID;
        }
        interaction.interactionId = response.interactionID;
        interaction.messageDate = convertEpochToDate(response.createdOn);
        interaction.messagePreviewText = response.message.body;
        interaction.associateId = response.ownerAgent.associateID;
        interaction.unmaskedPhoneNumber = response.source.data.phone
          ? response.source.data.phone
          : response.destination[0].data.phone;
        interaction.clientId = response.ownerClient.clientID;
        interaction.clientType = response.ownerClient.type;
        interaction.virtualAssistantActive = response.virtualAssistantActive;
        interaction.lastReplierType = response.sender.type;
        if (response.starred) {
          interaction.virtualAssistantFollowup = virtualAssistantFollowup(
            response.starred
          );
          interaction.starred = response.starred;
        }
        if (response.starred) {
          interaction.virtualAssistantFollowup = virtualAssistantFollowup(
            response.starred
          );
        }
        interaction.starred = response.starred;
        if (response.sender.type !== "associate") {
          interaction.isMessageViewed = false;
          interaction.unreadClientMessageCount = 1;
        }

        if (validateArray(action.payload.newClientData)) {
          let newClientData = action.payload.newClientData;
          updatedClientInfos.push(addNewClientDataToClientInfos(newClientData));
        } else if (
          !updatedClientInfos.some(
            (client) => client.clientID === interaction.clientId
          )
        ) {
          let newClient = {
            clientID: interaction.clientId,
            firstName: interaction.customerName.substring(
              0,
              interaction.customerName.indexOf(" ")
            ),
            lastName: interaction.customerName.substring(
              interaction.customerName.lastIndexOf(" ") + 1
            ),
            doNotText: interaction.doNotText,
            customerOfAnotherAgent: interaction.customerOfAnotherAgent,
            firstTextableMobilePhone: interaction.phoneNumber,
            casl: interaction.casl,
            protectedPersonIndicator: interaction.protectedPersonIndicator,
          };
          updatedClientInfos.push(addNewClientDataToClientInfos(newClient));
        }
        interactions.unshift(interaction);
      }

      //if it doesn't exist, update the clientInfos and create the new interaction it
      else {
        //not performing a validateArray because this is a map
        if (action.payload.newClientData) {
          let newClientData = action.payload.newClientData;
          updatedClientInfos.push(addNewClientDataToClientInfos(newClientData));

          let responseWithClientInfo = {
            data: response,
            newClientInfo: newClientData,
          };
          interaction = translateWSInteractionForReducer(
            responseWithClientInfo
          );
        } else if (validateArray(action.payload.multiMatchClientInfo)) {
          let multiMatchClientInfo = action.payload.multiMatchClientInfo;

          multiMatchClientInfo.map((clientInfo) => {
            updatedClientInfos.push(addNewClientDataToClientInfos(clientInfo));
            return true;
          });
          let responseWithMMInfo = {
            data: response,
            multiMatchInfoList: response.ownerClient.multiMatchClientIDs,
            clientInfos: state.clientInfos,
          };
          interaction = translateWSInteractionForReducer(responseWithMMInfo);
        } else {
          interaction = translateWSInteractionForReducer(action.payload);
        }

        if (interactionMatchesCurrentFilters(interaction, state)) {
          interactions.unshift(interaction);
        }
      }

      let officeMessagesUnreadCount = interactions.filter(
        (interaction) =>
          !interaction.isMessageViewed && !interaction.hidden.value
      ).length;

      // setting temp send message to null to prevent double message from displaying on push
      state.currentInteraction.tempSendMessage = null;

      return {
        ...state,
        interactions,
        clientInfos: updatedClientInfos,
        officeMessagesUnreadCount,
        currentInteraction,
      };
    }

    case SET_CURRENT_INTERACTION: {
      let currentInteraction = state.interactions[action.payload];
      let messageBody = "";
      let webSocket = translateWSMessage(currentInteraction);
      if (
        state.interactions[action.payload] ===
        state.currentInteraction.interactionId
      ) {
        currentInteraction = state.currentInteraction;
        messageBody = state.currentInteraction.messageBody;
      }

      return {
        ...state,
        currentInteraction: {
          ...currentInteraction,
          messageBody: messageBody,
          webSocketJSON: webSocket,
        },
      };
    }

    case CLEAR_CURRENT_INTERACTION: {
      return {
        ...state,
        currentInteraction: { interactionId: null },
      };
    }

    case CANCEL_CURRENT_DRAFT: {
      let interactions = state.interactions.filter(
        (interaction) => interaction.interactionId !== action.payload
      );
      return {
        ...state,
        interactions,
        currentInteraction:
          state.currentInteraction.interactionId === "tempInteractionId"
            ? { interactionId: null }
            : state.currentInteraction,
      };
    }

    case CLEAR_FILTERS: {
      return {
        ...state,
        isHiddenFilterOn: false,
        isTextFilterOn: false,
        isMyMessagesFilterOn: false,
      };
    }

    case UPDATE_CHAT: {
      let currentInteraction = state.currentInteraction;
      let webSocketJSON = currentInteraction.webSocketJSON;

      let messageValue = action.payload.replace(/[\u0000-\u001F]/g, " "); // added to strip non printable characters and prevent backend errors

      return {
        ...state,
        currentInteraction: {
          ...currentInteraction,
          messageBody: action.payload,
          webSocketJSON: {
            ...webSocketJSON,
            message: {
              body: messageValue,
            },
          },
        },
      };
    }

    case UPDATE_CHAT_LOG: {
      let chatLog = state.chatLog;
      let message = buildMessageObject(
        action.payload.data,
        action.payload.currentOfficeMembers,
        action.payload.currentInteraction
      );
      let matchIndex = chatLog.findIndex(
        (chatMessage) => chatMessage.messageId === message.messageId
      );

      if (matchIndex !== -1) {
        chatLog[matchIndex] = message;
      } else {
        chatLog.push(message);
      }

      return {
        ...state,
        chatLog: chatLog,
      };
    }

    case UPDATE_TEXTAREA_ATTRIBUTES: {
      let textMessageBox = state.textMessageBox;
      return {
        ...state,
        textMessageBox: {
          ...textMessageBox,
          cursorPos: {
            start: action.payload.start,
            end: action.payload.end,
          },
        },
      };
    }

    case GET_UUID: {
      //insert UUID into object
      let currentInteraction = state.currentInteraction;
      let webSocketJSON = currentInteraction.webSocketJSON;
      return {
        ...state,
        currentInteraction: {
          ...currentInteraction,
          hasUUID: !action.isParameterMissing,
          webSocketJSON: {
            ...webSocketJSON,
            messageID: action.payload,
          },
          tempSendMessage: webSocketJSON.message.body,
        },
      };
    }

    case SHOULD_TEXT_SEND: {
      return {
        ...state,
        shouldTextSend: action.payload,
      };
    }

    case UPDATE_MESSAGE_BODY: {
      const currentInteraction = state.currentInteraction;
      const webSocketJSON = currentInteraction.webSocketJSON;
      const message = webSocketJSON.message;
      const numAttachments = message.attachments.length;
      const newBody = `${numAttachments} attachment${
        numAttachments > 1 ? "s" : ""
      }`;
      return {
        ...state,
        currentInteraction: {
          ...currentInteraction,
          webSocketJSON: {
            ...webSocketJSON,
            message: {
              ...message,
              body: newBody,
            },
          },
        },
      };
    }

    case ATTACH_TO_MESSAGE: {
      const currentInteraction = state.currentInteraction;
      const webSocketJSON = currentInteraction.webSocketJSON;
      const message = webSocketJSON.message;
      const attachments = action.payload;
      return {
        ...state,
        currentInteraction: {
          ...currentInteraction,
          webSocketJSON: {
            ...webSocketJSON,
            message: {
              ...message,
              attachments: attachments,
            },
          },
        },
      };
    }

    case MARK_SPI_SUCCESS: {
      let chatLog = state.chatLog;
      state.chatLog[action.index].attachments[
        action.attachmentIndex
      ].spiIndicator = true;
      state.chatLog[action.index].attachments[
        action.attachmentIndex
      ].attachmentUrl = "";

      return {
        ...state,
        chatLog: chatLog,
      };
    }

    case SET_MMS_INDEX: {
      let attachmentMessage = action.payload.attachmentMessage;
      let attachments = attachmentMessage.attachments.map((attachment) => ({
        Bucket: attachment.Bucket,
        Key: attachment.Key,
      }));
      let messageToSend = {
        interactionID: attachmentMessage.interactionId,
        createdOn: attachmentMessage.createdOn,
        key: attachmentMessage.attachments[action.payload.attachmentIndex].Key,
        spiIndicator: true,
        message: {
          body: attachmentMessage.message,
          attachments: attachments,
        },
      };
      return {
        ...state,
        attachmentIndex: action.payload.attachmentIndex,
        messageId: attachmentMessage.messageId,
        messageIndex: action.payload.messageIndex,
        attachmentMessage: messageToSend,
        attachmentUrl: action.payload.attachmentUrl,
      };
    }

    case SEND_WEB_MESSAGE_SUCCESS: {
      let currentInteraction = state.currentInteraction;
      return {
        ...state,
        shouldTextSend: false,
        currentInteraction: {
          ...currentInteraction,
          hasUUID: false,
          messageBody: "",
          messageDeliveryStatus: "sent",
          webSocketJSON: {
            ...state.currentInteraction.webSocketJSON,
            messageID: "",
            message: {
              body: "",
            },
          },
        },
      };
    }

    case GET_MMS_URL_SUCCESS: {
      let chatLog = state.chatLog;
      state.chatLog[action.index].attachments[
        action.attachmentIndex
      ].attachmentUrl = action.payload;
      return {
        ...state,
        chatLog: chatLog,
      };
    }

    case CREATE_NEW_INTERACTION: {
      let payload = action.payload;
      let newInteraction = translateInteractionForNewInteraction(payload);
      let webSocket = createWSEmptyMessage(newInteraction);
      let interactions = state.interactions.filter(
        (interaction) => interaction.interactionId !== "tempInteractionId"
      );
      interactions.unshift(newInteraction);

      return {
        ...state,
        interactions,
        draftCustomerDetails: payload.customerDetails,
        chatLog: [],
        createdInteraction: {
          ...newInteraction,
          messageBody: "",
          webSocketJSON: webSocket,
        },
        newDraftMessage: true,
        currentInteraction: {
          ...newInteraction,
          messageBody: "",
          webSocketJSON: webSocket,
        },
      };
    }

    case PATCH_UPDATE_INTERACTION_RESOLVE_MULTIMATCH: {
      //if still on current interaction, then update messageSenderName
      let selectedClientId = action.payload.ownerClient.clientID;
      let chatLog = state.chatLog;
      let clientInfos = state.clientInfos;
      let currentInteraction = state.currentInteraction;
      if (action.payload.interactionID === chatLog[0].interactionId) {
        //filter through clientInfos to get the clientName
        let filteredClientInfo = clientInfos.find((clientInfo) => {
          if (Array.isArray(clientInfo)) {
            return clientInfo.some(
              (client) => client.clientID === selectedClientId
            );
          } else {
            return clientInfo.clientID === selectedClientId;
          }
        });

        if (Array.isArray(filteredClientInfo)) {
          filteredClientInfo = filteredClientInfo.filter(
            (client) => client.clientID === selectedClientId
          );
          filteredClientInfo = filteredClientInfo[0];
        }

        for (let chatItem of chatLog) {
          chatItem.messageSenderName =
            filteredClientInfo.firstName + " " + filteredClientInfo.lastName;
        }

        //update the websocketJSON with the correct ownerClient details
        currentInteraction.webSocketJSON.ownerClient.clientID =
          selectedClientId;
        currentInteraction.webSocketJSON.ownerClient.type = "client";
      }
      return {
        ...state,
        chatLog,
        currentInteraction,
      };
    }

    case PATCH_UPDATE_INTERACTION: {
      //if still on current interaction, then update messageSenderName
      let selectedClientId = action.payload.ownerClient.clientID;
      let newCustomerDetails = action.payload.newCustomerDetails;
      let firstName = newCustomerDetails.firstName;
      let lastName = newCustomerDetails.lastName;
      if (action.payload.interactionID === state.chatLog[0].interactionId) {
        //update previous interactions to match with the current customer name/ID
        for (let i = 0; i < state.chatLog.length; i++) {
          if (state.chatLog[i].messageSender === "customer") {
            state.chatLog[i].messageSenderName = firstName + " " + lastName;
            state.chatLog[i].messageSenderId = selectedClientId;
          }
        }
        //update the websocketJSON with the correct ownerClient details
        if (!state.currentInteraction.webSocketJSON) {
          state.currentInteraction.webSocketJSON = {};
        }
        state.currentInteraction.webSocketJSON.ownerClient = {
          clientID: selectedClientId,
          type: "client",
        };
      }
      //update current customer state on UI
      if (newCustomerDetails) {
        const newInitials =
          firstName && lastName ? `${firstName[0]}${lastName[0]}` : "?";
        const newClientInfo = {
          casl: newCustomerDetails.casl,
          clientID: newCustomerDetails.clientID,
          customerInitials: newInitials,
          customerOfAnotherAgent: newCustomerDetails.customerOfAnotherAgent,
          doNotText: newCustomerDetails.doNotText,
          firstName: firstName,
          lastName: lastName,
          middleName: newCustomerDetails.middleName,
          phoneNumber: newCustomerDetails.displayedPhone,
        };
        state.clientInfos[0] = newClientInfo;
      }

      return {
        ...state,
      };
    }

    // GET_INTERACTIONS is called on a WebSocket push of type "interaction"
    case GET_INTERACTIONS: {
      let currentOfficeMembers = action.payload.currentOfficeMembers;
      let payload = action.payload.response.data;
      let interactionToUpdate = state.interactions.filter(
        (interaction) => interaction.interactionId === payload.interactionID
      );
      let indexOfInteractionToBeUpdated = state.interactions.findIndex(
        (interaction) => interaction.interactionId === payload.interactionID
      );
      let newInteraction = state.interactions.filter(
        (interaction) => interaction.interactionId !== payload.interactionID
      );

      let interaction = null;

      let clientInfoFound = getClientInfoDetails(payload, state.clientInfos);

      //if it exists update it
      if (interactionToUpdate.length > 0) {
        interaction = interactionToUpdate[0];

        //for read/unread update - Note: when the WS push of type interaction comes as Multimatch, it has an empty reader value
        interaction.unreadClientMessageCount =
          payload.readers && payload.readers.length > 0
            ? payload.readers[0].unreadClientMessageCount
            : 0;
        interaction.isMessageViewed =
          payload.readers &&
          payload.readers.length > 0 &&
          payload.readers[0].unreadClientMessageCount === 0;

        //for MultiMatch update
        interaction.clientId = payload.ownerClient.clientID;
        interaction.clientType = payload.ownerClient.type;
        interaction.messagePreviewText = payload.messages
          ? payload.messages[0].message.body
          : payload.message.body;

        if (clientInfoFound.customerName !== "Unknown") {
          interaction.customerName = clientInfoFound.customerName;
          interaction.customerInitials = clientInfoFound.customerInitials;
          interaction.customerOfAnotherAgent =
            clientInfoFound.customerOfAnotherAgent;
          interaction.doNotText = clientInfoFound.doNotText;
          interaction.casl = clientInfoFound.casl;
        }

        //for lastOutgoingSender name update in ChatCard
        interaction.assignedAssociates =
          payload.assignedAssociates && payload.assignedAssociates.length > 0
            ? payload.assignedAssociates[0].associateID
            : "";
        interaction.lastOutgoingSender = lastOutgoingSender(
          payload.assignedAssociates,
          currentOfficeMembers
        );
        interaction.virtualAssistantFollowup = virtualAssistantFollowup(
          payload.starred
        );
        interaction.virtualAssistantActive = payload.virtualAssistantActive;
        interaction.ownerAgentName = getAssociateName(
          payload.ownerAgent.associateID,
          currentOfficeMembers
        );

        // for star/unstar interaction
        interaction.starred = payload.starred;

        //add interaction to where it was in list
        newInteraction.splice(indexOfInteractionToBeUpdated, 0, interaction);
      }

      let webSocket = state.currentInteraction.webSocketJSON;
      let updateCurrentInteraction = state.currentInteraction;
      if (state.currentInteraction.interactionId === payload.interactionID) {
        updateCurrentInteraction = interaction;
      }
      if (payload.archived) {
        newInteraction.splice(indexOfInteractionToBeUpdated, 1);

        if (state.currentInteraction.interactionId === payload.interactionID) {
          updateCurrentInteraction = { interactionId: null };
        }
      }

      let officeMessagesUnreadCount = state.interactions.filter(
        (interaction) =>
          !interaction.isMessageViewed && !interaction.hidden.value
      ).length;

      return {
        ...state,
        interactions: newInteraction,
        currentInteraction: {
          ...updateCurrentInteraction,
          messageBody:
            webSocket && webSocket.message
              ? webSocket.message.body === ""
                ? ""
                : webSocket.message.body
              : "", //putting this check to disable send text button but still allow Agent to continue the next text while the previous one is sending
          webSocketJSON: webSocket,
        },
        officeMessagesUnreadCount,
      };
    }

    case PUT_READ_INTERACTION: {
      let officeMessagesUnreadCount = state.interactions.filter(
        (interaction) =>
          !interaction.isMessageViewed && !interaction.hidden.value
      ).length;

      return {
        ...state,
        officeMessagesUnreadCount,
      };
    }

    case UPDATE_MESSAGE_DELIVERY_STATUS: {
      let payload = action.payload.data;
      let interactionToUpdate = state.interactions.filter(
        (interaction) => interaction.interactionId === payload.interactionID
      );
      let indexOfInteractionToBeUpdated = state.interactions.findIndex(
        (interaction) => interaction.interactionId === payload.interactionID
      );
      let newInteraction = state.interactions.filter(
        (interaction) => interaction.interactionId !== payload.interactionID
      );

      let interaction = null;

      //if it exists update it
      if (interactionToUpdate.length > 0) {
        interaction = interactionToUpdate[0];

        interaction.messageDeliveryStatus = "";
        interaction.messageDeliveryErrorType = "";

        //add interaction to where it was in list
        newInteraction.splice(indexOfInteractionToBeUpdated, 0, interaction);
        //if you are on the current interaction, the values need to be updated
        if (
          state.currentInteraction.interactionId === interaction.interactionId
        ) {
          state.currentInteraction.messageDeliveryStatus = "";
          state.currentInteraction.messageDeliveryErrorType = "";
        }
      }

      return {
        ...state,
        interactions: newInteraction,
      };
    }
    case GENERIC_ERROR: {
      return {
        ...state,
        error: true,
        actionInError: action.actionInError,
        errorDetails: JSON.stringify(action.error),
      };
    }
    case CLEAR_GENERIC_ERROR: {
      return {
        ...state,
        error: false,
        actionInError: "",
        errorDetails: "",
      };
    }

    case MESSAGE_DELIVERY_STATUS: {
      let payload = action.payload.data;
      let interactionToUpdate = state.interactions.filter(
        (interaction) => interaction.interactionId === payload.interactionID
      );
      let indexOfInteractionToBeUpdated = state.interactions.findIndex(
        (interaction) => interaction.interactionId === payload.interactionID
      );
      let newInteraction = state.interactions.filter(
        (interaction) => interaction.interactionId !== payload.interactionID
      );

      let interaction = null;

      //if it exists update it
      if (interactionToUpdate.length > 0) {
        interaction = interactionToUpdate[0];

        interaction.messageDeliveryStatus =
          payload.reports[0].status && payload.reports[0].status === 5
            ? "failed"
            : payload.reports[0].status === 3
            ? "delayed"
            : "sent";
        interaction.messageDeliveryErrorType =
          interaction.messageDeliveryStatus === "failed"
            ? //? ": " + payload.reports[0].meta.error.type
              payload.reports[0].meta.error.type === "RUNTIME_ERROR" ||
              payload.reports[0].meta.error.type === "AERIALINK_ERROR"
              ? ": System is down. Retry twice, then submit service ticket."
              : ": Bad Data. Submit service ticket."
            : "";
        interaction.messageDeliveryErrorDetails =
          interaction.messageDeliveryStatus === "failed"
            ? ": " + payload.reports[0].meta.error.msg
            : //  ? (payload.reports[0].meta.error.msg.includes("destinationPhone is not valid") ? ": Customer Phone is Empty or Invalid" : ": "+payload.reports[0].meta.error.msg)
              "";
        interaction.vehicleID = payload.vehicleID;
        //add interaction to where it was in list
        newInteraction.splice(indexOfInteractionToBeUpdated, 0, interaction);

        //if you are on the current interaction, the values need to be updated
        if (
          state.currentInteraction.interactionId === interaction.interactionId
        ) {
          state.currentInteraction.messageDeliveryStatus =
            payload.reports[0].status && payload.reports[0].status === 5
              ? "failed"
              : payload.reports[0].status === 3
              ? "delayed"
              : "sent";
          state.currentInteraction.messageDeliveryErrorType =
            interaction.messageDeliveryStatus === "failed"
              ? // ? ": " + payload.reports[0].meta.error.type
                payload.reports[0].meta.error.type === "RUNTIME_ERROR" ||
                payload.reports[0].meta.error.type === "AERIALINK_ERROR"
                ? ": System is down. Retry twice, then submit service ticket."
                : ": Bad Data. Submit service ticket."
              : "";
          state.currentInteraction.messageDeliveryErrorDetails =
            interaction.messageDeliveryStatus === "failed"
              ? ": " + payload.reports[0].meta.error.msg
              : //? (payload.reports[0].meta.error.msg.includes("destinationPhone is not valid") ? ": Customer Phone is Empty or Invalid" : ": "+payload.reports[0].meta.error.msg)
                "";
        }
      }

      return {
        ...state,
        interactions: newInteraction,
      };
    }

    case UPDATE_DO_NOT_TEXT_STATUS_BAR: {
      let payload = action.payload.data;

      let interactionToUpdate = state.interactions.filter(
        (interaction) => interaction.clientId === payload.clientID
      );
      let indexOfInteractionToBeUpdated = state.interactions.findIndex(
        (interaction) => interaction.clientId === payload.clientID
      );
      let newInteraction = state.interactions.filter(
        (interaction) => interaction.clientId !== payload.clientID
      );

      //if it exists update it
      if (interactionToUpdate.length > 0) {
        let interaction = interactionToUpdate[0];
        interaction.doNotText = payload.donotText;

        //add interaction to where it was in list
        newInteraction.splice(indexOfInteractionToBeUpdated, 0, interaction);

        //if you are on the current interaction, the values need to be updated
        if (state.currentInteraction.clientId === payload.clientID) {
          state.currentInteraction.doNotText = payload.donotText;
        }
      }

      //update the donotText value for the particular client in the clientInfos redux list so when GetInteractions is called, it pulls the right value.
      let indexOfClientInfoTobeUpdated = state.clientInfos.findIndex(
        (clientInfo) => clientInfo.clientID === payload.clientID
      );
      let newClientInfo = state.clientInfos.filter(
        (clientInfo) => clientInfo.clientID !== payload.clientID
      );

      let clientInfoTobeUpdated = state.clientInfos.filter(
        (clientInfo) => clientInfo.clientID === payload.clientID
      );
      if (clientInfoTobeUpdated.length > 0) {
        let clientInfo = clientInfoTobeUpdated[0];
        clientInfo.doNotText = payload.donotText;

        newClientInfo.splice(indexOfClientInfoTobeUpdated, 0, clientInfo);
      }

      return {
        ...state,
        interactions: newInteraction,
        clientInfos: newClientInfo,
      };
    }

    case GO_BACK_TO_DRAFT_MESSAGE: {
      return {
        ...state,
        chatLog: [],
        currentInteraction: state.createdInteraction,
      };
    }

    case UPDATE_CLIENT_TYPE_FOR_MULTIMATCH: {
      return {
        ...state,
        clientType: "client",
      };
    }

    case ADD_ECRM_MOBILE_NUMBER: {
      let ecrmClientId = action.payload.ecrmClientId;

      let interactionToUpdate = state.interactions.filter(
        (interaction) => interaction.clientId === ecrmClientId
      );
      let indexOfInteractionToBeUpdated = state.interactions.findIndex(
        (interaction) => interaction.clientId === ecrmClientId
      );
      let interactions = state.interactions.filter(
        (interaction) => interaction.clientId !== ecrmClientId
      );

      //if it exists update it
      if (interactionToUpdate.length > 0) {
        let interaction = interactionToUpdate[0];
        interaction.ecrmMobile = action.payload.ecrmPhone;

        //add interaction to where it was in list
        interactions.splice(indexOfInteractionToBeUpdated, 0, interaction);

        //if you are on the current interaction, the values need to be updated
        if (state.currentInteraction.clientId === ecrmClientId) {
          state.currentInteraction.ecrmMobile = action.payload.ecrmPhone;
        }
      }
      return {
        ...state,
        interactions,
      };
    }

    case ARCHIVE_INTERACTION: {
      const interactions = [...state.interactions];
      let indexOfInteractionToBeRemoved = state.interactions.findIndex(
        (interaction) =>
          interaction.interactionId === action.payload.interactionID
      );
      interactions.splice(indexOfInteractionToBeRemoved, 1);

      return {
        ...state,
        interactions,
      };
    }

    case SET_CURRENT_FILTER: {
      return {
        ...state,
        interactions:
          action.payload.isMyMessagesFilterOn !== undefined
            ? state.interactions
            : [],
        initialQueryExecuted:
          action.payload.isMyMessagesFilterOn !== undefined
            ? state.initialQueryExecuted
            : false,
        nextToken:
          action.payload.isMyMessagesFilterOn !== undefined
            ? state.nextToken
            : "",
        resultsStatus:
          action.payload.isMyMessagesFilterOn !== undefined
            ? state.resultsStatus
            : "Loading ...",
        isNewFilterOn:
          action.payload.isNewFilterOn !== undefined
            ? action.payload.isNewFilterOn
            : state.isNewFilterOn,
        isReadFilterOn:
          action.payload.isReadFilterOn !== undefined
            ? action.payload.isReadFilterOn
            : state.isReadFilterOn,
        isRepliedToFilterOn:
          action.payload.isRepliedToFilterOn !== undefined
            ? action.payload.isRepliedToFilterOn
            : state.isRepliedToFilterOn,
        isFollowUpFilterOn:
          action.payload.isFollowUpFilterOn !== undefined
            ? action.payload.isFollowUpFilterOn
            : state.isFollowUpFilterOn,
        isHiddenFilterOn:
          action.payload.isHiddenFilterOn !== undefined
            ? action.payload.isHiddenFilterOn
            : state.isHiddenFilterOn,
        isTextFilterOn:
          action.payload.isTextFilterOn !== undefined
            ? action.payload.isTextFilterOn
            : state.isTextFilterOn,
        isMyMessagesFilterOn:
          action.payload.isMyMessagesFilterOn !== undefined
            ? action.payload.isMyMessagesFilterOn
            : state.isMyMessagesFilterOn,
        currentFilter: action.payload.value,
        textFilterLastSearch:
          action.payload.textFilterLastSearch !== undefined
            ? action.payload.textFilterLastSearch
            : state.textFilterLastSearch,
      };
    }

    case CHANGE_FILTER_OPTIONS: {
      const isTextFilterOn = state.isTextFilterOn;
      let interactions = [...state.interactions];
      let filteredInteractions = state.filteredInteractions;

      //Each click in the filter area (My Message, Search by name/number and Unread/Viewed/Replied) should call this reducer method
      state.filterOptions[action.payload.name] = action.payload.value;
      state.filterOptions.SearchByNameNumber.selectedSearchCriteria = action
        .payload.searchCriteria
        ? action.payload.searchCriteria
        : state.filterOptions.SearchByNameNumber.selectedSearchCriteria;
      state.filterOptions.SearchByNameNumber.selectedSearchValue = action
        .payload.searchValue
        ? action.payload.searchValue
        : state.filterOptions.SearchByNameNumber.selectedSearchValue;

      if (isTextFilterOn || state.filterOptions.MyMessages) {
        if (state.filterOptions.MyMessages) {
          filteredInteractions = interactions.filter(
            (item) =>
              item.assignedAssociates === state.loggedInAssociateId ||
              item.assignedAssociates === ""
          );
        }

        // Search by Name or Number
        if (
          state.filterOptions.SearchByNameNumber.selectedSearchCriteria ===
          "fuzzySearch"
        ) {
          filteredInteractions = interactions;
        }

        //if the current interaction does not exist in the temporary filtered list, then clear the current interaction
        let matchCurrentInteraction =
          filteredInteractions.length &&
          filteredInteractions.filter(
            (interaction) =>
              interaction.interactionId ===
              state.currentInteraction.interactionId
          );
        if (matchCurrentInteraction.length === 0) {
          state.currentInteraction = { interactionId: null };
        }

        return {
          ...state,
          interactions,
          filteredInteractions,
          isNewlyRead: action.payload.isNewlyRead,
        };
      }
      return {
        ...state,
      };
    }

    case PATCH_UPDATE_STARRED_INTERACTION: {
      const interactions = state.interactions.map((i) => {
        if (i.interactionId === action.payload.interactionID) {
          return { ...i, starred: action.payload.starred };
        }
        return i;
      });
      return { ...state, interactions };
    }

    case STORE_CUSTOMER_INFO_FOR_PROSPECT_RESOLUTION: {
      let prospectToCustomerDetails = {};
      if (Object.keys(action.payload).length !== 0) {
        prospectToCustomerDetails.clientID = action.payload.clientID;
        prospectToCustomerDetails.firstName = action.payload.firstName;
        prospectToCustomerDetails.middleName = action.payload.middleName;
        prospectToCustomerDetails.lastName = action.payload.lastName;
        prospectToCustomerDetails.customerInitials =
          action.payload.firstName === "" || action.payload.firstName === null
            ? action.payload.lastName[0]
            : action.payload.firstName[0] + "" + action.payload.lastName[0];
        prospectToCustomerDetails.doNotText = action.payload.doNotText;
        prospectToCustomerDetails.customerOfAnotherAgent =
          action.payload.customerOfAnotherAgent;
        prospectToCustomerDetails.firstTextableMobilePhone = action.payload
          .displayedPhone
          ? action.payload.displayedPhone
          : action.payload.firstTextableMobilePhone;
        prospectToCustomerDetails.casl = action.payload.casl;
      }

      return {
        ...state,
        prospectToCustomerDetails: prospectToCustomerDetails,
      };
    }

    case PATCH_UPDATE_INTERACTION_PROSPECT_TO_CUSTOMER: {
      let updatedClientInfos = state.clientInfos;
      updatedClientInfos.push(
        addNewClientDataToClientInfos(state.prospectToCustomerDetails)
      );

      if (
        state.currentInteraction.interactionId === action.payload.interactionID
      ) {
        state.chatLog[0].messageSenderName =
          state.prospectToCustomerDetails.firstName +
          " " +
          state.prospectToCustomerDetails.lastName;
        state.currentInteraction.webSocketJSON.ownerClient.clientID =
          action.payload.ownerClient.clientID;
        state.currentInteraction.webSocketJSON.ownerClient.type = "client";
      }
      return {
        ...state,
        clientInfos: updatedClientInfos,
        prospectToCustomerDetails: [],
      };
    }

    case PATCH_INTERACTION_HIDDEN_SETTING: {
      let interactionToUpdate;
      let interactions = [...state.interactions];

      if (action.payload) {
        action = {
          hidden: action.payload.hidden,
          interactionId: action.payload.interactionId,
        };
      }
      interactionToUpdate = interactions.filter(
        (interaction) => interaction.interactionId === action.interactionId
      )[0];

      interactions = interactions.filter(
        (interaction) => interaction.interactionId !== action.interactionId
      );

      let officeMessagesUnreadCount = state.officeMessagesUnreadCount;

      if (
        !interactionToUpdate.isMessageViewed &&
        interactionToUpdate.hidden.value
      ) {
        officeMessagesUnreadCount -= 1;
      } else if (
        !interactionToUpdate.isMessageViewed &&
        !interactionToUpdate.hidden.value
      ) {
        officeMessagesUnreadCount += 1;
      }

      return {
        ...state,
        interactions,
        officeMessagesUnreadCount,
      };
    }

    case PATCH_INTERACTION_ASSIGN_SETTING: {
      const interactions = [...state.interactions];
      const updatedInteraction = state.interactions.find(
        (interaction) => interaction.interactionId === action.interactionId
      );

      if (updatedInteraction) {
        updatedInteraction.assigned = action.assigned;
        if (!interactionMatchesCurrentFilters(updatedInteraction, state)) {
          const interactionsIndex = interactions.findIndex(
            (interaction) => interaction.interactionId === action.interactionId
          );
          interactions.splice(interactionsIndex, 1);
        }
      }
      return { ...state, interactions };
    }

    case REMOVE_INTERACTION: {
      const interactionIdToRemove = action.interactionId;

      const interactions = state.interactions.filter(
        (interaction) => interaction.interactionId !== interactionIdToRemove
      );

      return {
        ...state,
        interactions,
      };
    }

    case RESET_INTERACTIONS: {
      return createInitialState();
    }

    case ADD_INTERACTION: {
      let interactions = [...state.interactions];
      let clientInfos = state.clientInfos;
      let createdInteraction = state.createdInteraction;
      let draftToRemove;
      let newDraftMessage = state.newDraftMessage;

      if (!action.payload.interactionId) {
        return {
          ...state,
        };
      }
      let newInteraction = translateInteractionFromModernizedStructure(
        action.payload,
        action.currentOfficeMembers
      );
      newInteraction.hidden.value = false;
      let messageBody = newInteraction.messagePreviewText;

      const webSocket = translateWSMessage(newInteraction);

      const interactionsIndex = interactions.findIndex(
        (interaction) =>
          interaction.interactionId === "tempInteractionId" &&
          interaction.clientId === newInteraction.clientId
      );

      if (interactionsIndex !== -1) {
        draftToRemove = interactions.splice(interactionsIndex, 1);
      }

      if (action.payload.client.details) {
        const newClient = translateClientInfosForReducer(
          action.payload.client.details
        );

        clientInfos.push(newClient);
      } else if (draftToRemove) {
        newInteraction = {
          ...newInteraction,
          customerInitials: draftToRemove[0].customerInitials,
          customerName: draftToRemove[0].customerName,
        };
        createdInteraction = undefined;
        newDraftMessage = false;
      }

      interactions.unshift(newInteraction);

      return {
        ...state,
        interactions,
        currentInteraction: {
          ...newInteraction,
          messageBody: messageBody,
          webSocketJSON: {
            ...webSocket,
            messageID: webSocket.messageID,
            message: {
              body: messageBody,
            },
          },
        },
        createdInteraction,
        newDraftMessage,
        currentInteractionMessagesFetched: false,
        clientInfos,
      };
    }

    case HIDE_INTERACTION: {
      let clientInfo = translateClientInfosForReducer(
        action.payload.interaction.client.details
      );

      let clientInfos = clientInfo
        ? state.clientInfos.concat(clientInfo)
        : state.clientInfos;
      const hiddenInteraction = translateInteractionFromModernizedStructure(
        action.payload.interaction,
        action.payload.currentOfficeMembers
      );
      const interactions = [...state.interactions];

      let foundInteractionIndex = interactions.findIndex(
        (interaction) =>
          interaction.interactionId === hiddenInteraction.interactionId
      );
      if (state.isHiddenFilterOn) {
        if (foundInteractionIndex === -1) {
          interactions.unshift(hiddenInteraction);
        }
      } else {
        if (foundInteractionIndex !== -1) {
          interactions.splice(foundInteractionIndex, 1);
          toastInteractionStatusChange(
            clientInfo,
            hiddenInteraction.clientType,
            "hidden"
          );
        }
      }

      return {
        ...state,
        interactions,
        clientInfos,
      };
    }

    case UNHIDE_INTERACTION: {
      let clientInfo = translateClientInfosForReducer(
        action.payload.interaction.client.details
      );

      let clientInfos = clientInfo
        ? state.clientInfos.concat(clientInfo)
        : state.clientInfos;

      const unhiddenInteraction = translateInteractionFromModernizedStructure(
        action.payload.interaction,
        action.payload.currentOfficeMembers
      );

      const interactions = [...state.interactions];

      let foundInteractionIndex = interactions.findIndex(
        (interaction) =>
          interaction.interactionId === unhiddenInteraction.interactionId
      );
      if (state.isHiddenFilterOn) {
        if (foundInteractionIndex !== -1) {
          interactions.splice(foundInteractionIndex, 1);
          toastInteractionStatusChange(
            clientInfo,
            unhiddenInteraction.clientType,
            "unhidden"
          );
        }
      } else {
        if (
          foundInteractionIndex === -1 &&
          interactionMatchesCurrentFilters(unhiddenInteraction, state)
        ) {
          interactions.unshift(unhiddenInteraction);
        }
      }

      return {
        ...state,
        interactions,
        clientInfos,
      };
    }

    case SET_LOGGED_IN_USER: {
      const loggedInAssociateId = action.payload.associateID;
      const loggedInUserName = action.payload.userName;

      const selectedAssignmentsFilter = state.selectedAssignmentsFilter;

      selectedAssignmentsFilter.push({
        associateId: loggedInAssociateId,
        associateName: loggedInUserName,
      });

      return {
        ...state,
        loggedInAssociateId,
        selectedAssignmentsFilter,
      };
    }

    case SET_ASSIGNMENTS_FILTER: {
      const selectedAssociateId = action.payload.selectedAssociateId;
      const selectedAssociateName = action.payload.selectedAssociateName;
      const selectAllChange = action.payload.selectAllChange;
      const officeMembers = action.payload.officeMembers;
      let selectedAssignmentsFilter = [...state.selectedAssignmentsFilter];
      let selectAllAssignments = state.selectAllAssignments;
      let returnUnassigned = state.returnUnassigned;

      if (selectAllChange) {
        selectAllAssignments = !selectAllAssignments;

        if (!selectAllAssignments) {
          selectedAssignmentsFilter = [];
          returnUnassigned = false;
        } else {
          selectedAssignmentsFilter = [];
          returnUnassigned = true;

          for (let officeMember of officeMembers) {
            selectedAssignmentsFilter.push(officeMember);
          }
        }
      } else {
        const assignedAssociateIndex = selectedAssignmentsFilter.findIndex(
          (assignedAssociate) =>
            assignedAssociate.associateId === selectedAssociateId
        );

        if (assignedAssociateIndex === -1) {
          selectedAssignmentsFilter.push({
            associateId: selectedAssociateId,
            associateName: selectedAssociateName,
          });
          if (
            returnUnassigned &
            (selectedAssignmentsFilter.length === officeMembers.length)
          ) {
            selectAllAssignments = true;
          }
        } else {
          selectedAssignmentsFilter.splice(assignedAssociateIndex, 1);
          selectAllAssignments = false;
        }
      }

      return {
        ...state,
        selectedAssignmentsFilter,
        selectAllAssignments,
        returnUnassigned,
      };
    }

    case UPDATE_UNASSIGNED_FILTER: {
      const officeMembers = action.payload.officeMembers;
      const returnUnassigned = !state.returnUnassigned;
      let selectAllAssignments = state.selectAllAssignments;
      let selectedAssignmentsFilter = [...state.selectedAssignmentsFilter];

      if (!returnUnassigned) {
        selectAllAssignments = false;
      } else if (
        returnUnassigned &&
        selectedAssignmentsFilter.length === officeMembers.length
      ) {
        selectAllAssignments = true;
      }
      return {
        ...state,
        returnUnassigned,
        selectAllAssignments,
      };
    }

    case CLEAR_INTERACTIONS: {
      return {
        ...state,
        interactions: [],
      };
    }

    case ASSIGN_INTERACTION: {
      let clientInfo = translateClientInfosForReducer(
        action.payload.interaction.client.details
      );

      let clientInfos = clientInfo
        ? state.clientInfos.concat(clientInfo)
        : state.clientInfos;

      const assignedInteraction = translateInteractionFromModernizedStructure(
        action.payload.interaction,
        action.payload.currentOfficeMembers
      );

      const interactions = [...state.interactions];
      const selectedAssignmentsFilter = state.selectedAssignmentsFilter;

      let foundInteractionIndex = interactions.findIndex(
        (interaction) =>
          interaction.interactionId === assignedInteraction.interactionId
      );
      if (
        !(
          selectedAssignmentsFilter.length &&
          selectedAssignmentsFilter.some(
            (assignment) =>
              assignment.associateId ===
              assignedInteraction.assigned.assignedAssociateId
          )
        )
      ) {
        if (foundInteractionIndex !== -1) {
          interactions.splice(foundInteractionIndex, 1);
          toastInteractionStatusChange(
            clientInfo,
            assignedInteraction.clientType,
            "assigned"
          );
        }
      } else if (
        foundInteractionIndex === -1 &&
        interactionMatchesCurrentFilters(assignedInteraction, state)
      ) {
        interactions.unshift(assignedInteraction);
      } else {
        interactions[foundInteractionIndex] = assignedInteraction;
      }

      return {
        ...state,
        interactions,
        clientInfos,
      };
    }

    case UNASSIGN_INTERACTION: {
      let clientInfo = translateClientInfosForReducer(
        action.payload.interaction.client.details
      );

      let clientInfos = clientInfo
        ? state.clientInfos.concat(clientInfo)
        : state.clientInfos;

      const unassignedInteraction = translateInteractionFromModernizedStructure(
        action.payload.interaction,
        action.payload.currentOfficeMembers
      );

      const interactions = [...state.interactions];
      const returnUnassigned = state.returnUnassigned;

      let foundInteractionIndex = interactions.findIndex(
        (interaction) =>
          interaction.interactionId === unassignedInteraction.interactionId
      );
      if (!returnUnassigned) {
        if (foundInteractionIndex !== -1) {
          interactions.splice(foundInteractionIndex, 1);
          toastInteractionStatusChange(
            clientInfo,
            unassignedInteraction.clientType,
            "unassigned"
          );
        }
      } else if (
        foundInteractionIndex === -1 &&
        interactionMatchesCurrentFilters(unassignedInteraction, state)
      ) {
        interactions.unshift(unassignedInteraction);
      } else {
        interactions[foundInteractionIndex] = unassignedInteraction;
      }

      return {
        ...state,
        interactions,
        clientInfos,
      };
    }

    default:
      return state;
  }
}

const interactionMatchesCurrentFilters = (interaction, state) => {
  const isNewFilterOn = state.isNewFilterOn;
  const isReadFilterOn = state.isReadFilterOn;
  const isRepliedToFilterOn = state.isRepliedToFilterOn;
  const isFollowUpFilterOn = state.isFollowUpFilterOn;
  const isHiddenFilterOn = state.isHiddenFilterOn;
  const selectedAssignmentsFilter = state.selectedAssignmentsFilter;
  const returnUnassigned = state.returnUnassigned;

  if (
    (interaction.assigned.value === false && returnUnassigned) ||
    (interaction.assigned.value &&
      selectedAssignmentsFilter.length &&
      selectedAssignmentsFilter.some(
        (assignment) =>
          assignment.associateId === interaction.assigned.assignedAssociateId
      ))
  ) {
    if (
      !(
        isNewFilterOn ||
        isReadFilterOn ||
        isRepliedToFilterOn ||
        isFollowUpFilterOn ||
        isHiddenFilterOn
      )
    ) {
      return !interaction.hidden.value;
    } else if (isNewFilterOn) {
      return !interaction.isMessageViewed && !interaction.hidden.value;
    } else if (isReadFilterOn) {
      return interaction.isMessageViewed && !interaction.hidden.value;
    } else if (isHiddenFilterOn) {
      return interaction.hidden.value;
    }
  } else {
    return false;
  }
};

const toastInteractionStatusChange = (clientInfo, clientType, action) => {
  toast.info(
    `The interaction for customer ${
      clientType === "client" &&
      clientInfo &&
      clientInfo.length &&
      clientInfo[0].firstName
        ? clientInfo[0].firstName[0].toUpperCase() +
          clientInfo[0].firstName.slice(1).toLowerCase() +
          " "
        : ""
    }${
      clientType === "client" &&
      clientInfo &&
      clientInfo.length &&
      clientInfo[0].lastName
        ? clientInfo[0].lastName[0].toUpperCase() +
          clientInfo[0].lastName.slice(1).toLowerCase() +
          " "
        : ""
    }${
      clientType !== "client" ||
      (clientInfo &&
        clientInfo.length &&
        !(clientInfo[0].firstName || clientInfo[0].lastName))
        ? clientInfo[0].phoneNumber + " "
        : ""
    }was ${action}.`,
    {
      position: toast.POSITION.TOP_RIGHT,
    }
  );
};
