import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import { bindActionCreators } from "redux";
import { getOfficePreferences } from "../redux/actions/officeSettingsActions";
import { updateAssociateId } from "redux/actions/userInfoActions";
import MessageFilter from "../components/message-filter";
import Messages from "../components/Message";
import Options from "../components/Message/Options";
import Loader from "../components/Loader";
import UserNotAuthorized from "../components/UnauthorizedUser";
import ECRMErrorMessage from "../components/ECRMErrorMessage";
import GenericError from "../pages/genericError";
import Breadcrumb from "../components/Breadcrumb";
import Notification from "../components/Notification";
import MediaQuery from "react-responsive";
import { sfc_routes } from "../utils/general";
import queryString from "query-string";
import {
  mainAction,
  fetchMessages,
  patchUpdateInteraction,
  getInteractionForCustomer,
} from "redux/actions/interactionsActions";
import { getCustomerInfo } from "redux/actions/customerSearchAction";
import {
  CREATE_NEW_INTERACTION,
  RESET_CUSTOMER_INFO_RETRIEVED,
  ADD_ECRM_MOBILE_NUMBER,
  PATCH_INTERACTION_HIDDEN_SETTING,
} from "../redux/actions/types";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const TITLE = "Messages | SF Connect";

class Home extends Component {
  state = {
    redirectError: false,
    clientId: "",
    stateAgentCode: "",
    firstTextableMobilePhone: "",
    ecrmRetrival: false,
    ecrmError: false,
  };

  getOfficePreferences() {
    if (
      !this.props.officeSettingsIsFetched &&
      this.props.token &&
      (this.props.moaPageShown || this.props.agentsOfficeList.length === 1)
    ) {
      this.props.actions.getOfficePreferences(
        this.props.officeAssociateId,
        this.props.loggedInAssociateId
      );
      clearTimeout(this.id);
    }
  }
  // needed for MOA ECRM link
  setOfficePreferences(stateAgentCodeParam) {
    let officeList = this.props.agentsOfficeList.filter(
      (agentOffice) =>
        agentOffice.stateAgentCode.substring(2) ===
        stateAgentCodeParam.substring(2)
    );
    if (!this.state.ecrmError && officeList.length === 0) {
      this.setState({ ecrmError: true });
    } else if (
      this.props.officeAssociateId === "" &&
      window.location.search &&
      officeList.length > 0
    ) {
      this.props.actions.updateAssociateId(officeList[0].id);
      this.props.actions.getOfficePreferences(
        officeList[0].id,
        this.props.loggedInAssociateId
      );
    }
  }

  // replicates the send text redirect similar to customer search send text
  sendTextRedirect(clientId) {
    //needed for MOA ECRM link becaus bypassing MOA page
    if (this.props.agentsOfficeList.length > 1) {
      this.setOfficePreferences(this.state.stateAgentCode);
    }

    // this checks for client id in url params from ECRM link
    if (clientId) {
      //add firstTextableMobilePhone to Redux store
      if (this.state.firstTextableMobilePhone) {
        this.props.actions.mainAction(ADD_ECRM_MOBILE_NUMBER, {
          ecrmPhone: this.state.firstTextableMobilePhone,
          ecrmClientId: clientId,
        });
      }
      if (
        this.props.interactions.filter(
          (interaction) => interaction.clientId === clientId
        ).length > 0
      ) {
        let interaction = this.props.interactions.filter(
          (interaction) => interaction.clientId === clientId
        )[0];

        if (interaction.hidden.value) {
          this.props.actions.mainAction(PATCH_INTERACTION_HIDDEN_SETTING, {
            hidden: false,
            interactionId: interaction.interactionId,
          });
        }

        this.props.actions.fetchMessages(
          interaction.interactionId,
          this.props.token,
          this.props.officeAssociateId,
          clientId,
          this.props.currentOfficeMembers
        );
      }
      //existing interaction - condition for prospect in in-tray having a matching phone number to the picked customer from ECRM
      else if (
        this.props.interactions.filter(
          (interaction) => interaction.clientType === "prospect"
        ).length > 0 &&
        this.props.interactions.filter(
          (interaction) =>
            interaction.unmaskedPhoneNumber ===
            this.state.firstTextableMobilePhone
        ).length > 0
      ) {
        let interactionId = this.props.interactions.filter(
          (interaction) =>
            interaction.unmaskedPhoneNumber ===
            this.state.firstTextableMobilePhone
        )[0].interactionId;
        let prospectClientId = this.props.interactions.filter(
          (interaction) =>
            interaction.unmaskedPhoneNumber ===
            this.state.firstTextableMobilePhone
        )[0].clientId;
        this.props.actions.fetchMessages(
          interactionId,
          this.props.token,
          this.props.officeAssociateId,
          prospectClientId,
          this.props.currentOfficeMembers
        );
        this.props.actions.getCustomerInfo(
          this.props.token,
          clientId,
          this.props.officeAssociateId,
          true
        );
        this.props.actions.patchUpdateInteraction(
          this.props.token,
          this.props.officeAssociateId,
          clientId,
          prospectClientId,
          interactionId,
          "prospect"
        );
      }
      //new interaction
      else {
        this.props.actions.getInteractionForCustomer(
          this.props.officeAssociateId,
          clientId,
          this.props.currentOfficeMembers
        );
        this.props.actions.getCustomerInfo(
          this.props.token,
          clientId,
          this.props.officeAssociateId,
          false
        );
      }
    }
  }
  componentDidMount() {
    this.id = setTimeout(() => this.setState({ redirectError: true }), 30000);
    let paramString = queryString.parse(window.location.search);
    this.setState({
      clientId: paramString.clientId,
      stateAgentCode: paramString.stateAgentCode,
      firstTextableMobilePhone: paramString.firstTextableMobilePhone,
    });
  }

  componentDidUpdate() {
    if (this.props.isCustomerInfoRetrieved) {
      const _clientData = {
        ...this.props.clientDetails,
        token: this.props.token,
        officeAssociateId: this.props.officeAssociateId,
        hashedClientId: this.state.clientID,
        interactions: this.props.interactions,
        loggedInAssociateId: this.props.loggedInAssociateId,
        loggedInAssociateName: this.props.loggedInAssociateName,
        currentOfficeMembers: this.props.currentOfficeMembers,
      };
      let newInteractionData = {
        officeAssociateId: this.props.officeAssociateId,

        //need to make call to get customer info here
        // customerDetails: this.props.customerInfoReducer.customerDetails,
        loggedInAssociateId: this.props.loggedInAssociateId,
        loggedInAssociateName: this.props.loggedInAssociateName,
        customerDetails: {
          ..._clientData,
        },
        ecrmPhone: this.state.firstTextableMobilePhone,
      };

      this.props.actions.mainAction(CREATE_NEW_INTERACTION, newInteractionData);

      this.props.actions.mainAction(RESET_CUSTOMER_INFO_RETRIEVED, null);
    }
    // checks for ECRM link to moa office and sets office preferences
    if (
      this.props.agentsOfficeList.length > 1 &&
      this.state.clientId &&
      this.state.clientId !== "" &&
      !this.props.officeSettingsIsFetched
    ) {
      this.setOfficePreferences(this.state.stateAgentCode);
    }

    if (this.props.userInfoIsFetched && !this.props.officeSettingsIsFetched) {
      this.getOfficePreferences();
      this.id = setTimeout(() => this.setState({ redirectError: true }), 30000);
    }
    window.oneX.Tabs.adjustRedBarIn();

    if (
      this.props.initialQueryExecuted &&
      this.state.clientId &&
      this.state.clientId !== "" &&
      !this.state.ecrmRetrival
    ) {
      this.sendTextRedirect(this.state.clientId);
      this.setState({
        ecrmRetrival: true,
      });
    }

    if (
      this.props.currentInteraction &&
      !this.props.currentInteractionMessagesFetched
    ) {
      this.props.actions.fetchMessages(
        this.props.currentInteraction,
        this.props.token,
        this.props.officeAssociateId,
        this.state.clientId,
        this.props.currentOfficeMembers
      );
    }
  }

  vaInfoMessage() {
    return (
      <span>
        Thank you for participating in the SF Connect Virtual Assistant pilot.
        To disable the Virtual Assistant, please use your normal support
        channels to open a ticket to the SFConnect team.
      </span>
    );
  }

  render() {
    // check url is link from ECRM if si
    if (
      this.props.agentsOfficeList.length > 1 &&
      !window.location.search &&
      !this.props.moaPageShown
    ) {
      return <Redirect to={sfc_routes.pages.moa} />;
    } else if (
      this.props.userInfoError === "Request failed with status code 401" ||
      this.props.userInfoError === "Request failed with status code 403"
    ) {
      return <UserNotAuthorized />;
    } else if (this.state.ecrmError) {
      return <ECRMErrorMessage />;
    } else if (this.props.error) {
      return (
        <GenericError
          actionInError={this.props.actionInError}
          errorDetails={this.props.errorDetails}
        />
      );
    } else {
      if (this.props.officeSettingsIsFetched) {
        if (!this.props.isFetchedButNoOffice) {
          return (
            <>
              <Helmet>
                {this.props.unreadCount && this.props.unreadCount > 0 ? (
                  <title>{"( " + this.props.unreadCount + " ) " + TITLE}</title>
                ) : (
                  <title>{TITLE}</title>
                )}
              </Helmet>
              <div className="-oneX-container">
                {this.props.officeSetttingOooEnabled &&
                  this.props.officeSetttingVirtualAssistant !== true && (
                    <div className="-oneX-row">
                      <div className="-oneX-col">
                        <Notification type="warning" className="notification">
                          Out of office is currently enabled
                        </Notification>
                      </div>
                    </div>
                  )}

                {this.props.officeSetttingVirtualAssistant === true &&
                  !this.props.officeSetttingOooEnabled && (
                    <div className="-oneX-row">
                      <div className="-oneX-col">
                        <Notification type="warning" className="notification">
                          Virtual Assistant is currently enabled
                          <div
                            id="vaInfo-icon-background"
                            className="vaInfo-icon-background">
                            <div
                              id="vaEnabled"
                              className="-oneX-icon-container vaInfo">
                              &#9432;
                            </div>
                          </div>
                          <div className="vaInfoText">
                            {this.vaInfoMessage()}
                          </div>
                        </Notification>
                      </div>
                    </div>
                  )}

                {this.props.officeSetttingVirtualAssistant === true &&
                  this.props.officeSetttingOooEnabled && (
                    <div className="-oneX-row">
                      <div className="-oneX-col">
                        <Notification type="warning" className="notification">
                          Out of office and Virtual Assistant are currently
                          enabled
                          <div
                            className="vaInfo-icon-background"
                            title="Out of Office and Virtual Assistant are currently enabled">
                            <div
                              id="vaOOOEnabled"
                              className="-oneX-icon-container vaInfo">
                              &#9432;
                            </div>
                          </div>
                          <div className="vaInfoText oooAndVAInfoText">
                            {this.vaInfoMessage()}
                          </div>
                        </Notification>
                      </div>
                    </div>
                  )}

                <div className="-oneX-row -oneX-no-gutters -display-flex-row">
                  {/* | Mobile View Port | */}
                  <MediaQuery maxWidth={990}>
                    {!this.props.currentInteraction ? (
                      <div className="-oneX-col texts">
                        <Options history={this.props.history} />
                        <MessageFilter />
                      </div>
                    ) : (
                      <div className="-oneX-col">
                        <Breadcrumb
                          title="Message In Tray"
                          history={this.props.history}
                        />
                        <Messages history={this.props.history} />
                      </div>
                    )}
                  </MediaQuery>
                  {/* | Desktop View Port | */}
                  <MediaQuery minWidth={991}>
                    <div className="-oneX-col-4 ">
                      <Options history={this.props.history} />
                      <MessageFilter />
                    </div>
                    <div className="-oneX-col-6 -oneX-col-md-6 -oneX-col-lg-8 -oneX-d-none -oneX-d-lg-block">
                      <Messages history={this.props.history} />
                    </div>
                  </MediaQuery>
                </div>
                <ToastContainer />
              </div>
            </>
          );
        } else {
          return <Redirect to={sfc_routes.pages.signup} />;
        }
      } else {
        return (
          <>
            <div className="-oneX-container">
              {this.state.redirectError ? (
                <Redirect to={sfc_routes.pages.error} />
              ) : (
                this.props.userInfoIsFetched &&
                !this.props.officeSettingsIsFetched && <Loader />
              )}
            </div>
          </>
        );
      }
    }
  }
}

const mapStateToProps = (state) => ({
  token: state.authReducer.token,
  officeAssociateId: state.userInfoReducer.officeAssociateId,
  loggedInAssociateId: state.userInfoReducer.loggedInAssociateId,
  loggedInAssociateName: state.userInfoReducer.loggedInAssociateName,
  officeSettingsIsFetched: state.officeSettingsReducer.isFetched,
  officeSetttingOooEnabled: state.officeSettingsReducer.oooEnabled,
  officeSetttingVirtualAssistant:
    state.officeSettingsReducer.virtualAssistantEnabled,
  agentsOfficeList: state.userInfoReducer.agentsOfficeList,
  moaPageShown: state.userInfoReducer.moaPageShown,
  currentOfficeMembers: state.userInfoReducer.currentOfficeMembers,
  currentInteraction:
    state.interactionsReducer.currentInteraction.interactionId,
  error: state.interactionsReducer.error,
  userInfoError: state.userInfoReducer.error,
  actionInError: state.interactionsReducer.actionInError,
  errorDetails: state.interactionsReducer.errorDetails,
  unreadCount: state.interactionsReducer.officeMessagesUnreadCount,
  userInfoIsFetched: state.userInfoReducer.isFetched,
  interactions: state.interactionsReducer.interactions,
  initialQueryExecuted: state.interactionsReducer.initialQueryExecuted,
  isCustomerInfoRetrieved: state.customerSearchReducer.isCustomerInfoRetrieved,
  clientDetails: state.customerSearchReducer.clientDetails,
  isFetchedButNoOffice: state.officeSettingsReducer.isFetchedButNoOffice,
  currentInteractionMessagesFetched:
    state.interactionsReducer.currentInteractionMessagesFetched,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        getOfficePreferences,
        mainAction,
        fetchMessages,
        getCustomerInfo,
        updateAssociateId,
        patchUpdateInteraction,
        getInteractionForCustomer,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
