import {
    SET_WEBSOCKET
  } from "../redux/actions/types.js";
  import {
    dispatchWebsocketData,
  } from "../redux/actions/webSocketActions";


export const wsReadyState = {
    CONNECTING: 0,
    OPEN: 1,
    CLOSING: 2,
    CLOSED: 3
}


// Endpoints MAY use the following pre-defined status codes when sending a Close frame.
// Defined Status Codes     https://tools.ietf.org/html/rfc6455#section-11.7
export const closeframe = {
    StatusCode: {
        NORMAL_CLOSURE: 1000,
        GOING_AWAY: 1001,
        PROTOCOL_ERROR: 1002,
        UNSUPPORTED_DATA_ERROR: 1003,
        RESERVED_CLOSURE_4: 1004,
        NO_STATUS_RCVD: 1005,
        ABNORMAL_CLOSURE: 1006,
        INVALID_FRAME_PAYLOAD_DATA_ERROR: 1007,
        POLICY_VIOLATION_ERROR: 1008,
        MSG_TO_LARGE_ERROR: 1009,
        MANDATORY_EXT_ERROR: 1010,
        INTERNAL_SERVER_ERROR: 1011,
        RESERVED_CLOSURE_12: 1012,
        RESERVED_CLOSURE_13: 1013,
        RESERVED_CLOSURE_14: 1014,
        TSL_HANDSHAKE_ERROR: 1015
    }
}

export const websocketHandler = {
    //_self: this.websocketHandler,
    initialReconnectDelay: 1000,
    currentReconnectDelay: 10,
    maxReconnectDelay: 16000,
    socket: null,
    connectionString: null,
    connectionParms: null,
    readyState: wsReadyState.CLOSED,
    connect: function(wsURI, _parma) {
        this.connectionString = wsURI
        this.connectionParms = _parma
        this.createWebsocket()
        this.socket.addEventListener('open', this.onWebsocketOpen(this));
        this.socket.addEventListener('close', this.onWebsocketClose(this));
        this.socket.addEventListener('error', this.onWebsocketError(this)  );
        return this.socket;
    },
    createWebsocket: function() {
        this.socket = new WebSocket(this.connectionString, this.connectionParms);
        this.readyState = this.socket.readyState
        this.logStatus('Create')
    },
    refresh: function(param) {
        // Create new WS connection and close the previous socket.
        const prevSocket = this.socket;
        this.logStatus('Refresh');
        this.connect(this.connectionString, param);
        prevSocket.close();
    },
    onWebsocketOpen: function() {
        this.currentReconnectDelay = this.initialReconnectDelay
        this.readyState = this.socket.readyState
        this.dispatchEvent()
        this.readyState = this.socket.readyState
        this.logStatus('Open')        
    /*     // Uncomment below to quickly test socket close in ENV1 so it can call reconnect
        // setTimeout(() => {
        //     this.socket.close();
        //     this.onWebsocketClose(this);            
        // }, 10000);  */
    },
    onWebsocketClose: function() {
        if (this.socket.readyState > wsReadyState.OPEN) {
            this.logStatus('Close')
            this.dispatchEvent()
            this.socket = null
            this.readyState = wsReadyState.CLOSED
            // Add anything between 0 and 3000 ms to the delay.  
            setTimeout(() => {
                this.reconnectToWebsocket();
            },this.currentReconnectDelay + Math.floor(Math.random() * 3000)); 
        }
        this.dispatchEvent()
    },
    reconnectToWebsocket: function() {
        if (this.currentReconnectDelay < this.maxReconnectDelay) {
            this.currentReconnectDelay *= 2;
        }
        this.logStatus('Reconnect')
        this.connect(this.connectionString, this.connectionParms);
    },
    onWebsocketError: function(event) {
        this.readyState = (typeof this.socket !== 'undefined') ? this.socket.readyState : 3
        this.logStatus('on error Status')
    },
    logStatus: function(_status_) {
        console.log(`WebSocket ${_status_} -  Connection: ${this.connectionString}, Delay: ${this.currentReconnectDelay}, ReadyState: ${this.readyState}`)
    },
    dispatchEvent: function() {
        setTimeout(() => {
            document.dispatchEvent(
                new CustomEvent(
                    'SFC_WebSocketStatus', 
                    {
                        bubbles: true,
                        detail: {
                            reconnectDelay: this.currentReconnectDelay,
                            state: this.readyState
                        }
                    }
                )
            )
           dispatchWebsocketData(SET_WEBSOCKET, {socket: this.socket, readyState: this.readyState});
         }, 2000)

    }
   
};


