import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import CustomerSearchCriteria from "../components/CustomerSearch/CustomerSearchCriteria";
import CustomerSearchTable from "../components/CustomerSearch/CustomerSearchTable";

const TITLE = 'Search | SF Connect'

class Search extends Component {

  render() {
    return (
      <>
        <Helmet>{ this.props.unreadCount && this.props.unreadCount > 0 ? 
                <title>{"( " + this.props.unreadCount + " ) " + TITLE}</title>
                :
                <title>{TITLE}</title>
              }
        </Helmet>
        <div className="-oneX-container">
          <CustomerSearchCriteria history={this.props.history}/>
          <CustomerSearchTable history={this.props.history}/>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  unreadCount: state.interactionsReducer.officeMessagesUnreadCount
});

export default connect(mapStateToProps)(Search);
