const validFileTypes = [
  "image/bmp",
  "image/gif",
  "image/jpeg",
  "image/png",
  "application/pdf",
  "text/vcard",
];

function getExtension(fileName) {
  return fileName.match(/\.[^/.]+$/) ? fileName.match(/\.[^/.]+$/)[0] : "";
}

export function trimExtension(fileName) {
    return fileName.replace(/\.[^/.]+$/, "");
}

export function shortenFileName(fileName) {
  return trimExtension(fileName).substring(0, 15) + "..." + getExtension(fileName);
}

export function formatBytes(a, b = 2) {
  if (0 === a) return "0 Bytes";
  const c = 0 > b ? 0 : b,
    d = Math.floor(Math.log(a) / Math.log(1024));
  return (
    parseFloat((a / Math.pow(1024, d)).toFixed(c)) +
    " " +
    ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
  );
}

export function validateFile(file) {

  return (
    (file.name.includes(".vcf") || (validFileTypes.find((e) => e === file.type) ? true : false)) &&
    file.size <= 1000000
  );
}
