import React from "react";

const Loader = () => {
  //TODO if loader runs for more then 30 seconds, redirect to Error Page
  return (
    <>
      <div className="center-container-outer -sfc-center-on-page">
        <div className="-oneX -oneX-container center-container-inner">
          <div className="-oneX-page-loader" tabIndex="-1" id="loaderId">
            <h2 className="-oneX-loading-message">Loading...</h2>
            <div className="-oneX-loader-icon" aria-hidden="true"></div>

            <div className="-oneX-loaderdiv" aria-hidden="true">
              <svg className="-oneX-small">
                <circle cx="32" cy="32" r="28.8" viewBox="0 0 64 64"></circle>
              </svg>

              <svg className="-oneX-medium">
                <circle cx=" 40" cy="40" r="36" viewBox="0 0 80 80"></circle>
              </svg>

              <svg className="-oneX-large">
                <circle cx="50" cy="50" r="45" viewBox="0 0 100 100"></circle>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Loader;
