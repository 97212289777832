
/**
 *  A simple but high quality 53-bit hash.
 *  It's quite fast, provides very good hash distribution,
 *   and has significantly lower collision rates compared to any 32-bit hash.
 */
export function hashCode(s) {
  for (var i = 0, h; i < s.length; i++)
    h = (Math.imul(31, h) + s.charCodeAt(i)) | 0;
  return h;
}

export const sfc_routes = {
  _public_url: process.env.PUBLIC_URL,
  pages: {
    home: "/",
    search: "/search",
    error: "/error",
    moa: "/moa",
    signup: "/signup",
    gateway: "/gateway",
  },
};


export function hasMobileOveride(s) {
  return window.location.search.indexOf('emMobile') > 0 ? true : false
}


export const iOSListener = {
  isLoaded: () => {
    return (
      window.webkit &&
      window.webkit.messageHandlers &&
      window.webkit.messageHandlers.iosListener
    );
  },
  publish: (_message_) => {
    if (iOSListener.isLoaded()) {
      window.webkit.messageHandlers.iosListener.postMessage(_message_);
    }
  },
};