import React, { Component } from "react";
import Attachment from "components/Message/attachment";

export default class MessageList extends Component {
  render() {
    let { message, customerInitials } = this.props;
    return (
      <section className="message-pane" tabIndex="0" role="listitem">
        <div
          className={`sfconnect-message sfconnect-chat-card ${
            message.messageSender === "customer" ? "customer" : "agent"
          }`}>
          {message.messageSender === "customer" && (
            <div className="initial-section" aria-hidden="true">
              <span>{customerInitials}</span>
            </div>
          )}
          <div className="message">
            <div className="-oneX-row -oneX-no-gutters message-info info-area">
              <div className="-oneX-col-8 -oneX-col-md-9 message-number">
                {message.messageSenderName}
              </div>
              <div className="-oneX-col-4 -oneX-col-md-3 message-date">
                {message.messageDate}
              </div>
              <div className="-oneX-col-4 -oneX-offset-8 message-time">
                {message.messageTime}
              </div>
            </div>
            <div className="-oneX-row message-container">
              <div className="-oneX-row message-body">{message.message}</div>
              {message.attachments &&
                message.attachments.map((attachment, attachmentIndex) => (
                  <Attachment
                    key={attachmentIndex}
                    message={message}
                    index={this.props.index}
                    attachmentIndex={attachmentIndex}
                    attachment={attachment}
                    attachmentAlignment={
                      message.messageSender === "customer" ? "right" : "left"
                    }
                  />
                ))}
            </div>
          </div>
          {message.messageSender === "agent" && (
            <div className="initial-section" aria-hidden="true">
              <span>SF</span>
            </div>
          )}
        </div>
      </section>
    );
  }
}
