import React, { Component } from "react";
import { emoji } from "components/Message/emojis-list";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { mainAction } from "../../redux/actions/interactionsActions";

class Emoji extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: "",
    };
  }

  onClickHandler = (event) => {
    const _emoji = event.target.innerHTML;
    const _messageBody =
      this.props.messageBody === undefined ? "" : this.props.messageBody;
    this.toggleContainer(event);
    if (event.target.className !== "hide-offscreen") {
      const { cursorPos } = this.props.textMessageBox;
      const _startMessage = _messageBody.slice(0, cursorPos.start);
      const _endMessage = _messageBody.slice(cursorPos.end);
      this.props.actions.mainAction(
        "UPDATE_CHAT",
        _startMessage + _emoji + _endMessage
      );
      const _textArea = document.getElementById("conTA");
      _textArea.focus();
    } else {
      this.refs.emojiButton.focus();
    }
  };

  toggleContainer = (event) => {
    const _currentState = this.state.active;
    this.setState({ active: _currentState === "active" ? "" : "active" });
    setTimeout(() => {
      _currentState === "active"
        ? this.refs.emojiButton.focus()
        : this.refs.popover.focus();
    }, 500);
  };

  keyPressed = (event) => {
    if (event.key === "Enter") {
      this.onClickHandler(event);
    }
    if (/[E|e][S|s][C|c]/.test(event.key)) {
      this.toggleContainer(event);
    }
  };

  render() {
    const { navClass } = this.props;
    return (
      <>
        <div className="emoji-container">
          <div className="emoji-panel">
            <button
              ref={"emojiButton"}
              id="emoji-picker"
              className={
                "-oneX-btn-small -oneX-btn-secondary chat-input-tool " +
                navClass +
                " " +
                this.state.active
              }
              onClick={this.toggleContainer}
              title={"Select Emoji " + this.state.active}
            ></button>
          </div>
          <div
            ref={"popover"}
            className={
              "sfconnect-composer-popover sfconnect-composer-emoji-popover " +
              this.state.active
            }
            tabIndex="0"
            onKeyDown={this.keyPressed}
          >
            <div className="sfconnect-emoji-picker">
              {/* <div className="sfconnect-composer-popover-header"" >
                                <input className="sfconnect-composer-popover-input" placeholder="Search" value="" />
                            </div> */}
              <div className="sfconnect-composer-popover-body-container">
                <div className="sfconnect-composer-popover-body">
                  {emoji.list.map((list, i) => {
                    return (
                      <>
                        {list.enabled ? (
                          <div
                            key={`list_` + i}
                            className="sfconnect-emoji-picker-groups"
                            role="list"
                          >
                            <div
                              className="sfconnect-emoji-picker-group"
                              role="listitem"
                            >
                              <div
                                className={
                                  "sfconnect-emoji-picker-group-title " +
                                  list.type
                                }
                              >
                                {list.section}
                              </div>
                              {list.items.map((emoji, i) => {
                                return (
                                  <span
                                    key={`emoji_` + i}
                                    className="sfconnect-emoji-picker-emoji"
                                    role="img"
                                    tabIndex="0"
                                    title={emoji.title}
                                    onClick={this.onClickHandler}
                                    onKeyDown={this.keyPressed}
                                  >
                                    {emoji.image}
                                  </span>
                                );
                              })}
                              <span
                                className="hide-offscreen"
                                tabIndex="0"
                                onKeyPress={this.keyPressed}
                              >
                                End of List - Close Dialog
                              </span>
                            </div>
                          </div>
                        ) : null}
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="intercom-composer-popover-caret"></div>
          </div>
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    messageBody: state.interactionsReducer.currentInteraction.messageBody,
    textMessageBox: state.interactionsReducer.textMessageBox,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        mainAction,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Emoji);
