import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { mainAction } from "../../redux/actions/interactionsActions";
import { CANCEL_CURRENT_DRAFT } from "redux/actions/types";

class Cancel extends Component {
  cancelMessage(interactionId) {
    this.props.actions.mainAction(CANCEL_CURRENT_DRAFT, interactionId);
  }

  render() {
    const { interactionId } = this.props;
    return (
      <>
        {/* <div className="-oneX-col-4"> */}
          <div className="-sfc-cancel-button" title="click to cancel this draft">
            <button
              className="-oneX-btn-secondary -oneX-btn-small"
              onClick={(e) => this.cancelMessage(interactionId)}
            >Cancel</button>
          </div>
        {/* </div> */}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.authReducer.token,
    interactionsReducer: state.interactionsReducer,
    userInfoReducer: state.userInfoReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        mainAction,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Cancel);
