import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { CLEAR_CURRENT_INTERACTION } from "redux/actions/types";
import { mainAction } from "../redux/actions/interactionsActions";
import { sfc_routes } from "../utils/general";

class Breadcrumb extends Component {
  divStyle = {
    display: "inline-block",
  };

  iconStyle = {
    height: "24px",
    width: "24px",
    transform: "rotate(90deg)",
  };

  componentDidMount() {
    if (window.oneX) {
      window.oneX.addElement(this.refs.goBack);
    }
  }

  onClickContinue = (event) => {
    if (event.key === "Enter" || event.key === " " || event.type === "click") {
      const _public_root = sfc_routes.pages.home;
      const _search_route = sfc_routes.pages.search;
      if (this.props.history.location.pathname === _public_root) {
        this.props.actions.mainAction(CLEAR_CURRENT_INTERACTION, null);
      } else if (this.props.history.location.pathname === _search_route) {
        this.props.actions.mainAction(CLEAR_CURRENT_INTERACTION, null);
        this.props.history.goBack();
      } else {
        this.props.history.goBack();
      }
    }
  };
  render() {
    return (
      <>
        <div className="-oneX-row">
          <div className="-oneX-col">
            <div className="bread-crumb">
              <div
                ref={"goBack"}
                tabIndex="-1"
                className="-oneX-icon-container -sfc-oneX-overide"
                style={this.divStyle}
              >
                <div
                  className="-oneX-icon"
                  data-icon="web_ui_chevron"
                  style={this.iconStyle}
                ></div>
              </div>
              <span
                role="link"
                tabIndex="0"
                className="title"
                onClick={(event) => this.onClickContinue(event)}
                onKeyPress={(event) => this.onClickContinue(event)}
                title={"Return to " + this.props.title}
              >
                {this.props.title}
              </span>
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        mainAction,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Breadcrumb);
