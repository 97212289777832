import React from "react";
// import $ from 'jquery';

const NoMatchFound = () => {
  return (
    <>
      <div id="noMatchFound" className="-oneX-container center-container">
        <div className="-oneX-row">
          <div className="-oneX-col -oneX-heading--h3">
            No Match Found.<br />Please confirm your search criteria and try again.
          </div>
        </div>          
      </div>        
    </>
  );
};

export default NoMatchFound;
