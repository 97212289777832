import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { patchUpdateCustomerPreferences } from "../../redux/actions/customerSearchAction";
import Toggle from "../../components/OfficeSettings/Toggle";
import Notification from "../../components/Notification";
import Modal from "./Modal";
import DOMPurify from "dompurify";
import {
  mainAction,
  fetchMessages,
  patchUpdateInteraction,
  logUIErrors,
} from "../../redux/actions/interactionsActions";
import {
  CREATE_NEW_INTERACTION,
  STORE_CUSTOMER_INFO_FOR_PROSPECT_RESOLUTION,
  UPDATE_CLIENT_TYPE_FOR_MULTIMATCH,
  CLEAR_FILTERS,
} from "../../redux/actions/types.js";
import { hashCode, sfc_routes } from "../../utils/general.js";

class CustomerSearchRow extends Component {
  divStyle = {
    width: "auto",
  };

  iconStyle = {
    height: "24px",
    width: "24px",
  };

  constructor(props) {
    super(props);
    this.state = {
      active: "",
    };
    this.onClickSendText = this.onClickSendText.bind(this);
    this.onClickSetDoNotText = this.onClickSetDoNotText.bind(this);
    this.onClickConfirmationBox = this.onClickConfirmationBox.bind(this);
  }

  componentDidMount() {
    if (window.oneX) {
      window.oneX.addElement(this.refs.newMessage);
      window.oneX.addElement(this.refs.dnt);
    }

    setTimeout(function () {
      document
        .querySelectorAll("svg title")
        .forEach((element) => (element.outerHTML = ""));
      document
        .querySelectorAll("svg")
        .forEach((element) => element.removeAttribute("id"));
    }, 500);
  }

  filterClientInteractions(_clientData) {
    return (
      _clientData.interactions.filter(
        (interaction) =>
          interaction.clientType !== "prospect" &&
          interaction.unmaskedPhoneNumber ===
            _clientData.customerDetails.firstTextableMobilePhone &&
          interaction.clientId !== _clientData.customerDetails.clientID &&
          interaction.interactionId !== "tempInteractionId"
      ).length > 0
    );
  }

  filterProspectInteractions(_clientData) {
    return (
      _clientData.interactions.filter(
        (interaction) =>
          interaction.clientType === "prospect" &&
          interaction.unmaskedPhoneNumber ===
            _clientData.customerDetails.firstTextableMobilePhone &&
          interaction.interactionId !== "tempInteractionId"
      ).length > 0
    );
  }

  onClickSendText = (_clientData) => (event) => {
    //existing interaction - condition for existing client
    this.props.actions.mainAction(CLEAR_FILTERS);
    if (
      _clientData.interactions.filter(
        (interaction) =>
          interaction.clientId === _clientData.customerDetails.clientID
      ).length > 0
    ) {
      let interactionId = _clientData.interactions.filter(
        (interaction) =>
          interaction.clientId === _clientData.customerDetails.clientID
      )[0].interactionId;
      this.props.actions.fetchMessages(
        interactionId,
        _clientData.token,
        _clientData.officeAssociateId,
        _clientData.customerDetails.clientID,
        _clientData.currentOfficeMembers,
        true
      );
    }
    //existing interaction condition - same phonenumber but different client
    else if (this.filterClientInteractions(_clientData)) {
      let oldCustomerFilter = _clientData.interactions.filter(
        (interaction) =>
          interaction.unmaskedPhoneNumber ===
            _clientData.customerDetails.firstTextableMobilePhone &&
          interaction.clientId !== _clientData.customerDetails.clientID
      );

      let interactionId = oldCustomerFilter[0].interactionId;
      let oldClientId = oldCustomerFilter[0].clientId;
      let newClientId = _clientData.customerDetails.clientID;

      this.props.actions.fetchMessages(
        interactionId,
        _clientData.token,
        _clientData.officeAssociateId,
        oldClientId,
        _clientData.currentOfficeMembers
      );
      this.props.actions.patchUpdateInteraction(
        _clientData.token,
        _clientData.officeAssociateId,
        newClientId,
        oldClientId,
        interactionId,
        "client",
        _clientData.customerDetails,
        this.props.actions.mainAction(
          UPDATE_CLIENT_TYPE_FOR_MULTIMATCH,
          newClientId
        )
      );
    }
    //existing interaction - condition for prospect in in-tray having a matching phone number to the picked customer in customer search
    else if (this.filterProspectInteractions(_clientData)) {
      let interactionId = _clientData.interactions.filter(
        (interaction) =>
          interaction.unmaskedPhoneNumber ===
          _clientData.customerDetails.firstTextableMobilePhone
      )[0].interactionId;
      let prospectClientId = _clientData.interactions.filter(
        (interaction) =>
          interaction.unmaskedPhoneNumber ===
          _clientData.customerDetails.firstTextableMobilePhone
      )[0].clientId;
      this.props.actions.fetchMessages(
        interactionId,
        _clientData.token,
        _clientData.officeAssociateId,
        prospectClientId,
        _clientData.currentOfficeMembers
      );
      this.props.actions.mainAction(
        STORE_CUSTOMER_INFO_FOR_PROSPECT_RESOLUTION,
        _clientData.customerDetails
      );
      this.props.actions.patchUpdateInteraction(
        this.props.token,
        this.props.officeAssociateId,
        _clientData.customerDetails.clientID,
        prospectClientId,
        interactionId,
        "prospect"
      );
    }
    //new interaction
    else {
      let newInteractionData = {
        officeAssociateId: _clientData.officeAssociateId,
        customerDetails: _clientData.customerDetails,
        loggedInAssociateId: _clientData.loggedInAssociateId,
        loggedInAssociateName: _clientData.loggedInAssociateName,
      };
      this.props.actions.mainAction(CREATE_NEW_INTERACTION, newInteractionData);
    }

    this.props.history.push(sfc_routes.pages.home);
  };

  onClickSetDoNotText = (_clientData) => (event) => {
    window.oneX.Modal.showModal(
      this,
      "oneX-modal-do-not-text-" + _clientData.hashedClientId
    );
  };
  onClickConfirmationBox = (clientData, confirmationResult) => {
    window.oneX.Modal.hideModal(
      "oneX-modal-do-not-text-" + clientData.hashedClientId
    );
    if (confirmationResult === "true") {
      this.props.actions.patchUpdateCustomerPreferences(
        clientData.token,
        clientData.officeAssociateId,
        clientData.customerDetails.clientID,
        true
      );
    }
  };

  //utility functon to santize data and handle XSS vulnerability
  sanitizedData = (value) => {
    return value.replace(/[&<>"'`]/g, (char) => {
      switch (char) {
        case "&":
          return "&amp;";
        case "<":
          return "&lt;";
        case ">":
          return "&gt;";
        case '"':
          return "&quot;";
        case "'":
          return "&#x27;";
        case "`":
          return "&#x60;";
        default:
          return char;
      }
    });
  };

  textStatusDiscription = (textStatus) => {
    return textStatus === "D"
      ? "Do Not Text"
      : textStatus === "N"
      ? "No Phone Available"
      : textStatus === "C"
      ? "CASL"
      : "";
  };

  render() {
    const _doNotText = this.props.textStatus !== "";
    //sanitizing token for handle DOM XSS vulnerability
    const sanitizedToken = this.sanitizedData(this.props.token);
    //sanitizing office associate Id for handle DOM XSS vulnerability
    const sanitizedOfficeAssociateId = this.sanitizedData(
      this.props.officeAssociateId
    );
    const _clientData = {
      token: sanitizedToken,
      officeAssociateId: sanitizedOfficeAssociateId,
      customerDetails: this.props,
      hashedClientId: hashCode(this.props.clientID),
      interactions: this.props.interactionsReducer.interactions,
      loggedInAssociateId: this.props.userInfoReducer.loggedInAssociateId,
      loggedInAssociateName: this.props.userInfoReducer.loggedInAssociateName,
      currentOfficeMembers: this.props.userInfoReducer.currentOfficeMembers,
    };

    const sanitizedHashedClientId = DOMPurify.sanitize(
      _clientData.hashedClientId
    );

    return (
      <>
        <div className="-oneX-tbody">
          <div className="-oneX-tr" role="row">
            <div className="-oneX-th--row" role="rowheader">
              <span className="-oneX-heading--h5 -sfc-displayed-name">
                {this.props.displayedName}
                {this.props.protectedPersonIndicator && (
                  <span className="-oneX-labels associate-initial-section protected-person-label">
                    Protected Person
                  </span>
                )}
              </span>
              <br />
              {this.props.address}
              <br />
              {this.props.city}, {this.props.state}
              <br />
              {this.props.displayedPhone ? this.props.displayedPhone : null}
              {this.props.textStatus && (
                <Notification type="informational" inPage="true">
                  {this.textStatusDiscription(this.props.textStatus)}
                </Notification>
              )}
            </div>
            <div className="-oneX-td" role="cell">
              <div className="-oneX-row">
                <div className="-oneX-col-6 -oneX-offset-2 -oneX-col-sm-2 -oneX-offset-sm-2">
                  <button
                    className="-oneX-btn -oneX-btn-primary"
                    id={"sendNewText" + sanitizedHashedClientId}
                    title={
                      "Send Message to " +
                      this.props.displayedName +
                      (_doNotText ? "disabled" : "")
                    }
                    onClick={this.onClickSendText(_clientData)}
                    style={this.divStyle}
                    disabled={_doNotText ? true : false}>
                    <div
                      ref={"newMessage"}
                      tabIndex="-1"
                      className="-oneX-icon-container">
                      <div
                        className="-oneX-icon"
                        data-icon="generaluse_chat_text-message"
                        style={this.iconStyle}>
                        {" "}
                      </div>
                    </div>
                  </button>
                </div>
                <div className="-oneX-col-8 -oneX-offset-2 -oneX-col-sm-3 -oneX-offset-sm-4 -oneX-col-md-2 -oneX-offset-md-2 -oneX-col-lg-2 -oneX-offset-lg-1  slider-row">
                  <Toggle
                    id={"doNotText_" + sanitizedHashedClientId}
                    name="Do Not Text"
                    checked={_doNotText}
                    disabled={_doNotText}
                    onChange={this.onClickSetDoNotText(_clientData)}></Toggle>
                  <Modal
                    key={sanitizedHashedClientId}
                    clientData={_clientData}
                    callback={this.onClickConfirmationBox}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.authReducer.token,
    userInfoReducer: state.userInfoReducer,
    officeAssociateId: state.userInfoReducer.officeAssociateId,
    interactionsReducer: state.interactionsReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        patchUpdateCustomerPreferences,
        mainAction,
        fetchMessages,
        patchUpdateInteraction,
        logUIErrors,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerSearchRow);
