import { SET_WEBSOCKET } from "../actions/types";
const initialState = {
  webSocket: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_WEBSOCKET: {
      return {
        ...state,
        webSocket: action.payload.socket,
        readyState:
          action.payload.socket === null
            ? action.payload.readyState
            : action.payload.socket.readyState,
      };
    }

    default:
      return state;
  }
}
