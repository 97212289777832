export const tracks = {
  list: [
    {
      type: "appointment",
      title: "Appointment",
      enabled: true,
      items: [
        {
          title: "Appointment Confirmation",
          track:
            "We are looking forward to seeing you on [date] at [time]. Please reply “Y” to confirm. Thanks for choosing State Farm®. Text STOP to end texting.",
        },
        {
          title: "Appointment Confirmation <date>",
          track:
            "This is your State Farm® agent confirming your appointment with [name] on [date]. We look forward to meeting with you. Text STOP to end texting",
        },
      ],
    },
    {
      type: "claims",
      title: "Claims",
      enabled: true,
      items: [
        {
          title: "Weather Event – Reporting a Claim",
          track:
            "To submit a weather related claim, please use our mobile app http://st8.fm/clm, visit statefarm.com/claims, or call 1800SFCLAIM. Text STOP to cancel.",
        },
        {
          title: "Contact Card - Auto",
          track:
            "You don't want to miss a call from State Farm® Auto Claims! Click http://st8.fm/AutoCC to automatically update your caller ID. Text STOP to cancel.",
        },
        {
          title: "Contact Card - Fire",
          track:
            "You don't want to miss a call from State Farm® Property Claims! Click http://st8.fm/PropertyCC to automatically update your caller ID. Text STOP to cancel.",
        },
        {
          title: "Existing Claim Conversation",
          track:
            "Thanks for speaking with us today about your  State Farm® claim. For claim updates, download the mobile app http://st8.fm/clm. Text STOP to cancel.",
        },
        {
          title: "File a Claim Link",
          track:
            "File a claim here: http://st8.fm/start-claim. Choose a repair shop/rental car on statefarm.com or on the State Farm® mobile app. Text STOP to cancel.",
        },
        {
          title: "Link to Claim Help",
          track:
            "Here is the link for additional claim info: http://st8.fm/ClaimHelp. Thank you for choosing State Farm®. Text STOP to cancel.",
        },
        {
          title: "New Claim Filed Through Agent",
          track:
            "Thanks for letting us help file your claim. Did you know claims can also be filed through our State Farm® mobile app http://st8.fm/clm? Text STOP to cancel.",
        },
        {
          title: "New Claim Filed",
          track:
            "We see you had to file a claim. We are here to help. You can track your claim through our State Farm® Mobile app: http://st8.fm/clm. Text STOP to cancel.",
        },
      ],
    },
    {
      type: "cancellation",
      title: "Cancellation",
      enabled: true,
      items: [
        {
          title: "Please give us a call...",
          track:
            "Please give us a call regarding your cancellation request at <office phone#>. Thanks for choosing State Farm®. Text STOP to cancel.",
        },
      ],
    },
    {
      type: "follow-up",
      title: "Conversation Follow-ups",
      enabled: true,
      items: [
        {
          title: "Signed Written Consent",
          track:
            "Hi [consumer name], reply YES to give consent for me to email/call/text about State Farm ® products starting [xx/xx/xx]. A reply is your electronic signature.",
        },
        {
          title: "Mobile App Policy Info",
          track:
            "REMINDER: You can have instant access to your State Farm® policy information and bill summaries by downloading our app http://st8.fm/clm. Text STOP to cancel.",
        },
        {
          title: "Teen Driver",
          track:
            "Thanks for letting us help protect your teen driver. Get them ready with access to ID cards, ERS, and Claims with our http://st8.fm/clm. Text STOP to cancel.",
        },
      ],
    },
    {
      type: "customer-welcome",
      title: "Customer Welcome",
      enabled: true,
      items: [
        {
          title: "Download State Farm Mobile",
          track:
            "To download our State Farm® mobile app, click http://st8.fm/clm. Thank you for choosing State Farm.  Text STOP to cancel.",
        },
        {
          title: "New Customer Welcome",
          track:
            "Welcome to State Farm®. We appreciate your business. Text or call us anytime at <office phone#>. Thanks for choosing State Farm®. Text STOP to cancel.",
        },
      ],
    },
    {
      type: "documents-needed",
      title: "Document(s) Needed",
      enabled: true,
      items: [
        {
          title: "Send needed non-signature document (Consider due date)",
          track:
            "Please send <Name of Document> to update your policy. Please text a photo of it, fax to XXX-XXX-XXXX, or email to @statefarm.com only. Text STOP to cancel.",
        },
        {
          title: "Send needed signature document (Consider due date)",
          track:
            "Please send <Name of Document> to update your policy. You can fax to XXX-XXX-XXXX, or email to @statefarm.com only. Text STOP to cancel.",
        },
      ],
    },
    {
      type: "drive-saf-save",
      title: "Drive Safe & Save",
      enabled: true,
      items: [
        {
          title: "App Not Sending Data",
          track:
            "Your Drive Safe & Save™ app is not sending a signal. Log in and follow any in-app prompts. Still not working? Call 888-559-1922 for help. Text STOP to cancel.",
        },
        {
          title: "App Not Sending Data & Information Video",
          track:
            "Your Drive Safe & Save™ app is not sending a signal. Check out this video for information and tips to fix it: http://st8.fm/DSSsettings. Text STOP to cancel.",
        },
        {
          title: "Discount Offer",
          track:
            "I found a discount for you! Call our State Farm® office at <office phone#> or visit www.drivesafeandsave.com to learn more. Text STOP to cancel.",
        },
        {
          title: "Setup - Download App",
          track:
            "To keep your Drive Safe & Save™ discount, please complete setup in the app:  http://st8.fm/DSSsetup. Call 888-559-1922 for help. Text STOP to cancel.",
        },

        {
          title: "Odometer Reading Request",
          track:
            "Please submit your odometer reading in the Drive Safe & Save™ app at http://st8.fm/DSSmileage so we can calculate your discount. Text STOP to cancel.",
        },
        {
          title: "Setup Window Expired",
          track:
            "The Drive Safe & Save setup window expired and your discount was removed. If you’d like, you can re-enroll in the app: https://st8.fm/reenroll. Text STOP to end.",
        },
      ],
    },
    {
      type: "ers",
      title: "ERS",
      enabled: true,
      items: [
        {
          title: "ERS Mobile App Link",
          track:
            "We're happy to help with roadside assistance. You can also request assistance in the State Farm® Mobile App http://st8.fm/clm. Text STOP to cancel.",
        },
      ],
    },
    {
      type: "id-card",
      title: "ID Card",
      enabled: true,
      items: [
        {
          title: "ID Card Sent",
          track:
            "Your ID card has been sent. For immediate access to your digital ID card, download the State Farm® mobile app http://st8.fm/clm. Text STOP to cancel.",
        },
      ],
    },
    {
      type: "agent-digitalcard",
      title: "Agent Digital Contact Card",
      enabled: true,
      items: [
        {
          title: "Agent Contact Card Sent",
          track:
            "Your State Farm® Agent's Digital Contact Card has been sent. Please add to your Contacts to make texting with us easier! Text STOP to cancel.",
        },
      ],
    },
    {
      type: "online-reviews",
      title: "Online Reviews",
      enabled: true,
      items: [
        {
          title: "Review on Facebook",
          track:
            "Thank you for agreeing to provide our office a review on Facebook. Text STOP to cancel. (insert Facebook short link)",
        },
        {
          title: "Review on Google",
          track:
            "Thank you for agreeing to provide our office a review on Google. Text STOP to cancel. (insert Google short link)",
        },
        {
          title: "Review on Yelp",
          track:
            "Thank you for agreeing to provide our office a review on Yelp. Text STOP to cancel. (insert Yelp short link)",
        },
      ],
    },
    {
      type: "payment",
      title: "Payment",
      enabled: true,
      items: [
        {
          title: "Auto-Pay Did Not go through",
          track:
            "Hello, your payment transaction has not been completed. Please call us at <office phone#>. Thanks for choosing State Farm®. Text STOP to cancel.",
        },
        {
          title: "Payment Mobile App Link",
          track:
            "Thanks for calling in your payment. If ever you can’t reach me, pay easily via our State Farm® mobile app http://st8.fm/sfpay. Text STOP to cancel.",
        },
        {
          title: "Payment Past Due (call first, text as follow-up)",
          track:
            "Hi your payment of <$ amount> is past due. To pay, please click http://st8.fm/sfpay or call <office phone#>. Thanks for choosing State Farm®. Text STOP to cancel.",
        },
        {
          title: "SFPP No Paperless Preference",
          track:
            "Signing up for Paperless options is easy. To sign up, please text C to confirm, or call my office at <office phone#>. Text STOP to cancel.",
        },
        {
          title: "State Farm Payment Notice",
          track:
            "State Farm® Payment Notice: <Amount> is due <date>. To pay, please click here: http://st8.fm/sfpay or call <office phone#>. Text STOP to cancel.",
        },
        {
          title: "Auto/Fire Policies",
          track:
            "Your State Farm® Auto/Fire policies will have a new billing account but your Life/Health policies will not change. Please reply to discuss. Text STOP to cancel.",
        },
        {
          title: "Billing Account Changes",
          track:
            "I've been unable to contact you regarding your billing account changes. Please contact my State Farm® office at <office phone#> for more info. Text STOP to cancel.",
        },
        {
          title: "Bill Delivery Time",
          track:
            "Don’t wait for paper bills – choose a new way to get your State Farm bill and pay today! View options at http://st8.fm/payfaster Text STOP to cancel.",
        },
      ],
    },
    {
      type: "policy",
      title: "Policy",
      enabled: true,
      items: [
        {
          title: "Policy Review - Life Changes",
          track:
            "Life changes so fast! We would love to connect with you about your upcoming policy renewal. Just reply or click <office phone#> to connect! Text STOP to end.",
        },
        {
          title: "Policy Review",
          track:
            "Hi [Customer], it looks like your State Farm® policy is renewing. Let’s connect for a policy review. Just reply or call <office phone#> to discuss! Text STOP to end.",
        },
        {
          title: "Life Policy Beneficiary Review",
          track:
            "Hi [Customer], your State Farm® life insurance had another anniversary! Just reply or call <office phone#> to discuss updates to your beneficiary or policy. Text STOP to end",
        },
        {
          title: "Policies Currently Canceled",
          track:
            "Your State Farm® <policy(ies)> canceled and needs a payment for possible reinstatement. Please call us at <office phone#>. Text STOP to cancel.",
        },
        {
          title: "Policy Change Request",
          track:
            "We need information on a recent policy change. Please call us now at <office phone#>. Thank you for choosing State Farm®. Text STOP to cancel.",
        },
        {
          title: "Policy Renewal Premium Decrease",
          track:
            "The policy for the <vehicle type> has renewed, and your premium decreased by <insert savings>. Thanks for being a great State Farm® customer! Text STOP to cancel.",
        },
        {
          title: "Policy Renewal",
          track:
            "Hi <Customer>, your State Farm® auto policy is renewing and you may see changes on your bill. Just reply or click <office phone#> to discuss! Text STOP to end",
        },
        {
          title: "Policy Renewal - Home/Renters",
          track:
            "Hi <Customer>, your State Farm® [homeowners or renters] policy is renewing and you may see changes on your bill. Just reply or call <office phone#> to discuss! Text STOP to end.",
        },
      ],
    },
    {
      type: "completed-quotes",
      title: "Quotes - Complete",
      enabled: true,
      items: [
        {
          title: "Follow up",
          track:
            "I'm following up on the State Farm® quote I sent you. Let's start your policy today. Please contact us at <office phone#>. Text STOP to cancel.",
        },
        {
          title: "Internet Leads",
          track:
            "Thank you for your interest in State Farm®. I have a personalized quote ready for you. Would you like me to call or email you? Text STOP to cancel.",
        },
        {
          title: "statefarm.com® prospects",
          track:
            "Recently, you obtained an <policy type (Auto, Home, Renters)> quote on statefarm.com®. Would you like me to call or email you? Text STOP to cancel.",
        },
        {
          title: "Verify information",
          track:
            "Thank you for your interest in State Farm®. I have a personalized quote ready for you. Would you like me to call or email you? Text STOP to cancel.",
        },
        {
          title: "Attach Quote",
          track:
            "It's READY!! I'm attaching your quote and my contact information. I'll call you from <office phone#> to go over the details! Text STOP to cancel.",
        },
        {
          title: "Quote Bundling",
          track:
            "Thanks for your interest in State Farm®. Your Auto quote is ready. You might save by combining with a home. Could I call to discuss? Text STOP to cancel.",
        },
      ],
    },
    {
      type: "Incomplete Quotes",
      title: "Quotes - Incomplete",
      enabled: true,
      items: [
        {
          title: "Need additional information - 2",
          track:
            "Thank you for your interest in State Farm®. I need more information to provide an accurate quote. May I call or email you? Text STOP to cancel.",
        },
        {
          title: "Quote In Progress",
          track:
            "Thanks for your interest in State Farm®! We are working on your quote now! Text STOP to cancel.",
        },
        {
          title: "Quote In Progress Bundling",
          track:
            "Thanks for your interest in State Farm®! We are working on your Auto quote! The premium may reduce if you add a home. May I call to discuss? Text STOP to cancel.",
        },
      ],
    },
  ],
};
