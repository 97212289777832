import React, { Component } from "react";
import Cancel from "./Cancel";

class Archive extends Component {
  render() {
    const { interactionId, virtualAssistantActive } = this.props;
    return (
      <>
        {virtualAssistantActive === true ? (
          ""
        ) : interactionId === "tempInteractionId" ? (
          <Cancel interactionId={interactionId} />
        ) : (
            <div className="-sfc-archive-button -oneX-col-3" title="click to archive this interaction">
              <button
                className="-oneX-btn-secondary -oneX-btn-small"
                onClick={(event) =>
                  window.oneX.Modal.showModal(
                    event.target,
                    "oneX-modal-archive-" + interactionId
                  )
                }
              >Archive</button>
            </div> 
        )}
      </>
    );
  }
}

export default Archive;
