import axios from "axios";
import {
  FETCH_CUSTOMERS_REQUEST,
  FETCH_CUSTOMERS_SUCCESS,
  FETCH_CUSTOMERS_ERROR,
  PATCH_UPDATE_CUSTOMER_PREFERENCES,
  GET_CUSTOMER_INFO,
  STORE_CUSTOMER_INFO_FOR_PROSPECT_RESOLUTION,
} from "./types";
import { logUIErrors } from "./interactionsActions";

export const fetchCustomersByName =
  (token, agentId, lastName, exactMatch, firstName) => (dispatch) => {
    dispatch({ type: FETCH_CUSTOMERS_REQUEST });
    if (!exactMatch) {
      lastName = lastName + "*";
    }

    axios
      .get(
        process.env.REACT_APP_AWS_ROOT_URL +
          "/api/clients?lastName=" +
          lastName +
          "&firstName=" +
          firstName +
          "*",
        {
          headers: {
            "X-CONSUMER_KEY": "SFCONNECT_UI",
            Authorization: `Bearer ${token}`,
            selectedAgentId: agentId,
          },
        }
      )
      .then((response) =>
        dispatch({ type: FETCH_CUSTOMERS_SUCCESS, payload: response.data })
      )
      .catch((error) => {
        dispatch({ type: FETCH_CUSTOMERS_ERROR, error });
        dispatch(logUIErrors(agentId, error, "Fetch Customers by Name", true));
      });
  };

export const fetchCustomersByPhoneNumber =
  (token, agentId, phoneNumber) => (dispatch) => {
    dispatch({ type: FETCH_CUSTOMERS_REQUEST });

    axios
      .get(
        process.env.REACT_APP_AWS_ROOT_URL +
          "/api/clients?phone=" +
          phoneNumber,
        {
          headers: {
            "X-CONSUMER_KEY": "SFCONNECT_UI",
            Authorization: `Bearer ${token}`,
            selectedAgentId: agentId,
          },
        }
      )
      .then((response) =>
        dispatch({ type: FETCH_CUSTOMERS_SUCCESS, payload: response.data })
      )
      .catch((error) => {
        dispatch({ type: FETCH_CUSTOMERS_ERROR, error });
        dispatch(logUIErrors(agentId, error, "Fetch Customers by Phone", true));
      });
  };

export const clearCustomerRows = () => {
  return {
    type: "CLEAR_CUSTOMERS",
  };
};

export const patchUpdateCustomerPreferences =
  (token, agentId, clientId, doNotTextValue) => (dispatch) => {
    axios
      .patch(
        process.env.REACT_APP_AWS_ROOT_URL +
          `/api/clients/${clientId}/preferences?channel=mms`,
        {
          donotText: doNotTextValue,
          virtualAssistOptedIn: false,
        },
        {
          headers: {
            "X-CONSUMER_KEY": "SFCONNECT_UI",
            Authorization: `Bearer ${token}`,
            selectedAgentId: agentId,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) =>
        dispatch({
          type: PATCH_UPDATE_CUSTOMER_PREFERENCES,
          payload: response.data,
        })
      )
      .catch((error) => {
        dispatch(
          logUIErrors(agentId, error, "Patch Update Customer preferences", true)
        );
      });
  };

export const getCustomerInfo =
  (token, clientId, agentId, isProspect) => (dispatch) => {
    axios
      .get(process.env.REACT_APP_AWS_ROOT_URL + `/api/clients/${clientId}`, {
        headers: {
          "X-CONSUMER_KEY": "SFCONNECT_UI",
          Authorization: `Bearer ${token}`,
          selectedAgentID: agentId,
        },
      })
      .then((response) => {
        if (!isProspect) {
          dispatch({ type: GET_CUSTOMER_INFO, payload: response.data });
        } else {
          dispatch({
            type: STORE_CUSTOMER_INFO_FOR_PROSPECT_RESOLUTION,
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        dispatch(logUIErrors(agentId, error, "Get Customer information", true));
      });
  };
