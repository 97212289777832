import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Notification from "../components/Notification";
import { clearCustomerRows } from "redux/actions/customerSearchAction";
import { mainAction } from "redux/actions/interactionsActions";

const EmptyState = (props) => {
  const [prospectMultiMatchCount, setProspectMultiMatchCount] = useState(0);
  const tabObject = useRef();
  const iconGearObject = useRef();

  useEffect(() => {
    let _prospectMultiMatchCount = 0;
    if (props.interactionsReducer.interactions.length > 0) {
      props.interactionsReducer.interactions.map((interaction, index) => {
        _prospectMultiMatchCount +=
          interaction.customerName === interaction.phoneNumber
            ? Number("1")
            : Number("0");
        return _prospectMultiMatchCount;
      });
    }
    setProspectMultiMatchCount(_prospectMultiMatchCount);
  }, [props.interactionsReducer.interactions]);
  useLayoutEffect(() => {
    window.oneX.addElement(iconGearObject.current);
    window.oneX.addElement(tabObject.current);
    return () => {
      window.oneX.removeElement(iconGearObject);
      window.oneX.removeElement(tabObject);
    };
  }, []);

  return (
    <>
      <section id="empty-state">
        <div className="-oneX-container">
          <div className="-oneX-row">
            <div
              ref={tabObject}
              data-type="-oneX-tab-module-container"
              className="-oneX-col-8">
              <p className="-sfc-main-heading -oneX-heading--h4">
                Helpful Information
              </p>
              <div
                className="-oneX-tabs-container -oneX-tabs-mid-page"
                id="mid-page-tab">
                <div className="-oneX-tabs-scroller">
                  <ul
                    role="tablist"
                    aria-orientation="horizontal"
                    aria-label="help_and_usage"
                    className="-oneX-tabs-list">
                    <li role="presentation" className="-oneX-tabs-item">
                      <button
                        role="tab"
                        aria-selected="true"
                        tabIndex="0"
                        aria-controls="get-started-tab"
                        className="-oneX-tabs-button">
                        {" "}
                        Get Started
                      </button>
                    </li>
                    <li role="presentation" className="-oneX-tabs-item">
                      <button
                        role="tab"
                        aria-selected="true"
                        tabIndex="0"
                        aria-controls="coming-soon-tab"
                        className="-oneX-tabs-button">
                        {" "}
                        Coming Soon
                      </button>
                    </li>
                    <li role="presentation" className="-oneX-tabs-item">
                      <button
                        role="tab"
                        aria-selected="false"
                        tabIndex="-1"
                        aria-controls="archiving-tab"
                        className="-oneX-tabs-button">
                        Archiving
                      </button>
                    </li>
                    <li role="presentation" className="-oneX-tabs-item">
                      <button
                        role="tab"
                        aria-selected="false"
                        tabIndex="-1"
                        aria-controls="attachments-tab"
                        className="-oneX-tabs-button">
                        Attachments
                      </button>
                    </li>
                    <li role="presentation" className="-oneX-tabs-item">
                      <button
                        role="tab"
                        aria-selected="false"
                        tabIndex="-1"
                        aria-controls="general-help-tab"
                        className="-oneX-tabs-button">
                        Help
                      </button>
                    </li>
                    <li role="presentation"></li>
                  </ul>
                </div>
                <div
                  className="-oneX-tabs-panel"
                  id="get-started-tab"
                  tabIndex="0"
                  role="tabpanel"
                  aria-labelledby="start-here"
                  style={{ padding: "5px" }}>
                  <ul>
                    <li>
                      Click on the<strong>New Message</strong>button to start a
                      new text or select an existing text on the left.
                    </li>
                    <li>
                      Set your<strong>Out of Office</strong>settings and
                      <strong>Manage Notifications</strong>by clicking on the
                      <div className="red-icon-background">
                        <div
                          ref={iconGearObject}
                          id="webui_setting"
                          className="-oneX-icon-container -oneX-icon--interactive gear-icon">
                          <div
                            className="-oneX-icon"
                            data-icon="webui_settings_fill">
                            {" "}
                          </div>
                        </div>
                      </div>
                      .
                    </li>
                  </ul>

                  <p className="-oneX-heading--h4">Coming Soon!</p>
                  <div id="hintText">
                    <b>Ability to Assign and Filter Texts.</b> Refer to the
                    Coming Soon tab for Details.
                  </div>

                  <p className="-oneX-heading--h4">What's New</p>
                  <div id="hintText">
                    <b>Dec. 6, 2023</b>
                    <ul>
                      <li className="sublistNumber">
                        <p>
                          Introducing a new template for homeowners/renters
                          policy renewing with possible changes in billing. This
                          new template can be found under the Policy category of
                          text templates with the title of "Policy Renewal -
                          Home/Renters".
                        </p>
                      </li>
                    </ul>
                  </div>

                  <div id="hintText">
                    <b>Oct. 31, 2023</b>
                    <ul>
                      <li className="sublistNumber">
                        <p>
                          Introducing 3 new templates to conduct policy reviews
                          with customers. They can be found under the Policy
                          category of text templates, with the title of "Policy
                          Review".
                        </p>
                      </li>
                    </ul>
                  </div>

                  <p className="-oneX-heading--h4">SF Connect Highlights</p>
                  <div id="hintText">
                    <ul>
                      <li className="sublist">
                        Block SPAM/Phishing by completing this{" "}
                        <a
                          href="https://s.f/blockphonenumber"
                          target="_blank"
                          rel="noreferrer">
                          Request Form
                        </a>
                        .
                      </li>
                      <li className="sublist">
                        SF Connect only uses the agent office phone number.
                        Wireless phone numbers are not eligible. If you opt in a
                        wireless phone number, text messages
                        <strong>will not</strong>be delivered and/or received
                        via the SF Connect application.
                      </li>
                      <li className="sublist">
                        You can send and receive emoji's.
                        <span role="img" aria-label="smile">
                          🙂
                        </span>
                      </li>
                      <li className="sublist">
                        Templates can be easily populated into your messages
                        after clicking on Create Message.
                      </li>
                      <li className="sublist">
                        Customers can send attachments (
                        <a
                          href="https://sfnet.opr.statefarm.org/agency/training/rollout/sfConnect/multimedia_messaging_service.shtml#note"
                          target="_blank"
                          rel="noreferrer">
                          with limitations
                        </a>
                        ).{" "}
                        <span style={{ fontStyle: "italic" }}>
                          Please indicate when SPI is present.
                        </span>
                      </li>
                    </ul>
                  </div>

                  {(prospectMultiMatchCount >= 1 ||
                    props.interactionsReducer.officeMessagesUnreadCount >=
                      1) && (
                    <div>
                      <p className="-oneX-acquisition-heading -oneX-heading--XS -oneX-typography-variant4 -oneX-heading--h5">
                        Action Required
                      </p>
                      {prospectMultiMatchCount >= 1 && (
                        <Notification
                          type="informational"
                          className="notification">
                          There {prospectMultiMatchCount >= 2 ? "are" : "is"}{" "}
                          {prospectMultiMatchCount} message
                          {prospectMultiMatchCount >= 2 ? "s " : " "} that{" "}
                          {prospectMultiMatchCount === 1 ? "is" : "are"} not
                          associated with a customer in ECRM. Please note that
                          you do not need to associate or create a customer for
                          SPAM messages.
                        </Notification>
                      )}
                      {props.interactionsReducer.officeMessagesUnreadCount >=
                        1 && (
                        <div>
                          <Notification type="warning" className="notification">
                            There{" "}
                            {props.interactionsReducer
                              .officeMessagesUnreadCount >= 2
                              ? "are"
                              : "is"}{" "}
                            {
                              props.interactionsReducer
                                .officeMessagesUnreadCount
                            }{" "}
                            unread message
                            {props.interactionsReducer
                              .officeMessagesUnreadCount >= 2
                              ? "s"
                              : ""}
                            .
                          </Notification>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div
                  className="-oneX-tabs-panel -oneX-hidden"
                  id="coming-soon-tab"
                  tabIndex="0"
                  role="tabpanel"
                  aria-labelledby="general"
                  style={{ padding: "15px" }}>
                  <p>
                    <b>Assignment Functionality</b>
                  </p>
                  <br />
                  <p>
                    This has been a consistent request from agents to help
                    organize the in-tray and more easily provide remarkable
                    customer service. Agents and team members will be able to
                    assign texts to themselves and filter text messages by team
                    members. This will be the first stage of this capability
                    with additional enhancements to follow.
                  </p>
                </div>

                <div
                  className="-oneX-tabs-panel -oneX-hidden"
                  id="archiving-tab"
                  tabIndex="0"
                  role="tabpanel"
                  aria-labelledby="archiving"
                  style={{ padding: "10px" }}>
                  <ul>
                    <li>
                      Messages are systematically archived after 7 days of
                      inactivity and will be removed from the in-tray.
                    </li>
                    <ul>
                      <li className="sublist">
                        Example: If a message was last responded to on Monday at
                        10 AM, seven days later at 10 AM the message will be
                        automatically removed.{" "}
                      </li>
                      <li className="sublist">
                        You can manually archive a message at any time to help
                        manage the in-tray. (
                        <a
                          href="https://sfnet.opr.statefarm.org/agency/training/rollout/sfConnect/manualArchiveTextConversations.shtml"
                          target="_blank"
                          rel="noreferrer">
                          learn more
                        </a>
                        ).{" "}
                      </li>
                    </ul>
                    <br />
                    <li>
                      If a message is not associated to a customer, it will not
                      be viewable in ECRM.
                    </li>
                    <ul>
                      <li className="sublist">
                        ECRM does not process messages that do not have a
                        customer associated with them.
                      </li>
                      <li className="sublist">
                        Please note that you do not need to associate or create
                        a customer for SPAM messages. You can archive the SPAM
                        message to remove it from your in-tray.
                      </li>
                      <li className="sublist">
                        If a phone number matches more than one customer, you
                        must select a customer name from the dropdown. If this
                        is not resolved, when the message is archived it will
                        not be viewable.
                      </li>
                    </ul>
                    <br />
                    <li>
                      If you are unable to locate an archived message associated
                      to a customer, contact ECRM support for assistance.
                    </li>
                  </ul>
                </div>

                <div
                  className="-oneX-tabs-panel -oneX-hidden"
                  id="attachments-tab"
                  tabIndex="0"
                  role="tabpanel"
                  aria-labelledby="attachments"
                  style={{ padding: "15px" }}>
                  <ul>
                    <li>
                      Attachment delivery is not guaranteed by mobile carriers.
                    </li>
                    <br />
                    <li>
                      Each message carrier supports various media file formats
                      which may not be consistent with what SF Connect supports.
                    </li>
                    <ul>
                      <li className="sublist">
                        The following media formats are intended to be supported
                        by SF Connect: jpeg, gif, png, bmp, vcf and pdf.
                      </li>
                      <li className="sublist">
                        Audio/video files are not supported.
                      </li>
                    </ul>
                    <br />
                    <li>
                      Carriers could have imposed size limitations which vary by
                      carrier.
                    </li>
                    <ul>
                      <li className="sublist">
                        It is recommended that image sizes be no larger than 1
                        MB (600 KB for toll-free numbers).
                      </li>
                      <li className="sublist">
                        Some minor carriers have smaller size limits (300 KB).
                      </li>
                    </ul>
                  </ul>

                  <p className="-oneX-heading--h4">Mark as SPI</p>
                  <p>
                    Attachments are not scanned for sensitive and personal
                    information (SPI). It is the responsibility of agents and
                    team members to report SPI by following the steps below:
                  </p>
                  <ul>
                    <li>
                      Click on the &nbsp;
                      <a
                        role="button"
                        href="!#"
                        className="-oneX-btn-primary__anchor -oneX-btn-fit-content -oneX-btn-small display-button"
                        style={{
                          display: "inline-flex",
                          width: "fit-content",
                          position: "unset",
                        }}>
                        Mark SPI
                      </a>
                      &nbsp; that displays after the attachment is downloaded.
                      <ul>
                        <li className="sublist">
                          The following message will display: Are you sure you
                          want to mark this attachment as SPI?{" "}
                        </li>
                        <li className="sublist">
                          After confirming ‘Mark SPI’, the attachment is no
                          longer viewable and the following message is
                          displayed: The attachment has been blocked or removed.{" "}
                        </li>
                      </ul>
                    </li>
                  </ul>

                  <p className="-oneX-heading--h4">
                    Check out the new digital contact card!
                  </p>
                  <p>
                    There is a 1-time step to download the contact card to your
                    system. After download is complete, the contact card is
                    available for you to send to customers/prospects at any
                    time.
                  </p>
                  <ul>
                    <ul>
                      <li className="sublist">
                        Simply click on the paperclip for an attachment, locate
                        your digital card, and attach to send!
                      </li>
                      <li className="sublist">
                        We have also created a text template to use with the
                        attached digital card.{" "}
                      </li>
                    </ul>
                  </ul>
                </div>

                <div
                  className="-oneX-tabs-panel -oneX-hidden"
                  id="general-help-tab"
                  tabIndex="0"
                  role="tabpanel"
                  aria-labelledby="general"
                  style={{ padding: "15px" }}>
                  <br />
                  <p>
                    <b>Helpful Links</b>
                  </p>
                  <br />
                  <ul>
                    <li className="sublist">
                      <a
                        href="https://sfnet.opr.statefarm.org/agency/training/rollout/sfConnect/index.shtml"
                        target="_blank"
                        rel="noreferrer">
                        Overview - SF Connect{" "}
                      </a>
                    </li>
                    <br />

                    <li className="sublist">
                      <a
                        href="http://sfnet.opr.statefarm.org/agency/manuals/technology/my_mobile_office/textingWithSFConnect.shtml"
                        target="_blank"
                        rel="noreferrer">
                        Learn more about SF Connect on My Mobile Office
                      </a>
                    </li>
                    <br />

                    <li className="sublist">
                      <a
                        href="https://s.f/blockphonenumber"
                        target="_blank"
                        rel="noreferrer">
                        Submit form to request a block for SPAM/Phishing
                      </a>
                    </li>
                    <br />

                    <li className="sublist">
                      <a
                        href="https://statefarmprod.service-now.com/sp?id=search&spa=1&q=sfconnect&disableSpellCheck=false"
                        target="_blank"
                        rel="noreferrer">
                        Agency Knowledge Base - solutions to common questions.
                      </a>
                    </li>
                    <br />
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

function mapStateToProps(state) {
  return {
    authReducer: state.authReducer,
    interactionsReducer: state.interactionsReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ mainAction, clearCustomerRows }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EmptyState);
