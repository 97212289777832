import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Notification from "./Notification";

//import ".././scss/unauthorized.scss";

const TITLE = "SF Connect ECRM";
export default class ECRMErrorMessage extends Component {
  render() {
    return (
      <>
        <header></header>
        <Helmet>
          <title>{TITLE}</title>
        </Helmet>

        <br />
        <div className="-oneX-container">
          <div className="-oneX-row user-not-authorized-row">
            <div className="-onex-col-2 -onex-col-lg-4 user-not-authorized-page">
              <br />
              <Notification type="error" className="notification">
                  The book of business you are trying to use is not supported by SF Connect.
              </Notification>
              <h5>
                <Notification type="informational" className="notification">
                   Please select another book by clicking on the Change Office link above.
                </Notification> 
              </h5>
              <br />
              <h6>
                <p>
                </p>
              </h6>
            </div>
          </div>
        </div>
      </>
    );
  }
}