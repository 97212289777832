import React, { Component } from "react";

export default class CustomerSearchByName extends Component {

  componentDidMount() {
    if (window.oneX) {
      window.oneX.addElement(this.refs.nameCriteria)
    }
  }

  render() {
    const { inputFieldLastName, onChangeValue, inputFieldFirstName, onChangeFieldExactMatch } = this.props;
    return (
      <div className="-oneX-container">
        <div ref={'nameCriteria'} className="-oneX-row">
          <div className="-oneX-col-12 -oneX-col-md-7 -oneX-col-lg-4">
            <div className="-oneX-widget"> {/* -oneX-widget--err */}
              <label id="customerLastNameLabel" htmlFor="customerLastName" className="-oneX-search-textfield -oneX-textfield__floatingLabel">Last Name / Org*</label>
              <input id="customerLastName" name="lastName" className="-oneX-textfield--floating-input" aria-required="true" aria-labelledby="customerLastNameLabel" aria-label="Search by last name"
                value={inputFieldLastName} onChange={onChangeValue} />
              {/* <div id="name-err" className="-oneX-widget__err-text" aria-live="assertive">Name or Orgiazation Required</div> */}
            </div>
          </div>
          <div className="-oneX-col-12 -oneX-col-md-5 -oneX-col-lg-4">
            <label id="customerFirstNameLabel" htmlFor="customeFirstName" className="-oneX-textfield__floatingLabel">First Name</label>
            <input id="customeFirstName" name="firstName" className="-oneX-textfield--floating-input" aria-required="false" aria-labelledby="customerFirstNameLabel" aria-label="Search by first name"
              value={inputFieldFirstName} onChange={onChangeValue} />
          </div>
          <div className="-oneX-col-12 -oneX-offset-2 -oneX-col-md-6 -oneX-offset-md-3 -oneX-col-lg-4 -oneX-offset-lg-0 ">
            <div className="exact-match">
              <input id="exactMatchId" type="checkbox" className="-oneX-checkbox exact-match-checkbox" aria-required="false" onChange={onChangeFieldExactMatch} />
              <label htmlFor="exactMatchId" className="exact-match-checkbox">Exact Match</label>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
