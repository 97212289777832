import React, { Component } from "react";
import { connect } from "react-redux";
import DOMPurify from "dompurify";
import { bindActionCreators } from "redux";
import {
  fetchInteractions,
  mainAction,
} from "../../redux/actions/interactionsActions";
import ChatCard from "../chat-card";

class MessageFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }
  componentDidMount() {
    var options = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0,
    };

    // Handles the interaction of scroll reaches the end of the line
    this.observer = new IntersectionObserver(
      this.handleObserver.bind(this),
      options
    );
    this.observer.observe(this.loadingRef);
  }

  handleObserver(entities) {
    this.setState({ loading: true });
    const isIntersecting = entities[0].isIntersecting;

    if (isIntersecting && this.props.nextToken) {
      this.props.actions.fetchInteractions(
        this.props.officeAssociateId,
        this.props.currentOfficeMembers,
        this.props.nextToken,
        {
          hidden: this.props.isHiddenFilterOn,
          unread: this.props.isNewFilterOn,
          read: this.props.isReadFilterOn,
          repliedTo: this.props.isRepliedToFilterOn,
          followUp: this.props.isFollowUpFilterOn,
          assignments: this.props.selectedAssignmentsFilter,
          returnUnassigned: this.props.returnUnassigned,
        }
      );
    }
    this.setState({ loading: false });
  }

  render() {
    // Additional css
    const loadingCSS = {
      height: "30px",
      margin: "20px 0px",
    };

    // To change the loading icon behavior
    const loadingTextCSS = { display: this.state.loading ? "block" : "none" };
    let interactions =
      this.props.isTextFilterOn || this.props.isMyMessagesFilterOn
        ? this.props.filteredInteractions
        : this.props.interactions;
    let currentInteractionId = this.props.currentInteractionId;
    let allMessages =
      interactions.length > 0
        ? interactions.map((interaction, index) => {
            const starred =
              interaction.starred &&
              typeof interaction.starred === "object" &&
              interaction.starred.hasOwnProperty("status")
                ? interaction.starred.status
                : false;

            // santizing different properties before passing them to ChatCard component to handle DOM XSS vulenrability
            const sanitizedInteractionId = DOMPurify.sanitize(
              interaction.interactionId
            );
            const sanitizedCustomerName = DOMPurify.sanitize(
              interaction.customerName
            );
            const sanitizedCustomerInitials = DOMPurify.sanitize(
              interaction.customerInitials
            );
            const sanitizedClientId = DOMPurify.sanitize(interaction.clientId);
            const sanitizedMessagePreview = DOMPurify.sanitize(
              interaction.messagePreviewText
            );
            const sanitizedPhoneNumber = DOMPurify.sanitize(
              interaction.phoneNumber
            );
            const sanitizedMessageDate = DOMPurify.sanitize(
              interaction.messageDate
            );
            const sanitizedIsMessageViewed = DOMPurify.sanitize(
              interaction.isMessageViewed
            );
            const sanitizedLastOutgoingSender = DOMPurify.sanitize(
              interaction.lastOutgoingSender
            );
            const sanitizedProtectedPersonIndicator = DOMPurify.sanitize(
              interaction.protectedPersonIndicator
            );
            const sanitizedVirtualAssistantActive = DOMPurify.sanitize(
              interaction.virtualAssistantActive
            );
            const sanitizedVirtualAssistantFollowup = DOMPurify.sanitize(
              interaction.virtualAssistantFollowup
            );
            const sanitizedOwnerAgentName = DOMPurify.sanitize(
              interaction.ownerAgentName
            );
            return (
              <li key={index} ref={index}>
                <ChatCard
                  key={sanitizedInteractionId}
                  customerName={sanitizedCustomerName}
                  customerInitials={sanitizedCustomerInitials}
                  clientId={sanitizedClientId}
                  interactionId={sanitizedInteractionId}
                  messagePreview={sanitizedMessagePreview}
                  phoneNumber={sanitizedPhoneNumber}
                  messageDate={sanitizedMessageDate}
                  isMessageViewed={sanitizedIsMessageViewed}
                  lastOutgoingSender={sanitizedLastOutgoingSender}
                  protectedPersonIndicator={sanitizedProtectedPersonIndicator}
                  currentInteractionId={currentInteractionId}
                  starred={starred}
                  virtualAssistantActive={sanitizedVirtualAssistantActive}
                  virtualAssistantFollowup={sanitizedVirtualAssistantFollowup}
                  ownerAgentName={sanitizedOwnerAgentName}
                  hidden={interaction.hidden}
                  assigned={interaction.assigned}
                  fuzzySearchResults={interaction.fuzzySearchResults}
                />
              </li>
            );
          })
        : this.props.resultsStatus;

    return (
      <div className="sfconnect-filter clearfix">
        {this.props.nextToken && (
          <div>
            <h1>
              Your office has more unloaded interactions. Scroll to the bottom
              to load.
            </h1>
            <br />
          </div>
        )}
        <div
          role="list"
          id="allMessages"
          aria-labelledby="allMessages"
          className={
            "-display-flex-col " +
            (interactions.length > 0 ? "all-messages" : "no-messages")
          }>
          <ul>
            {allMessages}
            {this.props.initialQueryExecuted &&
              !this.props.nextToken &&
              allMessages !== "No Messages" && <h1>End of list.</h1>}
            <li>
              <div
                ref={(loadingRef) => (this.loadingRef = loadingRef)}
                style={loadingCSS}>
                <span style={loadingTextCSS}>Loading...</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  interactions: state.interactionsReducer.interactions,
  filteredInteractions: state.interactionsReducer.filteredInteractions,
  currentInteractionId:
    state.interactionsReducer.currentInteraction.interactionId,
  resultsStatus: state.interactionsReducer.resultsStatus,
  officeAssociateId: state.userInfoReducer.officeAssociateId,
  currentOfficeMembers: state.userInfoReducer.currentOfficeMembers,
  nextToken: state.interactionsReducer.nextToken,
  isNewFilterOn: state.interactionsReducer.isNewFilterOn,
  isReadFilterOn: state.interactionsReducer.isReadFilterOn,
  isRepliedToFilterOn: state.interactionsReducer.isRepliedToFilterOn,
  isFollowUpFilterOn: state.interactionsReducer.isFollowUpFilterOn,
  isHiddenFilterOn: state.interactionsReducer.isHiddenFilterOn,
  isTextFilterOn: state.interactionsReducer.isTextFilterOn,
  isMyMessagesFilterOn: state.interactionsReducer.isMyMessagesFilterOn,
  initialQueryExecuted: state.interactionsReducer.initialQueryExecuted,
  selectedAssignmentsFilter:
    state.interactionsReducer.selectedAssignmentsFilter,
  returnUnassigned: state.interactionsReducer.returnUnassigned,
});
const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        mainAction,
        fetchInteractions,
      },
      dispatch
    ),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MessageFilter);
