import React, { Component } from "react";


export default class SFConnectLogo extends Component {
    render() {
        const { animate } = this.props
        return (
            <>
                <div className={ animate ? "-sfc-center-on-page" : "-sfc-logo"}>
                    <img
                        id={this.props.id}
                        className={ animate ? "animate__animated animate__zoomOut animate__slower" : ''}
                        alt="SF Conect Logo"
                        src="./images/sfconnect.png"
                    />
                </div>
            </>
        );
    }
}
