import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react";
import { useMsal } from "./AuthProvider";

export const UserContext = createContext();
export const useUserContext = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const { user, acquireTokenSilent, acquireTokenRedirect } = useMsal();
  const [isError] = useState(false);
  const [userDetails, setUserDetails] = useState({});

  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

  // Log on browser - Refresh token state
  const consoleLogRefreshState = (message, tokenExp) => {
    if (!tokenExp) {
      return;
    }
  };

  // Check if local storage token exists and if it is valid (not expired)
  const validateLocalStorageToken = () => {
    let localToken;
    const localStorageToken = localStorage.getItem("token");
    const localRefreshLimit = localStorage.getItem("refreshLimit");
    const refreshLimit = Math.floor(localRefreshLimit);

    if (localStorageToken) {
      try {
        const decodedJwt = parseJwt(localStorageToken);
        const localStorageTokenExp = decodedJwt.exp * 1000; // Convert expiration timestamp to millisec

        const now = Date.now();
        const timeLeft = localStorageTokenExp - now;
        const minutesThreshold = 10 * 60 * 1000; // 10 minutes threshold.

        consoleLogRefreshState("ACCESS INFO:", localStorageTokenExp);

        // If there is a token in local storage and it has more than 10 minutes left
        // OR the current time is greater than the refresh time limit
        // Then return the local storage token.
        if (timeLeft > minutesThreshold || now > refreshLimit) {
          localToken = {
            accessToken: localStorageToken,
            expiresOn: localStorageTokenExp,
          };
        }
      } catch (err) {
        // If it fails just log it and proceed.
        console.error(err);
      }
    }
    return localToken;
  };

  // Request Azure token
  const getAzureToken = async (forceRefresh = false) => {
    // Before requesting a new token - Check if the local storage has token.
    const localToken = validateLocalStorageToken();
    // If local storage token still valid return it
    if (localToken) {
      return localToken;
    }

    // If local storage token does not exist or is not valid
    // Then request new token.
    const TOKEN_REQUESTS = {
      LOGIN: {
        account: user,
        forceRefresh: forceRefresh,
        scopes: [process.env.REACT_APP_REQUEST_SCOPE],
      },
    };
    try {
      const token = await acquireTokenSilent(TOKEN_REQUESTS.LOGIN);
      consoleLogRefreshState("NEW ACCESS INFO: ", token.expiresOn);
      return token;
    } catch (error) {
      if ("interaction_required" in error) {
        const token = await acquireTokenRedirect(TOKEN_REQUESTS.LOGIN);
        consoleLogRefreshState("REDIRECT NEW ACCESS INFO: ", token.expiresOn);
        return token;
      }
    }
  };

  const refreshToken = async () => {
    const token = await getAzureToken(true);
    const access_token = token.accessToken;

    localStorage.setItem("token", access_token);
    setRefreshToken(token);

    const user_details = {
      ...userDetails,
      token: access_token,
    };
    setUserDetails(user_details);
  };

  // Waits for the token expiration time and call refreshToken:
  // Get token expiration time and current time
  // Get 10 minutes before the token expiration
  // If the time left is less than 10 minutes threshold then try to refresh the token every minute
  const setRefreshToken = (token) => {
    const exp = token.expiresOn;
    const now = Date.now();
    const timeLeft = exp - now;
    const minutesThreshold = 10 * 60 * 1000; // 10 minutes threshold
    const refreshTokenInterval =
      timeLeft > minutesThreshold ? timeLeft - minutesThreshold : 60000;

    setTimeout(async () => {
      await refreshToken();
    }, refreshTokenInterval);
  };

  const setRefreshLimit = () => {
    const now = Date.now();
    const localRefreshLimit = localStorage.getItem("refreshLimit");
    let refreshLimit = Math.floor(localRefreshLimit);

    // Check if the refresh limit exists or refresh limit is over
    if (!refreshLimit || now > refreshLimit) {
      // Define 8 hours token refresh limit.
      const sessionTime = 8 * 60 * 60 * 1000; // 8 hours
      refreshLimit = now + sessionTime;
      // Set new refresh limit to local storage
      localStorage.setItem("refreshLimit", refreshLimit);
    }
  };

  const getToken = useCallback(async () => {
    const token = await getAzureToken();
    return token;
  }, [acquireTokenSilent, acquireTokenRedirect, user]);

  const getUser = useCallback(async () => {
    // Check and set refresh limit before getting Azure token
    setRefreshLimit();
    const token = await getToken();
    const access_token = token ? token.accessToken : undefined;
    var base = user.username.split("@")[0];
    var user_alias = base.split(".").pop();

    const user_details = {
      alias: user_alias.toUpperCase(),
      email: user.username,
      token: access_token,
    };
    setUserDetails(user_details);

    // If it is a valid token
    if (access_token) {
      // Set token to local storage
      localStorage.setItem("token", access_token);
      setRefreshToken(token);
    }
  }, [user]);

  useEffect(() => {
    if (!user) {
    } else if (user) {
      getUser();
    }
  }, [user]);

  return (
    <UserContext.Provider
      value={{
        userDetails,
        getUser,
        getToken,
        isError,
      }}>
      {children}
    </UserContext.Provider>
  );
};
