import axios from "axios";
import { GET_OFFICE_PREFERENCES, PATCH_OFFICE_PREFERENCES } from "./types";
import { logUIErrors } from "./interactionsActions";

export const getOfficePreferences =
  (agentId, loggedInAssociateId) => (dispatch) => {
    axios
      .get(
        process.env.REACT_APP_AWS_API_URL +
          `agents/${agentId}/officePreferences`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        dispatch({ type: GET_OFFICE_PREFERENCES, payload: response.data });
      })
      .catch((error) => {
        dispatch(logUIErrors(agentId, error, "Get Office Preference", true));
      });
  };

export const patchOfficePreferences = (token, agentId, data) => (dispatch) => {
  axios
    .patch(
      process.env.REACT_APP_AWS_ROOT_URL +
        "/api/office/preferences?channel=mms",
      data,
      {
        headers: {
          "X-CONSUMER_KEY": "SFCONNECT_UI",
          Authorization: `Bearer ${token}`,
          selectedAgentId: agentId,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      dispatch({ type: PATCH_OFFICE_PREFERENCES, payload: response.data });
    })
    .catch((error) => {
      dispatch(logUIErrors(agentId, error, "Patch Office Preference", true));
    });
};

export const patchOfficesPreferences = (agentId, data) => (dispatch) => {
  axios
    .patch(
      process.env.REACT_APP_AWS_API_URL + `offices/${agentId}/preferences`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      dispatch({ type: PATCH_OFFICE_PREFERENCES, payload: response.data });
    })
    .catch((error) => {
      dispatch(logUIErrors(agentId, error, "Patch Office Preference", true));
    });
};
