import React, { Component } from "react";

class Toggle extends Component {
  render() {
    const sliderToggle = () => {
      return (
        <>
          <label
            htmlFor={"switch_" + this.props.id}
            className="switch"
            aria-label={this.props.name}>
            <input
              id={"switch_" + this.props.id}
              type="checkbox"
              checked={this.props.checked ? "checked" : false}
              disabled={this.props.disabled}
              onChange={this.props.onChange}
            />
            <span
              className={
                "slider round" + (this.props.type === "small" ? " small" : "")
              }></span>
          </label>
        </>
      );
    };
    return (
      <>
        <fieldset className="-oneX -oneX-fieldset">
          {this.props.align !== "left" ? (
            <>
              <legend className="-oneX-legend">{this.props.name}</legend>
              {sliderToggle(this.props)}
            </>
          ) : (
            <>
              <legend className="-oneX-legend">
                {sliderToggle(this.props)}
                <div className="label-right">{this.props.name}</div>
              </legend>
            </>
          )}
        </fieldset>
      </>
    );
  }
}

export default Toggle;
