import React, { Component } from "react";

class OfficeSettingsPanelHeader extends Component {
  componentDidMount() {
    //This is needed by oneX onLoad of the object, the content panel must be hidden, then oneX toggles the class.
    this.setState({ panel: { visible: " -oneX-hidden" } });
    if (window.oneX) {
      let oneXItem = {};
      for (oneXItem in this.refs) {
        window.oneX.addElement(this.refs[oneXItem]);
      }
    }
  }
  render() {
    return (
      <>
        <div
          id="dialogLnkHdr"
          className="officeSettingsHeaderBkGround"
          tabIndex="-1">
          <div
            ref={"myIcon"}
            className="-oneX-icon-container -oneX-icon--interactive officeSettingsHeaderBkGroundDiv">
            <button
              className="-oneX-icon drawer-icon chevronDiv"
              data-icon="web_ui_chevron"
              aria-label="Close Drawer"
              tabIndex="-1"
              onClick={() => window.oneX.Drawer.hideDrawer()}></button>

            <div
              className="-oneX-icon icon-in-drawer"
              data-icon="webui_settings_fill"
              aria-label="Gear Icon"
              tabIndex="-1"
              onClick={() => window.oneX.Drawer.hideDrawer()}></div>

            <div className="officeSettingHeaderTextGroupDiv"></div>
          </div>
        </div>
      </>
    );
  }
}

export default OfficeSettingsPanelHeader;
